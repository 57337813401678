import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { HttpService } from '../../service/HttpService';
import { checkForError,showMessage } from '../../utility/AppUtility';
import { AppBreadcrumb } from '../../AppBreadcrumb';

export class GuillocheImageList extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue: [],
            page: 1,
            totalRecords: 0,
            size: 100
        };

        this.httpService = new HttpService();
    }

    componentDidMount() {

        this.getList(this.state.page - 1);
    }
    getList = (page) => {
        trackPromise(
            this.httpService.getApi('guilloche-images').then((response) => {
                this.setState({ dataTableValue: response.data, totalRecords: response.data.totalElements });

            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            }));
    }
    showMessage(severity, summary, message) {
        let msg = { severity: severity, summary: summary, detail: message };
        this.growl.show(msg);
    }
    onPageChange = (event) => {
        this.setState({ page: event.page })
        this.getList(event.page)

    }


    edit = (rowData) => {
        this.props.history.push({
            pathname: '/guilloche_images_edit',
            state: { id: rowData.id }
        })
    }

    add = () => {
        this.props.history.push('guilloche_images_add')
    }
    delete = (rowData) => {
        trackPromise(
            this.httpService.deleteApi('guilloche-images/' + rowData.id).then((response) => {
                this.getList(0);
            }).catch((error) => {
                if (checkForError(error)){

                    this.showMessage('error', 'Error Message', error.response.data.title)

                }
            }));
    }

    returnHeader = () => {
        return (
            <div>
                <div> List of Guilloche Images</div>

                <div className="p-datatable-globalfilter-container">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" />
                </div>
            </div>
        );
    }
    actionTemplate = (rowData, column) => {
        return <div>
            <Button type="button" tooltip="Delete" tooltipOptions={{ position: 'bottom' }} icon="pi-md-delete-forever" className="p-button-danger" onClick={() => this.delete(rowData)} style={{ marginRight: '.5em' }} />
            <Button type="button" tooltip="Edit" tooltipOptions={{ position: 'bottom' }} icon="pi-md-pencil" className="p-button-warning" onClick={() => this.edit(rowData)} />
        </div>;
    }
    render() {
        let actionHeader = <Button type="button" icon="pi-md-settings" />;

        return <><div className="p-grid">
            <div className="p-col-12">
                <Growl ref={(el) => this.growl = el} style={{ marginTop: '75px' }} />

                <div className=" datatable-demo">
                    <div className="p-col-12">
                        <font className="font21Weight500" >Guilloche Images</font>

                        <Button className="floatRight" icon="pi-md-plus"
                            tooltip="Add Guilloche Image" label="Add" tooltipOptions={{ position: 'left' }} onClick={() => this.add()} />
                    </div>
                    <div className="p-col-12">
                    </div>
                    <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue}
                        paginator={true} rows={10}
                        header="List of Guilloche Images"
                        responsive={true} resizableColumns={true} columnResizeMode="fit"
                    >
                        <Column field="color" header="Color" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains"/>
                        <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />


                    </DataTable>

                </div>
            </div>
        </div></>
    }
}