import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { InputSwitch } from "primereact/inputswitch";
import React, { useEffect, useState, useRef } from "react";
import { trackPromise } from "react-promise-tracker";
import { withRouter } from "react-router-dom";
import { HttpService } from "../../service/HttpService";
import { checkForError, showMessage } from "../../utility/AppUtility";
import { AppBreadcrumb } from "../../AppBreadcrumb";

var _ = require("lodash");

function UserPriviladge(props) {
  var id = "";
  var username = "";
  if (
    props === undefined ||
    props.location === undefined ||
    props.location.state === undefined ||
    props.location.state.id === undefined
  ) {
    props.history.push("user_list");
  } else {
    id = props.location.state.id;
    username = props.location.state.username;
  }
  var [change, setChange] = useState(true);
  var [response, setResponse] = useState(false);
  var data1 = [
    {
      name: "Master",
      priority: 0,
      maxPriority: 1,
      children: [
        {
          name: "Customer",
          priority: 0,
          maxPriority: 3,
        },
        {
          name: "Product",
          priority: 0,
          maxPriority: 3,
        },
        {
          name: "Plant",
          priority: 0,
          maxPriority: 3,
        },
        {
          name: "Vendor",
          priority: 0,
          maxPriority: 3,
        },
        {
          name: "Guilloche Image",
          priority: 0,
          maxPriority: 3,
        },
        {
          name: "Packaging",
          priority: 0,
          maxPriority: 3,
        },
      ],
    },
    {
      name: "Printing",
      priority: 0,
      maxPriority: 1,
      children: [
        {
          name: "Print Data",
          priority: 0,
          maxPriority: 2,
        },
        {
          name: "Label Production",
          priority: 0,
          maxPriority: 3,
        },
        {
          name: "Rolls",
          priority: 0,
          maxPriority: 1,
        },
      ],
    },
    {
      name: "Template",
      priority: 0,
      maxPriority: 1,
      children: [
        {
          name: "Template",
          priority: 0,
          maxPriority: 2,
        },
        {
          name: "Token",
          priority: 0,
          maxPriority: 1,
        },
        {
          name: "Invalid Scan",
          priority: 0,
          maxPriority: 1,
        },
      ],
    },
    {
      name: "Token Data",
      priority: 0,
      maxPriority: 1,
      children: [
        {
          name: "Master Product",
          priority: 0,
          maxPriority: 3,
        },
        {
          name: "Token Data",
          priority: 0,
          maxPriority: 2,
        },
        {
          name: "Token Data Report",
          priority: 0,
          maxPriority: 1,
        },
      ],
    },
    {
      name: "Track & Trace",
      priority: 0,
      maxPriority: 1,
    },
    {
      name: "Smart Bin",
      priority: 0,
      maxPriority: 1
    }
  ];
  var [data, setData] = useState(data1);
  var [moduleArray, setModuleArray] = useState(data);
  const growl = useRef(null);

  const httpService = new HttpService();

  useEffect(() => {
    getUserPriviladge();
  }, []);

  const getUserPriviladge = () => {
    httpService
      .getApi("user-priviladges/parent/" + id)
      .then((response) => {
        mergeData([...response.data]);
        setResponse(true);
      })
      .catch((error) => { });
  };
  const mergeData = (userP) => {

    var obj = JSON.parse(localStorage.getItem("user"));
    var role = obj.authorities[0];

    if ((role === "ROLE_USER") && props.location.state.userPriviladges !== undefined && props.location.state.userPriviladges.length > 0) {
      var auths = [];
      props.location.state.userPriviladges.forEach((ele, index) => {
        if (ele.maxPriority !== ele.priority) {
          auths.push(ele.name)
        }
      });
    
      setModuleArray(moduleArray.filter(function (e) {
        return !(auths.indexOf(e.name) !== -1);
      }));
    }

    if (userP !== undefined && userP !== null && userP.length > 0) {
      for (let index = 0; index < data.length; index++) {
        var dataElement = data[index];
        var userElement = _.find(userP, function (n) {
          return n.name === dataElement.name;
        });

        if (userElement !== undefined) {
          dataElement.priority = userElement.priority;
          dataElement.id = userElement.id;

          if (dataElement.children != undefined) {
            if (
              dataElement.children !== null &&
              dataElement.children.length > 0
            )
              for (
                let index1 = 0;
                index1 < dataElement.children.length;
                index1++
              ) {
                var dataElement2 = dataElement.children[index1];

                var userElement2 = _.find(userElement.children, function (n) {
                  return n.name === dataElement2.name;
                });

                if (userElement2 !== undefined) {
                  dataElement2.priority = userElement2.priority;
                  dataElement2.id = userElement2.id;
                }
              }
          }
        }
      }
    }

    setChange(!change);
  };

  const cancel = () => {
    props.history.push("user_list");
  };

  const save = (data) => {
    trackPromise(
      httpService
        .postApi("user-priviladges", { userId: id, priviladges: moduleArray })
        .then((response) => {
          cancel();
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };

  const handleModule = (value, index) => {
    let moduleArrayTemp = [...moduleArray];
    let tempObj = moduleArrayTemp[index];
    if (value) {
      tempObj.priority = 1;
      if (tempObj.children != undefined) {
        tempObj.children.forEach((element) => {
          element.priority = element.maxPriority;
        });
      }
    } else {
      if (tempObj.children != undefined) {
        tempObj.children.forEach((element) => {
          element.priority = 0;
        });
      }
      tempObj.priority = 0;
    }
    console.log(moduleArrayTemp)
    setModuleArray(moduleArrayTemp);
  };
  const handlePrivladge = (e, index2, index) => {
    let tempObj = {};
    tempObj = moduleArray[index2].children[index];

    if (e.value) {
      if (e.target.name === "View") {
        tempObj.priority = 1;
      } else if (e.target.name === "Add") {
        tempObj.priority = 2;
      } else if (e.target.name === "Delete") {
        tempObj.priority = 3;
      }
    } else {
      if (e.target.name === "View") {
        tempObj.priority = 0;
      } else if (e.target.name === "Add") {
        tempObj.priority = 1;
      } else if (e.target.name === "Delete") {
        tempObj.priority = 2;
      }
    }
  };
  const getInputs = (obj, index2, index1) => {
    let item = [];
    let name = "View";
    for (let index = 0; index < obj.maxPriority; index++) {
      if (index == 1) {
        name = "Add";
      }
      if (index == 2) {
        name = "Delete";
      }

      item.push(
        <>
          <div className="p-col-3">
            <InputSwitch
              key={obj.name}
              checked={obj.priority > index}
              name={name}
              onChange={(e) => handlePrivladge(e, index2, index1)}
            />
            &nbsp;&nbsp;&nbsp;{name}
          </div>
        </>
      );
    }

    return item;
  };

  return (
    <>
      <AppBreadcrumb
        breadcrumdItems={[
          { label: "User List", url: "#/user_list" },
          { label: "User Priviladge" },
        ]}
      />

      <div className="p-grid ">
        <Growl ref={growl} style={{ marginTop: "75px" }} />

        <div className="p-col-12 ">
          <div className="card card-w-title">
            <div>
              <h2> Add Priviladge For : &nbsp;&nbsp;&nbsp;{username}</h2>
            </div>
            <br />
            <h2>Select Module</h2>
            {response &&
              moduleArray.map((obj, index) => {
                return (
                  <>
                    <div>
                      {" "}
                      <InputSwitch
                        checked={obj.priority == obj.maxPriority}
                        name={obj.name}
                        onChange={(e) => handleModule(e.value, index)}
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;{obj.name}{" "}
                    </div>
                    <br></br>
                  </>
                );
              })}
            <hr></hr>
            <h2>Add Priviladge</h2>
            {response &&
              moduleArray.map((obj, index1) => {
                return (
                  <>
                    {obj.priority == obj.maxPriority && (
                      <>
                        {obj.children != undefined && (
                          <>
                            <div>
                              <h2>{obj.name} Module</h2>
                            </div>
                            {obj.children.map((obj1, index) => {
                              return (
                                <>
                                  <div className="p-grid">
                                    <div
                                      className="p-col-12"
                                      style={{ display: "inherit" }}
                                    >
                                      <div className="p-col-3 ">
                                        {obj1.name}
                                      </div>

                                      {getInputs(obj1, index1, index)}
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        )}
                      </>
                    )}
                  </>
                );
              })}
            <Button onClick={save} label="Save"></Button>
          </div>
        </div>
      </div>
    </>
  );
}
export default withRouter(UserPriviladge);
