import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { useRef, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean, showMessage } from '../../utility/AppUtility';
import rule from './../../constant/Rule';
import { AppBreadcrumb } from '../../AppBreadcrumb';

var _ = require('lodash');

function ApUtilityAdd(props) {

    var [obj, setObj] = useState({})
    const growl = useRef(null)
    var [disableFile, setDisableFile] = useState(false)
    const { handleSubmit, errors, control, setValue } = useForm({ mode: "onBlur" });
    var [colors, setColors] = useState([])
    var [plants, setPlants] = useState([])
    var [zPrices, setZPrices] = useState([])
    const zPricesNormal = [
        { label: '10', value: '10' },
        { label: '15', value: '15' },
        { label: '20', value: '20' },
        { label: '25', value: '25' },
        { label: '50', value: '50' },
        { label: '75', value: '75' },
        { label: '100', value: '100' },
        { label: '150', value: '150' },
        { label: '200', value: '200' }
    ]
    const zPricesLucky = [
        { label: '15', value: '15' },
        { label: '20', value: '20' },
        { label: '25', value: '25' },
        { label: '50', value: '50' },
        { label: '75', value: '75' },
        { label: '100', value: '100' },
        { label: '150', value: '150' },
        { label: '200', value: '200' }
    ]
    const tokenTypes = [
        { label: 'Lucky', value: 'Lucky' },
        { label: 'Normal', value: 'Normal' }
    ]
    const httpService = new HttpService();


    const handleChange = (e, type) => {
        let val = e.target.value;
        if (e.target.name === 'tokenType') {
            if (val === 'Lucky') {
                setZPrices(zPricesLucky)
            } else if (val === 'Normal') {
                setZPrices(zPricesNormal)
            }
        }
        setObj({ ...obj, [e.target.name]: val });
        return val;
    }
    const onFileSelect = (e) => {
        setObj({ ...obj, file: e.files[0] });
        setValue('file', e.files[0], { shouldDirty: true })
        setDisableFile(true)
    }
    useEffect(() => {
        getPlants()
        getColors()
    }, [])

    const getPlants = () => {
        httpService.getApi('plants/name').then((response) => {
            setPlants([...response.data])
        }).catch((error) => {

        })
    }
    const getColors = () => {
        httpService.getApi('matnrs/colos').then((response) => {
            setColors([...response.data])
        }).catch((error) => {

        })
    }
    const cancel = () => {
        props.history.push('token_data_list')
    }

    const zpacks = [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' },
        { label: '16', value: '16' },
        { label: '17', value: '17' },
        { label: '18', value: '18' },
        { label: '19', value: '19' },
        { label: '20', value: '20' },
        { label: '21', value: '21' },
        { label: '22', value: '22' },
        { label: '23', value: '23' },
        { label: '24', value: '24' },
        { label: '25', value: '25' },
        { label: '26', value: '26' },
        { label: '27', value: '27' },
        { label: '28', value: '28' },
        { label: '29', value: '29' },
        { label: '30', value: '30' },
        { label: '31', value: '31' },
        { label: '32', value: '32' },
        { label: '33', value: '33' },
        { label: '34', value: '34' },
        { label: '35', value: '35' },
        { label: '36', value: '36' },
        { label: '37', value: '37' },
        { label: '38', value: '38' },
        { label: '39', value: '39' },
        { label: '40', value: '40' },
    ]



    const save = data => {
        obj = clean(obj)
        obj["matnr.color"] = obj.color.value;
        obj["plant"] = obj.plant.name;
        obj["matnr.tokenType"] = obj.tokenType;
        obj["matnr.packSize"] = obj.zpack
        let formData = new FormData()
        for (const property in obj) {
            if (property === "files") {
                if (obj[property] !== undefined)
                    obj[property].forEach(element => {
                        formData.append("file", element);
                    });

            } else
                if (obj[property] !== undefined)
                    formData.append(property, obj[property]);
        }
        trackPromise(
            httpService.getApiMultipart('ap-utilities', formData).then((response) => {
                generateFIle(response.data.id)
            }).catch((error) => {
                if (checkForError(error)) {
                    showMessage('error', 'Error Message', error.response.data.title, growl)
                }
            }));

    }
    const generateFIle = (id) => {
        trackPromise(
            httpService.getApi('ap-utilities/generateFile/' + id).then((response) => {
                cancel();
            }).catch((error) => {
                if (checkForError(error)) {
                    showMessage('error', 'Error Message', error.response.data.title, growl)
                }
            }));
    }

    const onRemoveImg = () => {
        _.unset(obj, 'file');
        setValue('file', null)
        setDisableFile(false)
    }


    return <>
        <AppBreadcrumb breadcrumdItems={[{ label: 'Token Data', url: "#token_data_list" }, { label: 'Token Data Add' }]} />

        <div className="p-grid ">
            <Growl ref={growl} style={{ marginTop: '75px' }} />

            <div className="p-col-10 ">
                <div className="card card-w-title">
                    <h1>Add Token Data</h1>
                    <div className="p-grid p-fluid form-group">
                        <div className="p-col-8">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                <span className="md-inputfield">
                                    <Controller optionLabel="name" optionValue="name" className={errors.plant ? "p-error" : ''} rules={{ ...rule.RULE.requiredRule }} name="plant" options={plants} value={obj.plant} placeholder="Plant" as={Dropdown} control={control} onChange={(e) => handleChange(e[0])} />

                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">{errors.plant?.message}</span>
                        </div>
                        <div className="p-col-8">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                <span className="md-inputfield">
                                    <Controller optionLabel="value" optionValue="value" className={errors.color ? "p-error" : ''} rules={{ ...rule.RULE.requiredRule }} name="color" options={colors} value={obj.color} placeholder="Color" as={Dropdown} control={control} onChange={(e) => handleChange(e[0])} />

                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">{errors.color?.message}</span>
                        </div>


                        <div className="p-col-8">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                <span className="md-inputfield">
                                    <Controller className={errors.tokenType ? "p-error" : ''} rules={{ ...rule.RULE.requiredRule }} name="tokenType" options={tokenTypes} value={obj.tokenType} placeholder="Token Type" as={Dropdown} control={control} onChange={(e) => handleChange(e[0])} />

                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">{errors.tokenType?.message}</span>
                        </div>
                        <div className="p-col-8">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                <span className="md-inputfield">
                                    <Controller className={errors.zpack ? "p-error" : ''} rules={{ ...rule.RULE.requiredRule, ...rule.RULE.minRuleVal1 }} name="zpack" options={zpacks} value={obj.zpack} placeholder="ZPACK" as={InputText} control={control} onChange={(e) => handleChange(e[0])} />

                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">{errors.zpack?.message}</span>
                        </div>
                        <div className="p-col-8">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                <span className="md-inputfield">
                                    <Controller className={errors.zprice ? "p-error" : ''} rules={{ ...rule.RULE.requiredRule }} name="zprice" options={zPrices} value={obj.zprice} placeholder="ZPRICE" as={Dropdown} control={control} onChange={(e) => handleChange(e[0])} />
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">{errors.zprice?.message}</span>
                        </div>

                        <div className="p-col-8">
                            <label>Upload file</label>
                            <div className="p-col-8"></div>
                            <div className="p-inputgroup">
                                <Controller className={errors.file ? "p-error" : ''} disabled={disableFile} rules={{ ...rule.RULE.requiredRule }} as={FileUpload} control={control} name="file"
                                    onSelect={e => onFileSelect(e)} accept="text/csv" onRemove={() => onRemoveImg()} maxFileSize={10000000} customUpload={true} />
                            </div>
                            <span className="errorSpan merginLeft30">{errors.file?.message}</span>
                        </div>


                    </div>
                    <div className="p-col-12">
                        <Button icon="pi pi-check" label="Save" onClick={handleSubmit(save)} />
                        <Button icon="pi-md-cancel" label="Cancel" className="p-button-secondary" onClick={() => cancel()} />

                    </div>
                    <div className="p-col-8">

                    </div>

                </div>
            </div>
            <div className="p-col-4"></div>
        </div>
    </>
}
export default withRouter(ApUtilityAdd);
