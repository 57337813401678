import React, { useState,useRef } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { HttpService } from './../service/HttpService'
import { Controller, useForm  } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { Message } from 'primereact/message';
import { Growl } from 'primereact/growl';
import {showMessage} from './../utility/AppUtility'

function ForgotPassword(props) {

    var [email, setEmail] = useState("")
    var [newPassword, setNewPassword] = useState("")
    var [otp, setOtp] = useState("")
    const growl = useRef(null)

    var [showMsg, setShowMsg] = useState(false)
    const httpService = new HttpService();
    const { handleSubmit, errors, control } = useForm({ mode: "onBlur" });
    const { control: control2, errors: errors2, handleSubmit: handleSubmit2 } = useForm({ mode: "onBlur" });


    var sendOtp = data => {

         
        httpService
            .sentOtp({ "email": data.email, "type": "forgotPassword" })
            .then((response) => {
                showMessage('info', 'Info Message', 'Otp send successfully',growl)

            })
            .catch((error) => {
                if(error?.response?.data?.title)
                showMessage('error', 'Error Message', error.response.data.title,growl)
               else 
                showMessage('warn', 'Warn Message', 'Your otp limit exceed, plase try after some time',growl)
            })
    }
    var showWardMessage =(message)=>{
        let msg = { severity: 'warn',  detail: message };
        growl.current.show(msg);
    }
   
    var showInfoMessage =(message)=>{
        let msg = { severity: 'info',  detail: message };
        growl.current.show(msg);
    }

    const submit = data => {
       
        httpService
            .resetPassowrd({ "email": email, "otp": data.otp, "newPassword": newPassword, "type": "forgotPassword"  })
            .then((response) => {
                showMessage('info', 'Info Message', 'Password reset successfully',growl)

                props.history.push('/login')
            })
            .catch((error) => {
                showMessage('error', 'Error Message', error.response.data.title,growl)


            })
    }

    return (<div className="login-body">

        <div className="login-type">
            {/* <ShowMessage></ShowMessage> */}
            <Growl ref={growl} style={{ marginTop: '75px' }} />


            <div className="card login-panel p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <img src="assets/layout/images/logo-icon1.png" width="45" height="45" alt="Fasiculus" />
                    </div>
                    <div className="p-col-12">
                        <span className="md-inputfield">

                            <Controller className={errors.newPassword ? "p-error" : ''} as={InputText} type="text" id="newPassword" name="newPassword"
                                rules={{ required: { value: true, message: "This field is required" }, maxLength: { value: 16, message: "You password must be 5-16 character" }, minLength: { value: 5, message: "You password must be 5-16 character" } }}
                                value={newPassword} onChange={(event) => { setNewPassword(event[0].target.value); return event[0].target.value }}
                                control={control} />

                            < label > New passowrd</label>
                        </span>
                        <span className="errorSpan">{errors.newPassword?.message}</span>
                    </div>
                    {/* <div className="p-col-12">
                        <span className="md-inputfield">

                            <Controller className={errors.mobileNo ? "p-error" : ''} as={InputText} type="text" id="mobileNo" name="mobileNo"
                                rules={{ required: { value: true, message: "This field is required" }, maxLength: { value: 15, message: "Value must be greather then 15" } }}
                                value={mobileNo} onChange={(event) => { setMobileNo(event[0].target.value); return event[0].target.value }}
                                control={control} />

                            < label >Mobile No.</label>
                        </span>
                        <span className="errorSpan">{errors.mobileNo?.message}</span>
                    </div> */}
                    <div className="p-col-12">
                        <span className="md-inputfield">

                            <Controller className={errors.email ? "p-error" : ''} as={InputText} type="text" id="mobileNo" name="email"
                                rules={{ required: { value: true, message: "This field is required" }, maxLength: { value: 200, message: "Value must be less then 200" } }}
                                value={email} onChange={(event) => { setEmail(event[0].target.value); return event[0].target.value }}
                                control={control} />

                            < label >Email ID</label>
                        </span>
                        <span className="errorSpan">{errors.email?.message}</span>
                    </div>
                    <div className="p-col-12 ">
                        <div className="p-md-6 floatLeft noPaddingLeft">
                            <span className="md-inputfield" />
                            <Button label="Request OTP" onClick={handleSubmit(sendOtp)}></Button>
                        </div>
                        <div className="p-md-6 floatLeft noPaddingRight">
                            <span className="md-inputfield">
                                <Controller className={errors2.otp ? "p-error" : ''} as={InputText} type="otp" id="otp" name="otp"
                                    rules={{ required: { value: true, message: "This field is required" } }}
                                    value={otp} onChange={(event) => { setOtp(event[0].target.value); return event[0].target.value }}
                                    control={control2} />
                                <label>Enter OTP</label>
                            </span>
                            <span className="errorSpan">{errors2.otp?.message}</span>
                        </div>
                    </div>
                    {/* <div className="p-col-12 p-md-6">
                        <span className="md-inputfield">
                            <Controller className={errors.otp ? "p-error" : ''} as={InputText} type="otp" id="otp" name="otp"
                                rules={{ required: { value: true, message: "This field is required" } }}
                                value={otp} onChange={(event) => { setOtp(event[0].target.value); return event[0].target.value }}
                                control={control} />
                            <label>Enter otp</label>
                        </span>
                        <span className="errorSpan">{errors.otp?.message}</span>
                    </div> */}

                    <div className="p-col-12">
                        <Button label="Submit" icon="pi-md-person" onClick={handleSubmit2(submit)} />
                        {/* <Button label="Forgot Password" icon="pi-md-help" className="pink-btn" /> */}
                        {showMsg && <Message severity="error" text="Plase try after some time" />}


                    </div>
                </div>
            </div>
        </div>


        {/* <div className="login-footer">PrimeReact Ultima</div> */}
    </div>
    )

}
export default withRouter(ForgotPassword);


