import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean ,showMessage} from '../../utility/AppUtility';
import rule from './../../constant/Rule';
import { TabView, TabPanel } from 'primereact/tabview';
import ViewObject from "./ViewObject"
import ViewList from "./ViewList"
import { AppBreadcrumb } from '../../AppBreadcrumb';


function View(props) {

    var title = '';
    var uri = '';
    var [contectPersons,setContectPersons]=useState([])
    var [plantProducts,setPlantProducts]=useState([])

    var data = {};
    var properties = [];
    if (props === undefined || props.location === undefined || props.location.state === undefined || props.location.state.title === undefined) {
        props.history.goBack();
    } else {
        title = props.location.state.title;
        uri = props.location.state.uri;
        data = props.location.state.data;
        properties = props.location.state.properties;
    }
    const httpService = new HttpService();

   
    useEffect(() => {
        if(props.location.state!==undefined)
        trackPromise(
            httpService.getApi(props.location.state.url +  props.location.state.data.id).then((response) => {
                if(response.data.plantProducts!=null && response.data.plantProducts!==undefined && response.data.plantProducts.length>0)
                setPlantProducts(response.data.plantProducts)
                setContectPersons(response.data.contectPersons)
            }).catch((error) => {
                // if (checkForError(error))
                //    showMessage('error', 'Error Message', error.response.data.title,growl)
            }));
    }, [])


    var condition=(props.location.state!==undefined && props.location.state.url!==undefined)
  

    return  <>
                <AppBreadcrumb breadcrumdItems={[{ label: title+' List',url:"#"+uri },{ label: title+' View' }]} />

    <div className="p-grid ">
        <div className="p-col-12">
        <div className="card card-w-title">
            {condition ? 
             <TabView  >
       
             <TabPanel header={title}>
             <ViewObject  title={title} data={data} properties={properties} plantProducts={plantProducts}></ViewObject>
             </TabPanel>
            
             <TabPanel header="Contact Person">
                 < ViewList title={"Contact Person"} list={contectPersons} ></ViewList>
             </TabPanel>
             
         </TabView>:
          <TabView  >
          <TabPanel header={title}>
          <ViewObject  title={title} data={data} properties={properties}></ViewObject>
          </TabPanel>
         
         
          
      </TabView>}
       
      {/* <div className="p-col-12">
                <Button icon="pi-md-cancel" label="Back" className="p-button-primary" onClick={() => cancel()} />
            </div> */}
        </div>
            </div>
            
        

    </div></>

}
export default withRouter(View);
