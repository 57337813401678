import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean } from '../../utility/AppUtility';
import rule from '../../constant/Rule';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AppBreadcrumb } from '../../AppBreadcrumb';


function ViewList(props) {



    let showDetail = []
    if (props.list != null)
        for (let index = 0; index < props.list.length; index++) {
            const element = props.list[index];

            showDetail.push(<>
                <div className="p-grid" ><div className="p-col-12" style={{ display: "inherit" }}> <div className="p-col-3">{"Name"} </div><div className="p-col-3">{element["name"]}</div></div><br /></div>
                <div className="p-grid" ><div className="p-col-12" style={{ display: "inherit" }}> <div className="p-col-3">{"Designation"} </div><div className="p-col-3">{element["designation"]}</div></div><br /></div>
            </>)

        }


    return (


        <>


            <div></div>
            {props.list !== null ? props.list.length > 0 ?
               
               <DataTable responsive={true}  columnResizeMode="fit" resizableColumns={true}  value={props.list} className="p-datatable-striped">
                    <Column field="name" header="Name"></Column>
                    <Column field="designation" header="Designation"></Column>
                    <Column field="mobile" header="Mobile"></Column>
                    <Column field="email" header="Email"></Column>
                </DataTable>
                : null : null

           

            }


        </>
    )

}
export default withRouter(ViewList);
