import React from "react";
import '../style/marquee.css';

export class Marquee extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="card">
            <div className="marquee-container">
                <div className="marquee-content">{this.props.text}</div>
            </div>
            </div>
        );
    }
}