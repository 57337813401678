import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import App from "./App";
import Login from "./pages/Login";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";
import { Wizard } from "./pages/Wizard";
import ForgotPassword from './pages/ForgotPassword';

class AppWrapper extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}

	render() {
		switch (this.props.location.pathname) {
			case "/":
				return <Route path="/" exact={true} component={Login} />
			case "/login":
				return <Route path="/login" component={Login} />
			case "/forgotpassowrd":
				return <Route path="/forgotpassowrd" component={ForgotPassword} />
			case "/error":
				return <Route path="/error" component={Error} />
			case "/notfound":
				return <Route path="/notfound" component={NotFound} />
			case "/access":
				return <Route path="/access" component={Access} />
			case "/wizard":
				return <Route path="/wizard" component={Wizard} />
			case "/dashboard":
			case "/user_profile":
			case "/user_list":
			case "/user_edit":
			case "/company_add":
			case "/company_edit":
			case "/company_list":
			case "/customer_add":
			case "/customer_edit":
			case "/customer_list":
			case "/business_partner_add":
			case "/business_partner_edit":
			case "/business_partner_list":
			case "/user_add":
			case "/template_add":
			case "/template_design":
			case "/template_history_list":
			case "/template_request_list":
			case "/design_template":
			case "/select_option":
			case "/print_data_add":
			case "/print_data_list":
			case "/printing_job_add":
			case "/printing_job_list":
			case "/template_histories":
			case "/label_production_add":
			case "/label_production_list":
			case "/label_production_edit":
			case "/plant_add":
			case "/plant_list":
			case "/plant_edit":
			case "/vendor_add":
			case "/vendor_list":
			case "/vendor_edit":
			case "/product_add":
			case "/product_list":
			case "/product_edit":
			case "/roll_file_list":
			case "/user_privladge":
			case "/view":
			case "/token_data_add":
			case "/token_data_list":
			case "/token_data_report":
			case "/master_product_add":
			case "/master_product_edit":
			case "/master_product_list":
			case "/matnr_add":
			case "/matnr_edit":
			case "/matnr_list":
			case "/invalid_scan_list":
			case "/report":
			case "/reports":
			case "/enduser_reward_reports":
			case "/guilloche_images_add":
			case "/guilloche_images_list":
			case "/guilloche_images_edit":
			case "/packaging_list":
			case "/packaging_add":
			case "/packaging_edit":
			case "/link_qr":
			case "/settings":
			case "/inventory_list":
			case "/inventory_report":
			case "/consignment_list":
			case "/consignment_fcode_list":
			case "/search_qr_code":
			case "/search":
			case "/download/invalid-scan-report":
			case "/smart-bin":
				return <App />

			default:
				return <Route path="/**" component={NotFound} />;

		}
	}
}

export default withRouter(AppWrapper);
