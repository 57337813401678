import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean,showMessage } from '../../utility/AppUtility';
import rule from './../../constant/Rule';
import { AppBreadcrumb } from '../../AppBreadcrumb';

var _ = require('lodash');

function PrintingJobAdd(props) {

    var [obj, setObj] = useState({})
    const growl = useRef(null)
    var [disableFile, setDisableFile] = useState(false)
    const { handleSubmit, errors, control } = useForm({ mode: "onBlur" });
    const httpService = new HttpService();



    const handleChange = (e, type) => {
        let val = e.target.value;

        setObj({ ...obj, [e.target.name]: val });
        return val;
    }
    const onFileSelect = (e) => {
        setObj({ ...obj, file: e.files[0] });
        setDisableFile(true)
    }
    const cancel = () => {
        props.history.push('printing_job_list')
    }
    
    const save = data => {

        obj = clean(obj)
        let formData = new FormData()
        for (const property in obj) {
            if (property === "files") {
                if (obj[property] !== undefined)
                    obj[property].forEach(element => {
                        formData.append("file", element);
                    });

            } else
                if (obj[property] !== undefined)
                    formData.append(property, obj[property]);
        }
        trackPromise(
            httpService.getApiMultipart('savePrintingJob', formData).then((response) => {
                cancel();
            }).catch((error) => {
                if (checkForError(error)) {
                   showMessage('error', 'Error Message', error.response.data.title,growl)
                }
            }));

    }

    const onRemoveImg = () => {
        _.unset(obj, 'file');
        setDisableFile(false)
    }


    return <><div className="p-grid ">
        <Growl ref={growl} style={{ marginTop: '75px' }} />

        <div className="p-col-10 ">
            <div className="card card-w-title">
                <h1>Add Printing Job</h1>
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-8">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                            <span className="md-inputfield">
                                <Controller className={errors.noOfJob ? "p-error" : ''} rules={{ ...rule.RULE.maxLengthRuleVal200 }} name="noOfJob" value={obj.noOfJob} as={InputText} control={control} onChange={(e) => handleChange(e[0])} />
                                <label>No. Of Job</label>
                            </span>
                        </div>
                        <span className="errorSpan merginLeft30">{errors.email?.message}</span>
                    </div>
                    <div className="p-col-8">
                        <label>Upload file</label>
                        <div className="p-col-8"></div>
                        <div className="p-inputgroup">
                            <Controller className={errors.file ? "p-error" : ''} disabled={disableFile} rules={{}} as={FileUpload} control={control} name="file"
                                onSelect={e => onFileSelect(e)} accept="text/csv" onRemove={() => onRemoveImg()} maxFileSize={10000000} customUpload={true} />
                        </div>
                        <span className="errorSpan merginLeft30">{errors.file?.message}</span>
                    </div>


                </div>
                <div className="p-col-12">
                    <Button icon="pi pi-check" label="Save" onClick={handleSubmit(save)} />
                    <Button icon="pi-md-cancel" label="Cancel" className="p-button-secondary" onClick={() => cancel()} />

                </div>
                <div className="p-col-8">

                </div>

            </div>
        </div>
        <div className="p-col-4"></div>
    </div></>

}
export default withRouter(PrintingJobAdd);
