import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { HttpService } from '../../service/HttpService';
import { checkForError, showMessage } from '../../utility/AppUtility';
import { AppBreadcrumb } from '../../AppBreadcrumb';
import { Grid } from '@material-ui/core';

export class InvalidScanList extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue: [],
            page: 1,
            totalRecords: 0,
            size: 100
        };

        this.httpService = new HttpService();
    }

    componentDidMount() {

        this.getList(this.state.page - 1);
    }
    getList = (page) => {
        trackPromise(
            this.httpService.getApi('invalid-scans').then((response) => {
                this.setState({ dataTableValue: response.data, totalRecords: response.data.totalElements });

            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            }));
    }
    showMessage(severity, summary, message) {
        let msg = { severity: severity, summary: summary, detail: message };
        this.growl.show(msg);
    }
    onPageChange = (event) => {
        this.setState({ page: event.page })
        this.getList(event.page)

    }

    download = () => {
        this.props.history.push("/download/invalid-scan-report");
    }

    render() {
        let actionHeader = <Button type="button" icon="pi-md-settings" />;

        return <>

            <AppBreadcrumb breadcrumdItems={[{ label: 'Invalid Scan List' }]} />
            <div className="p-grid">
                <div className="p-col-12">
                    <Growl ref={(el) => this.growl = el} style={{ marginTop: '75px' }} />

                    <div className="card card-w-title datatable-demo">
                        <Grid container>
                            <div className="p-col-6">
                                <font className="font21Weight500" >Invalid Scan</font>
                            </div>

                            <div className="p-col-6" dir='rtl'>
                                <Button type="button" icon='pi-md-file-download' label='Download' onClick={() => this.download()} />
                            </div>
                        </Grid>


                        <div className="p-col-12">
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue}
                            paginator={true} rows={10}
                            header="List of Invalid Scan"
                            responsive={true} resizableColumns={true} columnResizeMode="fit"
                        >
                            <Column field="name" header="Name" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains" />
                            <Column field="email" header="Email" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains" />
                            <Column field="mobile" header="Mobile" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains" />
                            <Column field="code" header="Code" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains" />
                            <Column field="customer.companyName" header="Client" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains" />


                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />


                        </DataTable>

                    </div>
                </div>
            </div></>
    }
}