export default{
    RULE : {
        requiredRule:{required:{value:true,message:"This field is required"}},
        maxLengthRuleVal25:{maxLength:{value:25,message:"Value must be less than 25"}},
        maxLengthRuleVal50:{maxLength:{value:50,message:"Value must be less than 50"}},
        maxLengthRuleVal100:{maxLength:{value:100,message:"Value must be less than 100"}},
        maxLengthRuleVal200:{maxLength:{value:200,message:"Value must be less than 200"}},
        maxLengthRuleVal250:{maxLength:{value:250,message:"Value must be less than 250"}},
        minRuleVal1:{min:{value:1,message:"Value can't be less than 1"}},
        minRuleVal0:{min:{value:0,message:"Value can't be less than 0"}},
        maxRuleVal12:{max:{value:12,message:"Value must be less than 12"}},
        maxRuleVal5:{max:{value:5,message:"Value must be less than 5"}},
        maxLengthRule15:{maxLength:{value:15,message:"Value must be less than 15"}},
        minLengthRule10:{minLength:{value:10,message:"Min Length 10"}},
        maxLengthRule7:{maxLength:{value:7,message:"Max Length 7"}},
        minRuleVal9:{min:{value:9,message:"Value can't be less than 9"}}


        
    }
}