import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Growl } from "primereact/growl";
import React, { Component } from "react";
import { trackPromise } from "react-promise-tracker";
import { HttpService } from "../../service/HttpService";
import { checkForError, showMessage } from "../../utility/AppUtility";
import constant from "./../../constant/Constant";
import { PriviladgeContext } from "./../../context/priviladge-context";
import CheckPriviladge from "./../../hoc/CheckPriviladge";
import { AppBreadcrumb } from "../../AppBreadcrumb";
import { InputText } from "primereact/inputtext";

export class RendomCodeGenerationList extends Component {
  constructor() {
    super();
    this.state = {
      dataTableValue: [],
      page: 1,
      totalRecords: 0,
      size: 10,
      first: 0,
      isSearch: false,
      searchKey: "",
      searchColumn: ""
    };
    this.httpService = new HttpService();
    this.cols = [
      { field: "month", header: "Month", width: "4%" },
      { field: "year", header: "Year", width: "4%" },
      { field: "client", header: "Client", width: "5%" },
      { field: "plant", header: "Plant", width: "5%" },
      { field: "labelSize", header: "Label Size", width: "5%" },
      { field: "vendor", header: "Vendor", width: "5%" },
      { field: "quantity", header: "Quantity", width: "5%" },
      { field: "product", header: "Product", width: "5%" },
      { field: "remark", header: "Remark", width: "5%" },
      { field: "genStatus", header: "Status", width: "5%" },
      { field: "createdDate", header: "Created Date", width: "5%" },
      { field: "download", header: "Download", width: "7%" },
    ];

    this.properties = [
      { key: "month", name: "Month" },
      { key: "year", name: "Year" },
      { key: "client.companyName", name: "Client" },
      { key: "product.labelSize", name: "Label Size" },
      { key: "plant.name", name: "Plant" },
      { key: "vendor.name", name: "Vendor" },
      { key: "up", name: "Up" },
      { key: "quantity", name: "Quantity" },
      { key: "rollDetails.producedQuantity", name: "Produced Quantity" },
      { key: "rollDetails.pendingQuantity", name: "Pending Quantity" },
      { key: "product.productCode", name: "Product" },
      { key: "remark", name: "Remark" },
      { key: "rollDetails.productionStatus", name: "Production Status" },
    ];
  }
  static contextType = PriviladgeContext;
  componentDidMount() {
    this.getList(this.state.page - 1);
  }
  getList = (page) => {
    trackPromise(
      this.httpService
        .getApi(
          "get/companyInfo?page=" +
          page +
          "&size=" +
          this.state.size +
          "&sort=createdDate,desc"
        )
        .then((response) => {
          this.setState({
            dataTableValue: response.data.content,
            totalRecords: response.data.totalElements,
          });
        })
        .catch((error) => {
          if (checkForError(error))
            this.showMessage(
              "error",
              "Error Message",
              error.response.data.title
            );
        })
    );
  };
  showMessage(severity, summary, message) {
    let msg = { severity: severity, summary: summary, detail: message };
    this.growl.show(msg);
  }
  onPageChange = (event) => {
    this.setState({
      page: event.page,
      first: event.first
    });
    if (this.state.isSearch) {
      this.customFilter(this.state.searchKey, this.state.searchColumn, event.page)
    } else {
      this.getList(event.page);
    }
  };
  add = () => {
    this.props.history.push("print_data_add");
  };
  view = (rowData) => {
    this.props.history.push({
      pathname: "/view",
      state: {
        data: rowData,
        title: "Print Data",
        uri: "print_data_list",
        properties: this.properties,
      },
    });
  };

  download = (rowData, ext) => {
    console.log(rowData)
    var a = document.createElement("a");
    a.href = constant.TEMPLATE_URl + rowData.fileName + "." + ext;
    a.download = rowData.fileName + "." + ext;
    a.click();
    a.remove();
  };
  go = (rowData) => {
    if (rowData.rollDetails == null) {
      this.props.history.push({
        pathname: "/label_production_add",
        state: { id: rowData.id },
      });
    } else {
      this.props.history.push({
        pathname: "/label_production_edit",
        state: { id: rowData.rollDetails.id },
      });
    }
  };

  customFilter(searchValue, columnName, pageNo) {
    if (searchValue == "") {
      this.setState({ isSearch: false, page: 1 })
      this.getList(0);
    } else {
      this.setState({
        searchKey: searchValue,
        searchColumn: columnName,
        isSearch: true,
        page: 0
      })
      this.httpService.searchApi(`company-info/search?columnName=${columnName}&searchKey=${searchValue}&page=${pageNo}&size=${this.state.size}`).then((response) => {
        this.setState({ dataTableValue: response.data.content, totalRecords: response.data.totalElements });
      }).catch((error) => {
        if (checkForError(error))
          this.showMessage('error', 'Error Message', error.response.data.title)
      })
    }

  }


  renderSearchElement(columnName) {
    return (
      <React.Fragment>
        <InputText onChange={(e) => this.customFilter(e.target.value, columnName, 0)} placeholder="Contains" ></InputText>
      </React.Fragment>
    );
  }



  actionTemplate = (rowData, column) => {
    return (
      <div style={{ width: "150px", marginLeft: "-15px" }}>
        {rowData.genStatus !== "RUNNING" ? (
          <>
            {(rowData.rollDetails?.productionStatus !== "CLOSE" ||
              this.context.isAdmin) && (
                <>
                  <Button
                    type="button"
                    tooltip="Excel"
                    tooltipOptions={{ position: "bottom" }}
                    icon="fas fa-file-excel"
                    className="p-button-info"
                    onClick={() => this.download(rowData, "xlsx")}
                  />
                  <Button
                    type="button"
                    tooltip="Csv"
                    tooltipOptions={{ position: "bottom" }}
                    icon="fas fa-file-csv"
                    className="p-button-info"
                    onClick={() => this.download(rowData, "csv")}
                  />{" "}
                </>
              )}
            <CheckPriviladge
              priviladge={{ name: "Label Production", priority: 2 }}
            >
              <Button
                type="button"
                icon="pi-md-location-city"
                tooltip="Label Production"
                tooltipOptions={{ position: "bottom" }}
                className="p-button-info"
                onClick={() => this.go(rowData)}
              />{" "}
            </CheckPriviladge>
          </>
        ) : null}
        <Button
          type="button"
          tooltip="View"
          tooltipOptions={{ position: "bottom" }}
          icon="pi-md-remove-red-eye"
          className="p-button-info"
          onClick={() => this.view(rowData)}
        />
      </div>
    );
  };
  render() {
    let actionHeader = <Button type="button" icon="pi-md-settings" />;

    return (
      <>
        <AppBreadcrumb
          breadcrumdItems={[{ label: "Print Data Generator list" }]}
        />

        <div className="p-grid">
          <div className="p-col-12">
            <Growl
              ref={(el) => (this.growl = el)}
              style={{ marginTop: "75px" }}
            />

            <div className="card card-w-title datatable-demo">
              <div className="p-col-12">
                <font className="font21Weight500">
                  Print Data Generator list
                </font>

                <CheckPriviladge
                  priviladge={{ name: "Print Data", priority: 2 }}
                >
                  {" "}
                  <Button
                    className="floatRight"
                    icon="pi-md-plus"
                    tooltip="Add Print Data"
                    label="Add"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => this.add()}
                  />
                </CheckPriviladge>
              </div>
              <div className="p-col-12"></div>
              <DataTable
                ref={(el) => (this.dt = el)}
                value={this.state.dataTableValue}
                header="List of Print Data "
                paginator={true}
                lazy
                totalRecords={this.state.totalRecords}
                rows={10}
                first={this.state.first}
                onPage={(event) => this.onPageChange(event)}
                responsive={true}
                resizableColumns={true}
                columnResizeMode="fit"
              >
                <Column
                  filterElement={this.renderSearchElement("month")}
                  field="month"
                  header="Month"
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  filterElement={this.renderSearchElement("plantName")}
                  field="plant.name"
                  header="Plant"
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  filterElement={this.renderSearchElement("productCode")}
                  field={"product" != null ? "product.productCode" : ""}
                  header="Product"
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  filterElement={this.renderSearchElement("quantity")}
                  field="quantity"
                  header="Quantity"
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  filterElement={this.renderSearchElement("producedQuantity")}
                  field="rollDetails.producedQuantity"
                  header="Produced Quantity"
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  filterElement={this.renderSearchElement("pendingQuantity")}
                  field="rollDetails.pendingQuantity"
                  header="Pending Quantity"
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  filterElement={this.renderSearchElement("productionStatus")}
                  field="rollDetails.productionStatus"
                  header="Production Status"
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />

                <Column
                  header={actionHeader}
                  body={this.actionTemplate}
                  style={{ textAlign: "center", width: "11em" }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </>
    );
  }
}
