import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Growl } from "primereact/growl";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import { withRouter } from "react-router-dom";
import { HttpService } from "../service/HttpService";
import { checkForError, clean, showMessage } from "../utility/AppUtility";
import rule from "./../constant/Rule";
import { AppBreadcrumb } from "../AppBreadcrumb";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import constant from "../constant/Constant";


var _ = require("lodash");

function InventoryReport(props) {

  var [obj, setObj] = useState({});
  var [customer, setCustomerList] = useState([]);
  var [minDate] = useState(new Date());
  var [maxDate] = useState(new Date());

  const growl = useRef(null);

  const { handleSubmit, errors, control, setValue } = useForm({
    mode: "onBlur",
  });
  const httpService = new HttpService();

  useEffect(() => {

    trackPromise(
      httpService
        .getApi(
          "customers/company?page=" +
          0 +
          "&size=" +
          10000 +
          "&sort=createdDate,desc&type=Customer"
        )
        .then((response) => {
          setCustomerList(response.data.content);
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  }, []);

  const handleChange = (e, type) => {
    let val = e.target.value;
    if (type === "date") {
      val = moment(new Date(e.target.value))
        .format("YYYY-MM-DD")
        .toString();
    }
    setObj({ ...obj, [e.target.name]: val });
    if (type === "date") {
      return e.target.value;
    } else return val;
    setObj({ ...obj, [e.target.name]: e.target.value });
    return e.target.value;
  };

  const cancel = () => {
    props.history.goBack();
  };

  const download = (data) => {
    obj = clean(obj);
    if (obj.customer != undefined) {
      obj.customerName = obj.customer.companyName;
    }
    trackPromise(
      httpService
        .postApi("v1/inventory_item/report", obj)
        .then((response) => {
          var a = document.createElement("a");
          a.href = constant.TEMPLATE_URl + response.data.fileName + ".xlsx";;
          a.download = response.data.fileName + ".xlsx";
          a.click();
          a.remove();
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };

  return (
    <>
      <AppBreadcrumb breadcrumdItems={[{ label: "Inventory Transaction Reports" }]} />

      <div className="p-grid">
        <Growl ref={growl} style={{ marginTop: "75px" }} />

        <div className="p-col-10 ">
          <div className="card card-w-title">
            <h1>Download Inventory Transaction Report</h1>
            <div className="p-grid p-fluid form-group">
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">insert_invitation</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      maxDate={maxDate}
                      className={errors.fromDate ? "p-error" : ""}
                      name="fromDate"
                      value={obj.fromDate}
                      rules={{ ...rule.RULE.minRuleVal1 }}
                      rules={{ ...rule.RULE.requiredRule }}
                      as={Calendar}
                      control={control}
                      onChange={(e) => handleChange(e[0], "date")}
                      monthNavigator={true}
                      yearNavigator={true}
                      yearRange="2010:2070"
                      dateFormat="yy-MM-dd"
                    />
                    <label>From Date</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.fromDate?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">insert_invitation</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      //minDate={minDate}
                      className={errors.toDate ? "p-error" : ""}
                      name="toDate"
                      value={obj.toDate}
                      rules={{ ...rule.RULE.minRuleVal1 }}
                      rules={{ ...rule.RULE.requiredRule }}
                      as={Calendar}
                      control={control}
                      onChange={(e) => handleChange(e[0], "date")}
                      monthNavigator={true}
                      yearNavigator={true}
                      yearRange="2010:2070"
                      dateFormat="yy-MM-dd"
                    />
                    <label>To Date</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.toDate?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">person</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      optionLabel="companyName"
                      key="companyCode"
                      optionValue="id"
                      filter
                      className={errors.customer ? "p-error" : ""}
                      // rules={{ ...rule.RULE.requiredRule }}
                      as={Dropdown}
                      control={control}
                      options={customer}
                      placeholder={"Customer"}
                      name="customer"
                      value={obj.customer}
                      onChange={(e) => handleChange(e[0])}
                    />
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.customer?.message}
                </span>
              </div>

            </div>
            <div className="p-col-12">
              <Button
                icon="pi pi-check"
                label="Download"
                onClick={handleSubmit(download)}
              />
              <Button
                icon="pi-md-cancel"
                label="Cancel"
                className="p-button-secondary"
                onClick={() => cancel()}
              />
            </div>
            <div className="p-col-8"></div>
          </div>
        </div>
        <div className="p-col-4"></div>
      </div>
    </>
  );
}
export default withRouter(InventoryReport);
