import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean,showMessage } from '../../utility/AppUtility';
import constant from './../../constant/Constant';
import rule from './../../constant/Rule';
import { AppBreadcrumb } from '../../AppBreadcrumb';

var _ = require('lodash');

function TemplateAdd(props) {

    let id = null
    if (props === undefined || props.location === undefined || props.location.state === undefined || props.location.state.id === undefined) {
        props.history.push('customer_list')
    } else {
        id = props.location.state.id;
    }
    

    var [obj, setObj] = useState({ "customerId": id })
    var [editObj, setEditObj] = useState({ "customerId": id })
    var [generateObj, setGenerateObj] = useState({ "customerId": id })
    var [disableFile, setDisableFile] = useState(false)
    var [disableFile1, setDisableFile1] = useState(false)

    const growl = useRef(null)
    var [list, setList] = useState([])
    var [list1, setList1] = useState([])
    var [changeState, setChangeState] = useState(false)

    const [displayAddDialog, setDisplayAddDialog] = useState(false)
    const [displayEditDialog, setDisplayEditDialog] = useState(false)
    const [displayGenerateDialog, setDisplayGenerateDialog] = useState(false)


    const { handleSubmit, errors, control, reset } = useForm({ mode: "onBlur" });
    const httpService = new HttpService();
    

    useEffect(() => {
        
        
        trackPromise(
            httpService.getApi('templates/customer/' + id + "?sort=createdDate,desc").then((response) => {
                setList(response.data)
            }).catch((error) => {
                if (checkForError(error))
                   showMessage('error', 'Error Message', error.response.data.title,growl)
            }))
    }, [id, changeState])

    const handleChange = (e) => {
        setObj({ ...obj, [e.target.name]: e.target.value });
        return e.target.value;
    }
    const handleChangeEdit = (e) => {
        setEditObj({ ...editObj, [e.target.name]: e.target.value });
        return e.target.value;
    }
    const handleChangeGenerate = (e) => {
        setGenerateObj({ ...generateObj, [e.target.name]: e.target.value });
        return e.target.value;
    }
    const onFileSelect = (e) => {
        setObj({ ...obj, file: e.files[0] });
        setDisableFile(true)
    }
    const onRemoveImg = () => {
        _.unset(obj, 'file');
        setDisableFile(false)
    }
    const onFileSelect1 = (e) => {
        setObj({ ...generateObj, file: e.files[0] });
        setDisableFile1(true)
    }
    const onRemoveImg1 = () => {
        _.unset(generateObj, 'file');
        setDisableFile1(false)
    }
    const onSelectObj = (e) => {

        setList1(e);
        // return e.target.value;
    }
    

    const clearEdit = () => {
        setEditObj({ ...editObj, description: '', file: '' })
        reset({
            description: '',
            file: ''
        }, {
            errors: false, // errors will not be reset 
            dirtyFields: false, // dirtyFields will not be reset
            dirty: false, // dirty will not be reset
            isSubmitted: false,
            touched: false,
            isValid: false,
            submitCount: false,
            data: false
        })
    }
    const clearGenerate = () => {
        setEditObj({ ...generateObj, quantity: '' })
        reset({
            quantity: ''
        }, {
            errors: false, // errors will not be reset 
            dirtyFields: false, // dirtyFields will not be reset
            dirty: false, // dirty will not be reset
            isSubmitted: false,
            touched: false,
            isValid: false,
            submitCount: false,
            data: false
        })
    }
    const clear = () => {
        setObj({ ...obj, description: '', file: '' })
        reset({
            description: '',
            file: ''
        }, {
            errors: false, // errors will not be reset 
            dirtyFields: false, // dirtyFields will not be reset
            dirty: false, // dirty will not be reset
            isSubmitted: false,
            touched: false,
            isValid: false,
            submitCount: false,
            data: false
        })
    }
    const saveWithDesign = data => {
        
        obj = clean(obj)
        let formData = new FormData()
        for (const property in obj) {
            if (obj[property] !== undefined)
                formData.append(property, obj[property]);
        }
        trackPromise(
            httpService.getApiMultipart('templates', formData).then((response) => {

                clearEdit()
                setDisplayAddDialog(false)
                setChangeState(!changeState)
                setDisableFile(false)
                selectOption(response.data.id)
            }).catch((error) => {
                setDisableFile(false)
                if (checkForError(error))
                   showMessage('error', 'Error Message', error.response.data.title,growl)
            }));
    }
    const save = data => {
        
        obj = clean(obj)
        let formData = new FormData()
        for (const property in obj) {
            if (obj[property] !== undefined)
                formData.append(property, obj[property]);
        }
        trackPromise(
            httpService.getApiMultipart('templates', formData).then((response) => {
                clearEdit()
                setDisplayAddDialog(false)
                setChangeState(!changeState)
                setDisableFile(false)
            }).catch((error) => {
                setDisableFile(false)
                if (checkForError(error))
                   showMessage('error', 'Error Message', error.response.data.title,growl)
            }));

    }
    const update = data => {
        
        _.unset(editObj, 'customer');
        obj = clean(editObj)
        let formData = new FormData()
        for (const property in obj) {
            if (obj[property] !== undefined)
                formData.append(property, obj[property]);
        }
        trackPromise(
            httpService.getApiMultipartPut
                ('templates', formData).then((response) => {
                    clearEdit()
                    setDisplayEditDialog(false)
                    setChangeState(!changeState)
                }).catch((error) => {

                    if (checkForError(error))
                       showMessage('error', 'Error Message', error.response.data.title,growl)
                }));

    }
    const generate = data => {
        
        obj = clean(generateObj)
        obj.templateId = obj.id;
        _.unset(obj, 'id');
        let formData = new FormData()
        for (const property in obj) {
            if (property === "files") {
                if (obj[property] !== undefined)
                    obj[property].forEach(element => {
                        formData.append("file", element);
                    });

            } else
                if (obj[property] !== undefined)
                    formData.append(property, obj[property]);
        }
        trackPromise(
            httpService.getApiMultipart
                ('template-histories', obj).then((response) => {
                    clearGenerate()
                    setDisplayGenerateDialog(false)
                    setChangeState(!changeState)
                }).catch((error) => {

                    if (checkForError(error))
                       showMessage('error', 'Error Message', error.response.data.title,growl)
                }));

    }


    const edit = (edobj) => {
        setEditObj({ ...editObj, ...edobj })
        reset({ ...edobj })
        setDisplayEditDialog(true)
    }
    const generateFile = (edobj) => {
        setGenerateObj({ ...generateObj, ...edobj })
        reset({ ...edobj })
        setDisplayGenerateDialog(true)
    }
    const download = (rowData) => {
        var a = document.createElement('a');
        a.href = constant.MASTER_TEMPLATE_URl + rowData.name;
        a.download = rowData.name;
        a.target = '_blank';
        a.click();
        a.remove();
    }

    let actionHeader = <Button type="button" icon="pi-md-settings" />;
    const actionTemplate = (rowData, column) => {
        return <div style={{ width: "110px" }}>

            <Button type="button" tooltip="Download" tooltipOptions={{ position: 'bottom' }} icon="pi-md-file-download" className="p-button-info" onClick={() => download(rowData)} style={{ marginRight: '.5em' }} />
            <Button type="button" tooltip="Edit" tooltipOptions={{ position: 'bottom' }} icon="pi-md-pencil" className="p-button-warning" onClick={() => edit(rowData)} />
            {rowData.status !== 'PENDING' && <Button type="button" tooltip="Generate token" tooltipOptions={{ position: 'bottom' }} icon="pi-md-insert-drive-file" className="p-button-success" onClick={() => generateFile(rowData)} />}

        </div>;
    }

    const cancel = () => {
        clear()
        setDisplayAddDialog(false)
    }
    const cancelEdit = () => {
        clearEdit()
        setDisplayEditDialog(false)
    }
    const cancelGenerate = () => {
        setDisplayGenerateDialog(false)
    }

    const selectOption = (id1) => {
        props.history.push({
            pathname: '/select_option',
            state: { id: id1 }
        })
    }

    let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="Cancel" icon="pi-md-clear" className="p-button-secondary" onClick={() => cancel()} />
        <Button label="Save" icon="pi pi-check"
            onClick={handleSubmit(save)} />
        <Button label="Save And Design" icon="pi pi-check"
            onClick={handleSubmit(saveWithDesign)} />
    </div>
    let dialogFooterEdit = <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="Cancel" icon="pi-md-clear" className="p-button-secondary" onClick={() => cancelEdit()} />
        <Button label="Save" icon="pi pi-check"
            onClick={handleSubmit(update)} />
    </div>
    let dialogFooterGenarate = <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="Cancel" icon="pi-md-clear" className="p-button-secondary" onClick={() => cancelGenerate()} />
        <Button label="Generate" icon="pi pi-check"
            onClick={handleSubmit(generate)} />
    </div>

    return <><div className="p-grid">
        <div className="p-col-10">
            <Growl ref={growl} style={{ marginTop: '75px' }} />
            <div className=" datatable-demo">
                <div className="p-col-12">
                    {/* <Button className="floatRight" icon="pi-md-file-upload"
                        tooltip="Upload Template" label="Upload Template" tooltipOptions={{ position: 'left' }}
                        onClick={() => setDisplayAddDialog(true)} /> */}
                    {/* <Button className="floatRight" icon="pi-md-brush"
                        tooltip="Design Template" label="Design Template" tooltipOptions={{ position: 'left' }}
                        onClick={() => dragAndDrop()} /> */}
                </div>
                <div className="p-col-12">
                </div>
                <div className="p-col-12">
                </div>
                <DataTable value={list} selectionMode="single" header="List of template" paginator={true} rows={10}
                    responsive={true} selection={list1} onSelectionChange={event => onSelectObj(event)} resizableColumns={true} columnResizeMode="fit">
                    <Column field="description" header="Description" sortable={true} filter={true} filterPlaceholder="Contains" />
                    <Column field="status" header="Status" sortable={true} filter={true} filterPlaceholder="Contains" />
                    <Column header={actionHeader} body={actionTemplate} style={{ textAlign: 'center', width: '9em' }} />
                </DataTable>
            </div>
        </div>
        <Dialog id="addServiceDialog" visible={displayAddDialog} style={{ width: "500px !important" }} header={"Add Template"} modal={true} footer={dialogFooter} onHide={() => cancel()}>
            {
                <div className="p-grid p-fluid form-group">
                    {/* <div className="card card-w-title">
                        <div className="p-grid  p-fluid form-group"> */}
                    <div className="p-col-12">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                            <span className="md-inputfield">
                                <Controller className={errors.description ? "p-error" : ''} name="description" value={obj.description} rules={{
                                    ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal100,
                                    ...rule.RULE.minRuleVal1
                                }} as={InputText} control={control} onChange={(e) => handleChange(e[0])} />
                                <label >Description</label>
                            </span>
                        </div>
                        <span className="errorSpan merginLeft30">{errors.description?.message}</span>
                    </div>
                    <div className="p-col-12">
                        <div className="p-inputgroup">
                            <Controller style={{ width: "400px !important" }} className={errors.file ? "p-error" : ''} disabled={disableFile} rules={{}} as={FileUpload} control={control} name="file"
                                onSelect={e => onFileSelect(e)} accept="pdf" onRemove={() => onRemoveImg()} maxFileSize={10000000} customUpload={true} />
                        </div>
                        <span className="errorSpan merginLeft30">{errors.file?.message}</span>
                    </div>
                    {/* 
                        </div>
                    </div> */}
                </div>
            }
        </Dialog>
        <Dialog id="editServiceDialog" visible={displayEditDialog} header={"Edit Template"} modal={true} footer={dialogFooterEdit} onHide={() => cancelEdit()}>
            {
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-12">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>

                            <span className="md-inputfield">
                                <Controller className={errors.description ? "p-error" : ''} name="description" value={editObj.description} rules={{
                                    ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal200,
                                    ...rule.RULE.minRuleVal1
                                }} as={InputText} control={control} onChange={(e) => handleChangeEdit(e[0])} />
                                <label >Description</label>
                            </span>
                        </div>
                        <span className="errorSpan merginLeft30">{errors.description?.message}</span>
                    </div>
                </div>
            }
        </Dialog>
        <Dialog id="editServiceDialog" visible={displayGenerateDialog} header={"Generate Template"} modal={true} footer={dialogFooterGenarate} onHide={() => cancelGenerate()}>
            {
                <div className="p-grid p-fluid form-group">
                    <div className="p-col-12">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                            <span className="md-inputfield">
                                <Controller className={errors.quantity ? "p-error" : ''} name="quantity" type="number" value={generateObj.quantity} rules={{
                                    ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRule7,
                                    ...rule.RULE.minRuleVal1
                                }} as={InputText} control={control} onChange={(e) => handleChangeGenerate(e[0])} />
                                <label >Quantity</label>
                            </span>

                        </div>
                        <span className="errorSpan merginLeft30">{errors.quantity?.message}</span>

                    </div>

                    <div className="p-col-12">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="material-icons">format_size</i></span>
                            <span className="md-inputfield">
                                <Controller className={errors.expirationDate ? "p-error" : ''} name="expirationDate" value={generateObj.expirationDate} rules={{
                                    ...rule.RULE.maxLengthRule7,
                                    ...rule.RULE.minRuleVal1
                                }} as={InputText} control={control} onChange={(e) => handleChangeGenerate(e[0])} />
                                <label >Expiration Date</label>
                            </span>
                        </div>
                    </div>
                   
                    <div className="p-col-12">
                        <div className="p-inputgroup">
                            <Controller style={{ width: "400px !important" }} className={errors.file ? "p-error" : ''} disabled={disableFile1} rules={{}} as={FileUpload} control={control} name="file"
                                onSelect={e => onFileSelect1(e)} accept="pdf" onRemove={() => onRemoveImg1()} maxFileSize={20000000} customUpload={true} />
                        </div>
                        <span className="errorSpan merginLeft30">{errors.file?.message}</span>
                    </div>
                </div>
            }
        </Dialog>
    </div></>

}
export default withRouter(TemplateAdd);
