import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { HttpService } from '../../service/HttpService';
import { checkForError,showMessage } from '../../utility/AppUtility';
import constant from './../../constant/Constant';
import {PriviladgeContext} from './../../context/priviladge-context'
import  CheckPriviladge from './../../hoc/CheckPriviladge'
import { AppBreadcrumb } from '../../AppBreadcrumb';

export class PrintingJobList extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue: [],
            page: 1,
            totalRecords: 0,
            size: 100
        };

        this.httpService = new HttpService();
        this.codeType = [
            { label: 'Alphanumeric', value: 'Alphanumeric' },
            { label: 'Numeric', value: 'Numeric' }
        ]
        this.fontType = [
            { label: 'Times Roman', value: 'Times-Roman' },
            { label: 'Helvetica', value: 'Helvetica' },
            { label: 'Courier', value: 'Courier' }
        ]

    }


    componentDidMount() {

        this.getList(this.state.page - 1);
    }
    getList = (page) => {
        trackPromise(
            this.httpService.getApi('getPrintingJob?page=' + page + "&size=" + this.state.size + "&sort=createdDate,desc").then((response) => {
                this.setState({ dataTableValue: response.data.content, totalRecords: response.data.totalElements });

            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            }));
    }
    showMessage(severity, summary, message) {
        let msg = { severity: severity, summary: summary, detail: message };
        this.growl.show(msg);
    }
    onPageChange = (event) => {
        this.setState({ page: event.page })
        this.getList(event.page)

    }

    download = (rowData, ext) => {
        var a = document.createElement('a');
        a.href = constant.TEMPLATE_URl + rowData.fileName;
        a.download = rowData.fileName;
        a.click();
        a.remove();
    }
    sendForPrinting = (rowData) => {
        trackPromise(
            this.httpService.getApi('printingJob/storeftp/' + rowData.id).then((response) => {

            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            }));
    }



    actionTemplate = (rowData, column) => {
        return <div style={{ width: "110px" }}>
            <Button type="button" tooltip="Csv" tooltipOptions={{ position: 'bottom' }} icon="pi-md-file-download" className="p-button-info" onClick={() => this.download(rowData, 'csv')} />
            <Button type="button" tooltip="Send For Printing" tooltipOptions={{ position: 'bottom' }} icon="pi-md-print" className="p-button-info" onClick={() => this.sendForPrinting(rowData)} />

        </div>;
    }
    render() {
        let actionHeader = <Button type="button" icon="pi-md-settings" />;

        return <><div className="p-grid">
            <div className="p-col-12">
                <Growl ref={(el) => this.growl = el} style={{ marginTop: '75px' }} />

                <div className="card card-w-title datatable-demo">
                    <div className="p-col-12">
                        <font className="font21Weight500" >Printing Job List</font>


                    </div>
                    <div className="p-col-12">
                    </div>
                    <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} header="List of customer"
                        paginator={true} rows={10}
                        responsive={true} resizableColumns={true} columnResizeMode="fit"
                    >
                        <Column field="fileName" header="File Name" sortable={true} filter={true} filterPlaceholder="Contains" />
                        <Column field="status" header="Status" sortable={true} filter={true} filterPlaceholder="Contains" />
                        <Column field="printingStatus" header="Printing Status" sortable={true} filter={true} filterPlaceholder="Contains" />
                        <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '9em' }} />


                    </DataTable>

                </div>
            </div>
        </div></>
    }
}