import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { HttpService } from '../../service/HttpService';
import { checkForError,showMessage } from '../../utility/AppUtility';
import constant from './../../constant/Constant';
import {PriviladgeContext} from './../../context/priviladge-context'
import  CheckPriviladge from './../../hoc/CheckPriviladge'
import { AppBreadcrumb } from '../../AppBreadcrumb';

export class RollFileList extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue: [],
            page: 1,
            totalRecords: 0,
            size: 100
        };

        this.httpService = new HttpService();
    }

    componentDidMount() {

        this.getList(this.state.page - 1);
    }
    getList = (page) => {
        trackPromise(
            this.httpService.getApi('rolls/completed?page=' + page + "&size=" + this.state.size + "&sort=createdDate,desc").then((response) => {
                this.setState({ dataTableValue: response.data, totalRecords: response.data.totalElements });

            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            }));
    }
    showMessage(severity, summary, message) {
        let msg = { severity: severity, summary: summary, detail: message };
        this.growl.show(msg);
    }
    onPageChange = (event) => {
        this.setState({ page: event.page })
        this.getList(event.page)

    }


    edit = (rowData) => {
        trackPromise(
            this.httpService.getApi('rolls/file/'+rowData.id).then((response) => {
                this.showMessage('info', 'Info Message',"Request Successfully submited please wait for some time")
            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            }));
    }

    add = () => {
        this.props.history.push('vendor_add')
    }
    delete = (rowData) => {
        trackPromise(
            this.httpService.deleteApi('vendors/' + rowData.id).then((response) => {
                this.getList(0);
            }).catch((error) => {

            }));
    }
    download = (rowData) => {
        var a = document.createElement('a');
        a.href = constant.TEMPLATE_URl + rowData.rollFile;
        a.download = rowData.rollFile;
        a.target = '_blank';
        a.click();
        a.remove();
    }
    returnHeader = () => {
        return (
            <div>
                <div> List of Roll files</div>

                <div className="p-datatable-globalfilter-container">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" />
                </div>
            </div>
        );
    }
    actionTemplate = (rowData, column) => {
        return <div>
            {
                rowData.rollFileStatus==='COMPLETED'?
            <Button type="button" label="Donwload" tooltipOptions={{ position: 'bottom' }}  className="p-button-danger" onClick={() => this.download(rowData)} style={{ marginRight: '.5em' }} />
            : (rowData.rollFileStatus!=='RUNNING' && <Button type="button" tooltip="Generate" label="Generate" tooltipOptions={{ position: 'bottom' }}  className="p-button-warning" onClick={() => this.edit(rowData)} />)

    }
    
        </div>;
    }
    render() {
        let actionHeader = <Button type="button" icon="pi-md-settings" />;

        return <> 
        
        <AppBreadcrumb breadcrumdItems={[{ label: 'Label Production File List'}]} />

        <div className="p-grid">
            <div className="p-col-12">
                <Growl ref={(el) => this.growl = el} style={{ marginTop: '75px' }} />

                <div className="card card-w-title  datatable-demo">
                    <div className="p-col-12">
                        <font className="font21Weight500" >Roll files</font>

                        </div>
                    <div className="p-col-12">
                    </div>
                    <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue}
                        paginator={true} rows={10}
                        header="List Of roll files"
                        responsive={true} resizableColumns={true} columnResizeMode="fit"
                    >
                        <Column field="id" header="Roll Id" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains"/>
                        <Column field="createdBy" header="Created By" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains"/>
                        <Column field="startingToken" header="StartingToken" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains"/>
                        <Column field="endingToken" header="EndingToken" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains"/>
                        <Column field="rollFileStatus" header="Roll File Status" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains"/>
                        <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} />


                    </DataTable>

                </div>
            </div>
        </div></>
    }
}