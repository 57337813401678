import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { HttpService } from '../../service/HttpService';
import { checkForError, showMessage } from '../../utility/AppUtility';
import { AppBreadcrumb } from '../../AppBreadcrumb';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import Constant from '../../constant/Constant';
import { Button as DialogButton, Grid } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';

export class SmartBinList extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue: [],
            page: 1,
            totalRecords: 0,
            size: 10,
            trackNTrace: false,
            passwordModal: false,
            first: 0,
            isSearch: false,
            searchKey: "",
            searchColumn: "",
            file: "",
            dialogOpen: false,
            fromDate: "",
            toDate: '',
            deleteByDateRange: false,
            id: "",
            viewDatePickers: false,
            downloadDialog: false
        };

        this.httpService = new HttpService();
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
        this.fileUpload = React.createRef();
    }

    downloadByDateRange = () => {
        this.httpService.getApi(`smart-bin/?fromDate=${this.state.fromDate}&toDate=${this.state.toDate}`).then((response) => {
            this.download(response.data);
            this.setState({ toDate: "", fromDate: "" })
        }).catch((error) => {
            if (checkForError(error)) {
                this.setState({ downloadDialog: false })
                this.showMessage('error', "Error", error.response.data.title)
            }
        })
    }

    onClick(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.setState(state);
    }

    onHide = () => {
        this.setState({ dialogOpen: false, viewDatePickers: false, downloadDialog: false });
    }
    renderFooter(name) {
        return (
            <div>
                {
                    this.state.viewDatePickers ?
                        <>
                            <Button label="Cancel" icon="pi pi-times" onClick={() => this.onHide()} className="p-button-text" />
                            <Button label="Delete" icon="pi pi-trash" onClick={() => this.delete()} />
                        </>
                        : this.state.downloadDialog ? <>
                            <Button label="Cancel" icon="pi pi-times" onClick={() => this.onHide()} className="p-button-text" />
                            <Button label="Download" icon="pi pi-md-file-download" onClick={() => this.downloadByDateRange()} />
                        </> : <></>
                }
            </div>
        );
    }

    delete = () => {
        this.httpService.deleteApi(`smart-bin/?id=${this.state.id}&fromDate=${this.state.fromDate}&toDate=${this.state.toDate} `).then((response) => {
            this.showMessage('info', "Info", response.data);
            this.onHide();
            this.setState({
                fromDate: "", toDate: ""
            })
            this.getList(0);
        }).catch((error) => {
            if (checkForError(error)) {
                this.showMessage('error', "Error", error.response.title)
                // this.getList(0)
            }
        })
    }

    onSelect = (e) => {
        let file = e.files[0]
        this.setState({ file: file })
        console.log(file)
    }
    onUpload = () => {
        let formdata = new FormData();
        console.log('hit')
        formdata.append('file', this.state.file);
        this.httpService.getApiMultipart(`smart-bin/upload`, formdata).then((response) => {
            this.fileUpload.current.clear();
            if (response.data.filename != undefined) {
                this.download(response.data.filename)
            }
            this.showMessage('info', 'Message', response.data.message);
            this.getList(0)
        }).catch((error) => {
            if (checkForError(error)) {
                this.showMessage('error', 'Error Message', error.response.title)
                this.fileUpload.current.clear();
            }
        })
    }

    download = (fileUrl) => {
        this.setState({ downloadDialog: false })
        var a = document.createElement('a')
        a.href = Constant.SMART_BIN_URL + fileUrl;
        a.download = fileUrl;
        a.target = '_blank';
        a.click();
        a.remove();
    }

    componentDidMount() {

        this.getList(this.state.page - 1);
        if (localStorage.getItem("track&Trace") != undefined && localStorage.getItem("track&Trace") === "true")
            this.setState({ trackNTrace: true })
    }
    getList = (page) => {
        trackPromise(
            this.httpService.getApi('smart-bin/get-all?page=' + page + "&size=" + this.state.size + "&sort=createdDate,desc").then((response) => {
                let response1 = response.data.content;


                this.setState({ dataTableValue: response1, totalRecords: response.data.totalElements });

                console.log(response.data.content)
            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            }))
    };
    showMessage(severity, summary, message) {
        let msg = { severity: severity, summary: summary, detail: message };
        this.growl.show(msg);
    }
    onPageChange = (event) => {
        this.setState({
            page: event.page,
            first: event.first
        })
        if (this.state.isSearch) {
            this.customFilter(this.state.searchKey, this.state.searchColumn, event.page)
        } else {
            this.getList(event.page)
        }
    }
    handleChangeDate = (e) => {
        let val = moment.utc(new Date(e.target.value)).local().format('YYYY-MM-DD').toString();
        const field = e.target.name
        this.setState({ ...this.state, [field]: val })
    }

    ondeleteClick = (rowData) => {
        const id = rowData.id;
        this.setState({ dialogOpen: true, "id": id })
    }

    actionTemplate = (rowData, column) => {
        return <div>
            <Button type="button" tooltip="Delete" tooltipOptions={{ position: 'bottom' }} icon="pi-md-delete-forever" className="p-button-danger" onClick={() => this.ondeleteClick(rowData)} />
        </div>;
    }

    returnOutwardDate = (rowData) => {
        return moment(new Date(rowData.createdDate))
            .format("DD-MM-YY hh:mm a")
            .toString();
    };

    customFilter(searchValue, columnName, pageNo) {
        if (searchValue == "") {
            this.setState({ isSearch: false })
            this.getList(0);
        } else {
            this.setState({
                searchKey: searchValue,
                searchColumn: columnName,
                isSearch: true
            })
            this.httpService.searchApi(`smart-bin/search?columnName=${columnName}&searchKey=${searchValue}&page=${pageNo}&size=${this.state.size}`).then((response) => {
                this.setState({ dataTableValue: response.data.content, totalRecords: response.data.totalElements, page: 1 });
            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            })
        }

    }

    renderSearchElement(columnName) {
        return (
            <React.Fragment>
                <InputText onChange={(e) => this.customFilter(e.target.value, columnName, 0)} placeholder="Contains" ></InputText>
            </React.Fragment>
        );
    }

    render() {
        let actionHeader = <Button type="button" icon="pi-md-settings" />;
        return <>

            <AppBreadcrumb breadcrumdItems={[{ label: 'Smart Bin' }]} />

            <div className="p-grid">
                <div className="p-col-12">
                    <Growl ref={(el) => this.growl = el} style={{ marginTop: '75px' }} />

                    <div className="card card-w-title datatable-demo">
                        <div className="p-col-12">
                            <font className="font21Weight500" >Smart Bin</font>
                            <div className='floatRight'>
                                <Button className="floatRight" icon="pi pi-md-file-download"
                                    tooltip="Download" label="Download" tooltipOptions={{ position: 'left' }} onClick={(e) => this.setState({ downloadDialog: true })} />

                                <Dialog header="Download" visible={this.state.downloadDialog} footer={this.renderFooter('passwordModal')} style={{ width: '30vw' }} onHide={() => this.onHide()}>
                                    <Grid item container xs={12} justifyContent="space-between">
                                        <Grid container item xs={12} justifyContent="center">

                                            <div><div style={{ marginTop: '2rem', display: 'block' }}>
                                                <label className='marginRight20'>From</label>
                                                <Calendar
                                                    viewDate={new Date()}
                                                    name='fromDate'
                                                    value={this.state.fromDate}
                                                    onChange={this.handleChangeDate}
                                                // icon='faCalendarAlt'
                                                />
                                            </div>
                                                <div style={{ marginTop: '2rem' }}>
                                                    <label className='marginRight20'>To</label>
                                                    <Calendar
                                                        viewDate={new Date()}
                                                        name='toDate'
                                                        onChange={this.handleChangeDate}
                                                        value={this.state.toDate}
                                                    // icon='faCalendarAlt'
                                                    />
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Dialog>


                                <FileUpload
                                    name='Upload'
                                    customUpload
                                    uploadHandler={this.onUpload}
                                    mode='basic'
                                    accept='.xls, .xlsx'
                                    ref={this.fileUpload}
                                    onSelect={this.onSelect}
                                />
                            </div>

                        </div>
                        <div className="p-col-12">
                        </div>

                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} header="List of Smart Bin Qr Codes"
                            responsive={true} paginator={true} resizableColumns={true} columnResizeMode="expand" rows={10}
                            lazy onPage={(event) => this.onPageChange(event)} first={this.state.first} totalRecords={this.state.totalRecords}
                        // selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}
                        >
                            <Column
                                filterElement={this.renderSearchElement("qrCode")}
                                field="qrCode"
                                header="Qr Codes"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="Contains" />
                            <Column
                                filterElement={this.renderSearchElement("createdBy")}
                                field="createdBy"
                                header="Uploaded By"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="Contains" />

                            <Column
                                filterElement={this.renderSearchElement("createdDate")}
                                field={this.returnOutwardDate}
                                header="Uploaded Date"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="Contains" />

                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '15em' }} />

                        </DataTable>
                        : <></>


                        <Dialog header="Delete QrCodes" visible={this.state.dialogOpen} footer={this.renderFooter('passwordModal')} style={{ width: '30vw' }} onHide={() => this.onHide()}>
                            <Grid item container xs={12} justifyContent="space-between">
                                <Grid xs={5}>
                                    <DialogButton startIcon={<Delete color='inherit' />} style={{ textTransform: 'none', fontSize: 12, color: "red" }} onClick={this.delete} variant='contained' fullWidth>Delete Single</DialogButton>
                                </Grid>
                                <Grid xs={5}>
                                    <DialogButton onClick={e => this.setState({ viewDatePickers: true })} style={{ textTransform: 'none', fontSize: 12 }} variant='contained' fullWidth>Delete by date range</DialogButton>
                                </Grid>
                                <Grid container item xs={12} justifyContent="center">
                                    {
                                        this.state.viewDatePickers ?
                                            <div><div style={{ marginTop: '2rem', display: 'block' }}>
                                                <label className='marginRight20'>From</label>
                                                <Calendar
                                                    viewDate={new Date()}
                                                    name='fromDate'
                                                    value={this.state.fromDate}
                                                    onChange={this.handleChangeDate}
                                                    icon='faCalendarAlt' inputStyle={{ width: "100%" }} />
                                            </div>
                                                <div style={{ marginTop: '2rem' }}>
                                                    <label className='marginRight20'>To</label>
                                                    <Calendar
                                                        viewDate={new Date()}
                                                        name='toDate'
                                                        onChange={this.handleChangeDate}
                                                        value={this.state.toDate}
                                                        icon='faCalendarAlt' inputStyle={{ width: "100%" }} />
                                                </div></div>
                                            : <></>
                                    }
                                </Grid>
                            </Grid>
                        </Dialog>

                    </div>
                </div>
            </div></>
    }
}