import React, { Component } from 'react';

export class AppFooter extends Component {

    render() {
        return <div className="footer" 
        >
            <div className="card clearfix">
                <span className="footer-text-left">Fasiculus</span>
                <span className="footer-text-right"><span className="ui-icon ui-icon-copyright"></span>  <span>All Rights Reserved</span></span>
            </div>
        </div>
    }
}