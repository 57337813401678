import React, { Component } from 'react';
import { BreadCrumb } from 'primereact/breadcrumb';
import './style/appbreadcrum.css'
import PropTypes from 'prop-types';
var _ = require('lodash');

export class AppBreadcrumb extends Component {

    constructor() {
        super();
        this.state = {
           
            home: {
                icon: 'pi-md-home', url: '#dashboard'
            },
        }
    }

    static propTypes = {
        match: PropTypes.object
    }
   
    render() {
        // const { location } = this.props;
        // let pathName = location.pathname;
        // let breadcrumdItems=[]
        // if (_.includes(pathName, 'edit')) {
        //     let preName = _.replace(location.pathname, 'edit', 'list');
        //     let prebredcrumName = _.replace(preName, '_', ' ')
        //     prebredcrumName = _.replace(preName, '/', '')
        //     prebredcrumName=  _.replace(prebredcrumName, /\_/g, ' ')
        //     prebredcrumName=_.capitalize(prebredcrumName);
        //     breadcrumdItems.push({ label: prebredcrumName, url: "#"+preName })
        // }
        // if (_.includes(pathName, 'add')) {
           
        //     let preName = _.replace(location.pathname, 'add', 'list');
        //     if(pathName==="/template_add"){
        //         preName='customer_list'
        //     }
        //     let prebredcrumName = _.replace(preName, '_', ' ')
        //     prebredcrumName = _.replace(preName, '/', '')
        //     prebredcrumName=  _.replace(prebredcrumName, /\_/g, ' ')
        //     prebredcrumName=_.capitalize(prebredcrumName);
        //     breadcrumdItems.push({ label: prebredcrumName, url: "#"+preName })
        // }
        
        // let bredcrumName = location.pathname.replace(/\_/g, " ")
        // bredcrumName = _.replace(bredcrumName, '/', '')
        // bredcrumName= _.capitalize(bredcrumName)
        // breadcrumdItems.push({ label: bredcrumName })

        return (
            <div className="p-grid p-fluid">
            <div className="p-col-12">
                <div className="card card-w-title">
                    <BreadCrumb id="de" model={this.props.breadcrumdItems} home={this.state.home} />
                    
                </div>
                </div>
            </div>
        );
    }
}