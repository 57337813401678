import { Button } from 'primereact/button'
import { Growl } from 'primereact/growl';
import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from "react-hook-form";
import { withRouter } from 'react-router-dom'
import { Dropdown } from "primereact/dropdown";
import rule from "./../constant/Rule";
import { AppBreadcrumb } from '../AppBreadcrumb';
import { InputText } from 'primereact/inputtext';
import { HttpService } from '../service/HttpService';
import { trackPromise } from 'react-promise-tracker';
import { checkForError, showMessage } from "../utility/AppUtility";


function SearchQrCode(props) {
    const growl = useRef(null);
    const { handleSubmit, errors, control, reset } = useForm({ mode: "onBlur" });
    const [customerId, setCustomerId] = useState({});
    const [customerList, setCustomerList] = useState([]);
    const [type, setType] = useState("Customer");

    const cancel = () => {
        props.history.push("/search_qr_code");
    };
    const httpService = new HttpService();

    useEffect(() => {
        getCustomerList();
    }, []);

    const getCustomerList = () => {
        httpService.getApi(`customers/type?type=${type}`).then(response => {
            setCustomerList(response.data)
        })
    }

    const save = (data) => {
        trackPromise(
            httpService.getApi(`qrCode/search?qrCode=${customerId?.qrCode}&customerId=${customerId?.companyName.id}`).then(response => {
                props.history.push("/search_qr_code")
            })
                .catch((error) => {
                    if (checkForError(error))
                        showMessage(
                            "error",
                            "Error Message",
                            error.response.data.title,
                            growl
                        );
                })
        );
    };

    const handleChange = (e, type) => {
        console.log(e.target.value)
        let val = e.target.value;
        setCustomerId({ ...customerId, [e.target.name]: val });
        return val;
    };

    return (
        <>
            <AppBreadcrumb
                breadcrumdItems={[
                    { label: "QrCode Search List", url: "#/search_qr_code" },
                    { label: "QrCode Search" },
                ]}
            />
            <div className="p-grid ">
                <Growl ref={growl} style={{ marginTop: "75px" }} />

                <div className="p-col-10 ">
                    <div className="card card-w-title">
                        <h1>QrCode Search</h1>
                        <div className="p-grid  p-fluid form-group">
                            <div className="p-col-8">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="material-icons">scanner</i>
                                    </span>
                                    <span className="md-inputfield">
                                        <Controller
                                            className={errors.qrCode ? "p-error" : ""}
                                            as={InputText}
                                            control={control}
                                            rules={{
                                                ...rule.RULE.requiredRule,
                                                ...rule.RULE.maxLengthRuleVal100,
                                            }}
                                            name="qrCode"
                                            value={customerId.qrCode}
                                            onChange={(event) => handleChange(event[0])}
                                        />
                                        <label>QrCode</label>
                                    </span>
                                </div>
                                <span className="errorSpan merginLeft30">
                                    {errors.qrCode?.message}
                                </span>
                            </div>

                            <div className="p-col-8">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="material-icons">business</i>
                                    </span>
                                    <span className="md-inputfield">
                                        <Controller
                                            optionLabel="companyName"
                                            optionValue="id"
                                            className={errors.company1 ? "p-error" : ""}
                                            rules={{ ...rule.RULE.requiredRule }}
                                            as={Dropdown}
                                            control={control}
                                            options={customerList}
                                            placeholder={"Customer"}
                                            name="companyName"
                                            value={customerId.companyName}
                                            onChange={(e) => handleChange(e[0])}
                                        />
                                    </span>
                                </div>
                                <span className="errorSpan merginLeft30">
                                    {errors.companyName?.message}
                                </span>
                            </div>

                        </div>

                        <div className="p-col-12">
                            <Button
                                icon="pi pi-search"
                                label="Search"
                                onClick={handleSubmit(save)}
                            />
                            <Button
                                icon="pi-md-cancel"
                                label="Cancel"
                                className="p-button-secondary"
                                onClick={() => cancel()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default withRouter(SearchQrCode)