import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import { withRouter } from "react-router-dom";
import { HttpService } from "../../service/HttpService";
import { checkForError, clean, showMessage } from "../../utility/AppUtility";
import { ColorPicker } from "primereact/colorpicker";
import rule from "./../../constant/Rule";
import colors from "./../../constant/colors";
import { AppBreadcrumb } from "../../AppBreadcrumb";

var _ = require("lodash");

function TemplateAdd1(props) {
  const codeType = [
    { label: "Alphanumeric", value: "Alphanumeric" },
    { label: "Numeric", value: "Numeric" },
  ];

  var [obj, setObj] = useState({});
  var [customer, setCustomerList] = useState([]);
  var [products, setProducts] = useState([]);
  var [fontType, setFontType] = useState([]);
  var [disableFile, setDisableFile] = useState(false);
  var [disableFileMaster, setDisableFileMaster] = useState(false);
  var [color, setColor] = useState("");
  var [digitaArray, setDigitaArray] = useState([]);

  const digits = [
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
  ];
  const digits2 = [
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
  ];

  const growl = useRef(null);

  const { handleSubmit, errors, control, setValue } = useForm({
    mode: "onBlur",
  });
  const httpService = new HttpService();

  useEffect(() => {
    //  getProductList()
    trackPromise(
      httpService
        .getApi(
          "customers/company?page=" +
            0 +
            "&size=" +
            10000 +
            "&sort=createdDate,desc&type=Customer"
        )
        .then((response) => {
          setCustomerList(response.data.content);
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
    //getFontList()
  }, []);

  const getProductList = (id) => {
    trackPromise(
      httpService
        .getApi("products/" + id + "/customer")
        .then((response) => {
          console.log(response.data);
          setProducts(response.data);
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };

  const getFontList = () => {
    trackPromise(
      httpService
        .getApi("fontList")
        .then((response) => {
          setFontType(response.data);
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };

  const handleChange = (e) => {
    setObj({ ...obj, [e.target.name]: e.target.value });
    if (e.target.name == "customer") {
      getProductList(e.target.value.id);
      //  setObj({ ...obj, [e.target.name]: e.target.value });
    }
    if (e.target.name == "color") {
      if (e.target.value.name == undefined) {
        validateColor(e.target.value);
      } else {
        validateColor(e.target.value.name);
      }
    }

    if (e.target.name === "codeType") {
      setValue("noOfDigit", null, { shouldDirty: true });
      if (e.target.value === "Alphanumeric") {
        setDigitaArray(digits);
      } else {
        setDigitaArray(digits2);
      }
    }

    return e.target.value;
  };

  const onFileSelect = (e, name) => {
    setObj({ ...obj, [name]: e.files[0] });
    setValue(name, e.files[0], { shouldDirty: true });
    if (name == "file") {
      setDisableFile(true);
    } else {
      setDisableFileMaster(true);
    }
  };
  const onRemoveImg = (name) => {
    _.unset(obj, name);
    setValue(name, null);
    if (name == "file") {
      setDisableFile(false);
    } else {
      setDisableFileMaster(false);
    }
  };

  const cancel = () => {
    props.history.goBack();
  };

  const save = (data) => {
    obj = clean(obj);
    obj["customerId"] = obj.customer.id;
    obj["product.id"] = obj.product.id;
    let formData = new FormData();
    for (const property in obj) {
      if (obj[property] !== undefined) formData.append(property, obj[property]);
    }
    trackPromise(
      httpService
        .getApiMultipart("templates", formData)
        .then((response) => {
          selectOption(response.data.id);
        })
        .catch((error) => {
          setDisableFile(false);
          setDisableFileMaster(false);
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };
  const setColorByCode = (color1) => {
    if (color1 == "" || color1 == undefined) {
      return;
    }
    let c = window.w3color(color1);
    if (c.valid) {
      setObj({ ...obj, color: { name: c.toHexString() } });
      setColor(color1);
      setValue("color", c.toName());
    }
  };
  const validateColor = (color1) => {
    if (color1 == "" || color1 == undefined) {
      return;
    }
    color1 = color1?.toLowerCase();
    let c = window.w3color(color1);
    if (c.valid) {
      setColor(c.toHexString());
    }
  };

  const selectOption = (id1, row, column) => {
    props.history.push({
      pathname: "/design_template",
      state: { id: id1 },
    });
  };

  return (
    <>
      <AppBreadcrumb breadcrumdItems={[{ label: "Add Template" }]} />

      <div className="p-grid">
        <Growl ref={growl} style={{ marginTop: "75px" }} />

        <div className="p-col-10 ">
          <div className="card card-w-title">
            <h1>Add Template</h1>
            <div className="p-grid p-fluid form-group">
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">person</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      optionLabel="companyName"
                      key="companyCode"
                      optionValue="id"
                      filter
                      className={errors.customer ? "p-error" : ""}
                      rules={{ ...rule.RULE.requiredRule }}
                      as={Dropdown}
                      control={control}
                      options={customer}
                      placeholder={"Customer"}
                      name="customer"
                      value={obj.customer}
                      onChange={(e) => handleChange(e[0])}
                    />
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.customer?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">shop</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      optionLabel="productCode"
                      optionValue="id"
                      filter
                      filterBy="productCode"
                      className={errors.product ? "p-error" : ""}
                      rules={{ ...rule.RULE.requiredRule }}
                      as={Dropdown}
                      control={control}
                      options={products}
                      placeholder={"Product"}
                      name="product"
                      value={obj.product}
                      onChange={(e) => handleChange(e[0])}
                    />
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.product?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">description</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.column ? "p-error" : ""}
                      name="column"
                      type="number"
                      value={obj.column}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRule7,
                        ...rule.RULE.minRuleVal1,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>No. of Ups</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.column?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">description</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.row ? "p-error" : ""}
                      name="row"
                      type="number"
                      value={obj.row}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRule7,
                        ...rule.RULE.minRuleVal1,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Repeat</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.row?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">description</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.xspace ? "p-error" : ""}
                      name="xspace"
                      type="number"
                      value={obj.xspace}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRule7,
                        ...rule.RULE.minRuleVal1,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>X space of 1st label in template</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.xspace?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">description</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.yspace ? "p-error" : ""}
                      name="yspace"
                      type="number"
                      value={obj.yspace}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRule7,
                        ...rule.RULE.minRuleVal1,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Y space of 1st label in template</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.yspace?.message}
                </span>
              </div>
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">description</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.innerFileWidth ? "p-error" : ""}
                      name="innerFileWidth"
                      type="number"
                      value={obj.innerFilewidth}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRule7,
                        ...rule.RULE.minRuleVal1,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Label Width</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.innerFileWidth?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">description</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.innerFileHeight ? "p-error" : ""}
                      name="innerFileHeight"
                      type="number"
                      value={obj.innerFileHeight}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRule7,
                        ...rule.RULE.minRuleVal1,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Label Height</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.innerFileHeight?.message}
                </span>
              </div>
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">text_format</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.codeType ? "p-error" : ""}
                      rules={{ ...rule.RULE.requiredRule }}
                      as={Dropdown}
                      control={control}
                      options={codeType}
                      placeholder={"Code Type"}
                      name="codeType"
                      value={obj.codeType}
                      onChange={(e) => handleChange(e[0])}
                    />
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.codeType?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">description</i>
                  </span>
                  <span className="md-inputfield">
                    {/* <Controller className={errors.noOfDigit ? "p-error" : ''} name="noOfDigit" type="number" value={obj.noOfDigit} rules={{
                                    ...rule.RULE.maxLengthRule7,
                                    ...rule.RULE.minRuleVal1
                                }} as={InputText} control={control} onChange={(e) => handleChange(e[0])} />
                                <label >No. Of Digit</label> */}
                    <Controller
                      className={errors.noOfDigit ? "p-error" : ""}
                      rules={{ ...rule.RULE.requiredRule }}
                      as={Dropdown}
                      control={control}
                      options={digitaArray}
                      placeholder={"No of Digit"}
                      name="noOfDigit"
                      value={obj.noOfDigit}
                      onChange={(e) => handleChange(e[0])}
                    />
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.noOfDigit?.message}
                </span>
              </div>

              {/* <div className="p-col-8">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="material-icons">font_download</i></span>
                            <span className="md-inputfield">
                                <Controller filter className={errors.font ? "p-error" : ''} rules={{}} as={Dropdown} control={control} options={fontType} placeholder={"Font"} name="font" value={obj.font} onChange={(e) => handleChange(e[0])} />

                            </span>
                        </div>
                        <span className="errorSpan merginLeft30">{errors.font?.message}</span>
                    </div>
                    <div className="p-col-8">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="material-icons">format_size</i></span>
                            <span className="md-inputfield">
                                <Controller className={errors.fontSize ? "p-error" : ''} name="fontSize" type="number" value={obj.fontSize} rules={{
                                    ...rule.RULE.maxLengthRule7,
                                    ...rule.RULE.minRuleVal1
                                }} as={InputText} control={control} onChange={(e) => handleChange(e[0])} />
                                <label >Font Size</label>
                            </span>
                        </div>
                        <span className="errorSpan merginLeft30">{errors.fontSize?.message}</span>
                    </div> */}
              {/* <div className="p-col-8" style={{ display: "flex" }}>
                        <div className="p-col-9">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">format_size</i></span>
                                <span className="md-inputfield">
                              
                                    <Controller optionLabel={"name"}  filter filterBy="name" optionValue={"name"} editable  options={colors} className={errors.column ? "p-error" : ''} placeholder="Color" name="color"  value={obj.color} rules={{
                                     
                                    }} as={Dropdown} control={control}  onChange={(e) => handleChange(e[0])} />

                                  


                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">{errors.color?.message}</span>
                        </div>
                        <div className="p-col-3">
                            <ColorPicker format="hex" name="color" value={color} onChange={(e) => setColorByCode(e.value)} />

                        </div>
                    </div> */}
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">description</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.description ? "p-error" : ""}
                      name="description"
                      value={obj.description}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRuleVal250,
                        ...rule.RULE.minRuleVal1,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Template Code</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.description?.message}
                </span>
              </div>
              <div className="p-col-8">
                <label>Single Label file</label>
                <div className="p-inputgroup">
                  <Controller
                    style={{ width: "400px !important" }}
                    className={errors.file ? "p-error" : ""}
                    disabled={disableFile}
                    rules={{ ...rule.RULE.requiredRule }}
                    as={FileUpload}
                    control={control}
                    name="file"
                    onSelect={(e) => onFileSelect(e, "file")}
                    accept="pdf"
                    onRemove={() => onRemoveImg("file")}
                    maxFileSize={200000000}
                    customUpload={true}
                  />
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.file?.message}
                </span>
              </div>

              <div className="p-col-8">
                <label>Master file</label>
                <div className="p-inputgroup">
                  <Controller
                    style={{ width: "400px !important" }}
                    className={errors.file ? "p-error" : ""}
                    disabled={disableFileMaster}
                    rules={{ ...rule.RULE.requiredRule }}
                    as={FileUpload}
                    control={control}
                    name="masterfile"
                    onSelect={(e) => onFileSelect(e, "masterfile")}
                    accept="pdf"
                    onRemove={() => onRemoveImg("masterfile")}
                    maxFileSize={200000000}
                    customUpload={true}
                  />
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.file?.message}
                </span>
              </div>
            </div>
            <div className="p-col-12">
              <Button
                icon="pi pi-check"
                label="Save"
                onClick={handleSubmit(save)}
              />
              <Button
                icon="pi-md-cancel"
                label="Cancel"
                className="p-button-secondary"
                onClick={() => cancel()}
              />
            </div>
            <div className="p-col-8"></div>
          </div>
        </div>
        <div className="p-col-4"></div>
      </div>
    </>
  );
}
export default withRouter(TemplateAdd1);
