import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Growl } from "primereact/growl";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import { withRouter } from "react-router-dom";
import { HttpService } from "../../service/HttpService";
import { checkForError, clean, showMessage } from "../../utility/AppUtility";
import rule from "./../../constant/Rule";
import { MultiSelect } from "primereact/multiselect";
import "./../../style/multiselect.scss";
import { PriviladgeContext } from "./../../context/priviladge-context";
import { AppBreadcrumb } from "../../AppBreadcrumb";

var _ = require("lodash");

function UserEdit(props) {
  var [obj, setObj] = useState({});
  var [message, setMessage] = useState("");
  const growl = useRef(null);
  var [companies, setCompanies] = useState([]);
  var [customers, setCustomers] = useState([]);
  var [disableFile, setDisableFile] = useState(false);
  var [minDate] = useState(new Date());
  var [trackNTrace, setTrackNTrace] = useState(false);
  var [authority, setAuthority] = useState([]);
  var id = "";

  const priviladgeContext = useContext(PriviladgeContext);

  if (
    props === undefined ||
    props.location === undefined ||
    props.location.state === undefined ||
    props.location.state.id === undefined
  ) {
    props.history.push("user_list");
  } else {
    id = props.location.state.id;
  }
  const { handleSubmit, errors, control, reset } = useForm({ mode: "onBlur" });
  const httpService = new HttpService();

  useEffect(() => {
    if (
      localStorage.getItem("track&Trace") != undefined &&
      localStorage.getItem("track&Trace") === "true"
    ) {
      setTrackNTrace(true);
      // getCustomers(true);
      getAuthorities(true);
    } else {
      getCompanies();
      // getCustomers(false);
    }
  }, [id]);

  const getAuthorities = (trackNTrace) => {
    trackPromise(
      httpService
        .getApi("users/authorities")
        .then((response) => {
          let res = response.data.filter((x) => {
            if (
              x.displayName != "Admin" &&
              x.displayName != "Super Admin" &&
              x.displayName != "User"
            )
              return x;
          });
          setAuthority(res);
          getCompanies(res, trackNTrace);
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage("error", "Error Message", error.response.data.title);
        })
    );
  };

  const getCompanies = (tempAuthority, trackNTrace) => {
    httpService
      .getApi("companies")
      .then((response) => {
        setCompanies([...response.data]);
        reset({ companies: response.data });
        getUser(response.data, tempAuthority, trackNTrace);
      })
      .catch((error) => { });
  };
  const getUser = (companies, authorities, trackNTrace) => {
    trackPromise(
      httpService
        .getApi("users/userId/" + id)
        .then((response) => {
          //setObj({ ...response.data });
          let resp = response.data;
          if (trackNTrace) {
            //   httpService
            //     .getApi("customers/company?type=Customer")
            //     .then((response) => {
            //       setCustomers([...response.data]);

            //       let index = response.data.findIndex(
            //         (x) => x.id === resp.customers[0].id
            //       );
            //       if (index > -1) {
            //         resp.customers = response.data[index];
            //       }
            //       reset({ ...resp });
            //     })
            //     .catch((error) => {});
            let index = authorities.findIndex(
              (x) => x.name === resp.authorities[0]
            );
            if (index > -1) {
              resp.authority = authorities[index];
              //setObj({authority:resp.authority});
            }
          }
          let url = "customers/company?type=Customer";
          if (trackNTrace) {
            url = "customers/company?type=Business Partner";
          }
          httpService
            .getApi(url)
            .then((response) => {
              setCustomers([...response.data.content]);

              // let indexs = [];
              // for (const element of resp.customers) {
              //   let index = response.data.content.findIndex((x) => x.id === element.id);
              //   if (index > -1) {
              //     resp.customers = trackNTrace ? response.data.content[index] : [...resp.customers, response.data.content[index]];
              //   }
              // }
              // console.log(resp.customers) 
              let index = 0;
              if(trackNTrace){
               index = response.data.content.findIndex(
                (x) => x.id === resp.customers[0].id
              )
              if (index > -1) {
                console.log([response.data.content[index]])
                resp.customers = response.data.content[index];
              }
            } else{
              const list =[];
              resp.customers.forEach((x) => {
                index = response.data.content.findIndex(
                  (y) => y.id === x.id
                )
                  if (index > -1) {
                    console.log([response.data.content[index]])
                    list.push(response.data.content[index])
                  }
                } )
                resp.customers = list;
              }
             
              console.log(resp);
              reset({ ...resp });
            })
            .catch((error) => { });

          if (resp.accountExpiredDate != null)
            resp.accountExpiredDate = new Date(resp.accountExpiredDate);

          if (resp.company !== null) {
            let index = companies.findIndex((x) => x.id === resp.company.id);
            if (index > -1) {
              resp.company = companies[index];
            }
          }
          setObj({ ...resp });
          reset({ ...resp });
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };
  // const getCustomers = (track) => {
  //   let url = "customers/company?type=Customer";
  //   if (track) {
  //     url = "customers/company?type=Business Partner";
  //   }
  //   httpService
  //     .getApi(url)
  //     .then((response) => {
  //       setCustomers([...response.data]);
  //     })
  //     .catch((error) => {});
  // };

  const handleChange = (e, type) => {
    let val = e.target.value;
    // if (type === "date") {
    //   val = moment(new Date(e.target.value))
    //     .format("YYYY-MM-DDTHH:mm:ss")
    //     .toString();
    // }
    setObj({ ...obj, [e.target.name]: val });
    if (type === "date") {
      return e.target.value;
    } else return val;
  };
  const onFileSelect = (e) => {
    setObj({ ...obj, file: e.files[0] });
    setDisableFile(true);
  };
  const onRemoveImg = () => {
    _.unset(obj, "file");
    setDisableFile(false);
  };

  const cancel = () => {
    props.history.push("user_list");
  };

  const save = (data) => {
    let tempobj = clean(obj);
    tempobj = { ...tempobj };
    if (tempobj.accountExpiredDate != null) {
      tempobj.accountExpiredDate = moment(new Date(tempobj.accountExpiredDate))
        .format("YYYY-MM-DDTHH:mm:ss")
        .toString();
    }
    tempobj["company.id"] = tempobj.company.id;

    if (obj.customers != undefined && obj.customers != null) {
      if (obj.customers.length >= 1) {
        for (let index = 0; index < obj.customers.length; index++) {
          tempobj["userCustomers[" + index + "].id.customerId"] =
            obj.customers[index].id;
        }
      } else {
        tempobj["userCustomers[" + 0 + "].id.customerId"] = obj.customers.id;
      }
    }

    _.unset(tempobj, "company");
    _.unset(tempobj, "userCustomers");
    _.unset(tempobj, "customers");
    let formData = new FormData();
    for (const property in tempobj) {
      if (property === "files") {
        if (tempobj[property] !== undefined)
          tempobj[property].forEach((element) => {
            formData.append("file", element);
          });
      } else if (tempobj[property] !== undefined)
        formData.append(property, tempobj[property]);
    }
    if (trackNTrace) {
      let authorities = [];
      authorities.push(data.authority.name);
      tempobj["authorities"] = authorities;
      formData.append("authorities", authorities);
    }
    trackPromise(
      httpService
        .getApiMultipartPut("users", formData)
        .then((response) => {
          cancel();
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };

  return (
    <>
      <AppBreadcrumb
        breadcrumdItems={[
          { label: "User List", url: "#/user_list" },
          { label: "User Edit" },
        ]}
      />

      <div className="p-grid ">
        <Growl ref={growl} style={{ marginTop: "75px" }} />

        <div className="p-col-10">
          <div className="card card-w-title">
            <h1>Edit User</h1>
            <div className="p-grid  p-fluid form-group">
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">account_circle</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.firstName ? "p-error" : ""}
                      as={InputText}
                      control={control}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRuleVal100,
                      }}
                      name="firstName"
                      value={obj.firstName}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>First name</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.firstName?.message}
                </span>
              </div>
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">account_circle</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.lastName ? "p-error" : ""}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRuleVal100,
                      }}
                      as={InputText}
                      control={control}
                      name="lastName"
                      value={obj.lastName}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Last name</label>{" "}
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.lastName?.message}
                </span>
              </div>
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">account_circle</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      disabled={true}
                      className={errors.login ? "p-error" : ""}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRuleVal100,
                      }}
                      as={InputText}
                      control={control}
                      name="login"
                      value={obj.login}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Username</label>{" "}
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.login?.message}
                </span>
              </div>
              {priviladgeContext.isSuperAdmin && (
                <div className="p-col-8">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="material-icons">business</i>
                    </span>
                    <span className="md-inputfield">
                      <Controller
                        optionLabel="name"
                        optionValue="id"
                        className={errors.company ? "p-error" : ""}
                        rules={{ ...rule.RULE.requiredRule }}
                        as={Dropdown}
                        control={control}
                        options={companies}
                        placeholder={"Company"}
                        name="company"
                        value={obj.company}
                        onChange={(e) => handleChange(e[0])}
                      />
                    </span>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.company?.message}
                  </span>
                </div>
              )}
              {trackNTrace && (
                <div className="p-col-8">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="material-icons">list</i>
                    </span>
                    <span className="md-inputfield">
                      <Controller
                        className={errors.authority ? "p-error" : ""}
                        optionLabel="displayName"
                        optionValue="name"
                        filter
                        rules={{
                          ...rule.RULE.requiredRule,
                          ...rule.RULE.maxLengthRuleVal25,
                        }}
                        as={Dropdown}
                        control={control}
                        options={authority}
                        placeholder={"Authority"}
                        name="authority"
                        value={obj.authority}
                        onChange={(e) => handleChange(e[0], "role")}
                      />
                    </span>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.authority?.message}
                  </span>
                </div>
              )}
              {obj.authority?.name !== "ROLE_PRODUCER" && (
                <div className="p-col-8">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="material-icons">business</i>
                    </span>
                    <span className="md-inputfield">
                      <Controller
                        optionLabel="companyName"
                        optionValue="id"
                        className={errors.customers ? "p-error" : ""}
                        rules={{ ...rule.RULE.requiredRule }}
                        as={trackNTrace ? Dropdown : MultiSelect}
                        control={control}
                        options={customers}
                        placeholder={"Customers"}
                        name="customers"
                        value={obj.customers}
                        onChange={(e) => handleChange(e[0])}
                      />
                    </span>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.customers?.message}
                  </span>
                </div>
              )}
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">phone</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.mobileNo ? "p-error" : ""}
                      type="number"
                      name="mobileNo"
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.minLengthRule10,
                        ...rule.RULE.maxLengthRule15,
                      }}
                      value={obj.mobileNo}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Mobile no.</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.mobileNo?.message}
                </span>
              </div>
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">email</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.email ? "p-error" : ""}
                      rules={{ ...rule.RULE.maxLengthRuleVal50 }}
                      name="email"
                      value={obj.email}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Email</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.email?.message}
                </span>
              </div>
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">home</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.address ? "p-error" : ""}
                      rules={{ ...rule.RULE.maxLengthRuleVal200 }}
                      name="address"
                      value={obj.address}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Address</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.email?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">insert_invitation</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      minDate={minDate}
                      className={errors.accountExpiredDate ? "p-error" : ""}
                      name="accountExpiredDate"
                      value={obj.accountExpiredDate}
                      rules={{ ...rule.RULE.minRuleVal1 }}
                      as={Calendar}
                      control={control}
                      onChange={(e) => handleChange(e[0], "date")}
                      monthNavigator={true}
                      yearNavigator={true}
                      yearRange="2010:2070"
                      dateFormat="yy-MM-dd"
                    />
                    <label>Account expiration date</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.accountExpiredDate?.message}
                </span>
              </div>
              <div className="p-col-8">
                <label>Upload profile image</label>
                <div className="p-col-8"></div>
                <div className="p-inputgroup">
                  <Controller
                    className={errors.file ? "p-error" : ""}
                    disabled={disableFile}
                    rules={{}}
                    as={FileUpload}
                    control={control}
                    name="file"
                    onSelect={(e) => onFileSelect(e)}
                    accept="image/*"
                    onRemove={() => onRemoveImg()}
                    maxFileSize={5000000}
                    customUpload={true}
                  />
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.file?.message}
                </span>
              </div>
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <InputSwitch
                    checked={obj.activated}
                    name={"activated"}
                    inputId={obj.firstName}
                    ariaLabelledBy={obj.firstName}
                    value={obj.activated}
                    onChange={(event) => handleChange(event)}
                  />
                  <label
                    htmlFor={obj.firstName}
                    className="p-checkbox-label merginLeft15"
                  >
                    Active
                  </label>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.activated?.message}
                </span>
              </div>
            </div>
            <div className="p-col-12">
              <Button
                icon="pi pi-check"
                label="Save"
                onClick={handleSubmit(save)}
              />
              <Button
                icon="pi-md-cancel"
                label="Cancel"
                className="p-button-secondary"
                onClick={() => cancel()}
              />
            </div>
            <div className="p-col-8">
              {message !== "" && <Message severity="error" text={message} />}
            </div>
          </div>
        </div>
        <div className="p-col-4"></div>
      </div>
    </>
  );
}
export default withRouter(UserEdit);
