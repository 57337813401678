import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean, showMessage } from '../../utility/AppUtility';
import rule from './../../constant/Rule';
import { PriviladgeContext } from './../../context/priviladge-context'
import CheckPriviladge from './../../hoc/CheckPriviladge'
import { Dropdown } from 'primereact/dropdown';
import { AppBreadcrumb } from '../../AppBreadcrumb';


function MatnrsEdit(props) {

    var [obj, setObj] = useState({})
    const growl = useRef(null)
    const { handleSubmit, errors, control, reset } = useForm({ mode: "onBlur" });
    const httpService = new HttpService();

    var id = '';
    if (props === undefined || props.location === undefined || props.location.state === undefined || props.location.state.id === undefined) {
        props.history.push('master_product_list')
    } else {
        id = props.location.state.id;
    }

    const zPrices = [
        { label: '10', value: '10' },
        { label: '15', value: '15' },
        { label: '20', value: '20' },
        { label: '25', value: '25' },
        { label: '50', value: '50' },
        { label: '75', value: '75' },
        { label: '100', value: '100' },
        { label: '150', value: '150' },
        { label: '200', value: '200' }
    ]
    const zpacks = [
        { label: '1', value: '1' },
        { label: '2', value: '2' },
        { label: '3', value: '3' },
        { label: '4', value: '4' },
        { label: '5', value: '5' },
        { label: '6', value: '6' },
        { label: '7', value: '7' },
        { label: '8', value: '8' },
        { label: '9', value: '9' },
        { label: '10', value: '10' },
        { label: '11', value: '11' },
        { label: '12', value: '12' },
        { label: '13', value: '13' },
        { label: '14', value: '14' },
        { label: '15', value: '15' },
        { label: '16', value: '16' },
        { label: '17', value: '17' },
        { label: '18', value: '18' },
        { label: '19', value: '19' },
        { label: '20', value: '20' },
        { label: '21', value: '21' },
        { label: '22', value: '22' },
        { label: '23', value: '23' },
        { label: '24', value: '24' },
        { label: '25', value: '25' },
        { label: '26', value: '26' },
        { label: '27', value: '27' },
        { label: '28', value: '28' },
        { label: '29', value: '29' },
        { label: '30', value: '30' },
        { label: '31', value: '31' },
        { label: '32', value: '32' },
        { label: '33', value: '33' },
        { label: '34', value: '34' },
        { label: '35', value: '35' },
        { label: '36', value: '36' },
        { label: '37', value: '37' },
        { label: '38', value: '38' },
        { label: '39', value: '39' },
        { label: '40', value: '40' },
    ]
    const tokenTypes = [
        { label: 'Lucky', value: 'Lucky' },
        { label: 'Normal', value: 'Normal' }
    ]

    useEffect(() => {


        trackPromise(
            httpService.getApi('matnrs/' + id).then((response) => {
                setObj({ ...response.data })
                reset({ ...response.data })
            }).catch((error) => {
                if (checkForError(error))
                    showMessage('error', 'Error Message', error.response.data.title, growl)
            }));
    }, [id])

    const handleChange = (e, type) => {
        let val = e.target.value;

        setObj({ ...obj, [e.target.name]: val });
        return val;
    }


    const cancel = () => {
        props.history.push('master_product_list')
    }

    const save = data => {
        obj = clean(obj)

        trackPromise(
            httpService.putApi('matnrs', obj).then((response) => {

                cancel();

            }).catch((error) => {
                if (checkForError(error))
                    showMessage('error', 'Error Message', error.response.data.title, growl)
            }));

    }





    return <>
        <AppBreadcrumb breadcrumdItems={[{ label: 'Master Product List', url: "#master_product_list" }, { label: 'Master Product Edit' }]} />

        <div className="p-grid ">
            <Growl ref={growl} style={{ marginTop: '75px' }} />

            <div className="p-col-10 ">
                <div className="card card-w-title">
                    <h1>Edit Master Product</h1>
                    <div className="p-grid p-fluid form-group">

                        <div className="p-col-8">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">account_circle</i></span>
                                <span className="md-inputfield">
                                    <Controller className={errors.name ? "p-error" : ''} name="name" value={obj.name} rules={{ ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal200 }} as={InputText} control={control} onChange={(e) => handleChange(e[0])} />
                                    <label >SKU Code</label>
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">{errors.name?.message}</span>
                        </div>

                        <div className="p-col-8">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">account_circle</i></span>
                                <span className="md-inputfield">
                                    <Controller className={errors.color ? "p-error" : ''} name="color" value={obj.color} rules={{ ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal200 }} as={InputText} control={control} onChange={(e) => handleChange(e[0])} />
                                    <label >Color</label>
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">{errors.color?.message}</span>
                        </div>
                        <div className="p-col-8">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                <span className="md-inputfield">
                                    <Controller className={errors.tokenType ? "p-error" : ''} rules={{ ...rule.RULE.requiredRule }} name="tokenType" options={tokenTypes} value={obj.tokenType} placeholder="Token Type" as={Dropdown} control={control} onChange={(e) => handleChange(e[0])} />

                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">{errors.tokenType?.message}</span>
                        </div>
                        <div className="p-col-8">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                <span className="md-inputfield">
                                    <Controller className={errors.packSize ? "p-error" : ''} rules={{ ...rule.RULE.requiredRule }} name="packSize" options={zpacks} value={obj.packSize} placeholder="Pack Size" as={InputText} control={control} onChange={(e) => handleChange(e[0])} />

                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">{errors.packSize?.message}</span>
                        </div>

                        <div className="p-col-8">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                <span className="md-inputfield">
                                    <Controller className={errors.description ? "p-error" : ''} name="description" value={obj.description} rules={{}} as={InputText} control={control} onChange={(e) => handleChange(e[0])} />
                                    <label >Description</label>
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">{errors.description?.message}</span>
                        </div>

                    </div>
                    <div className="p-col-12">
                        <Button icon="pi pi-check" label="Save" onClick={handleSubmit(save)} />
                        <Button icon="pi-md-cancel" label="Cancel" className="p-button-secondary" onClick={() => cancel()} />

                    </div>
                    <div className="p-col-8">

                    </div>

                </div>
            </div>

        </div></>

}
export default withRouter(MatnrsEdit);
