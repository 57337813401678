import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { HttpService } from '../../service/HttpService';
import { checkForError, showMessage } from '../../utility/AppUtility';
import { AppBreadcrumb } from '../../AppBreadcrumb';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { ThreeDRotationSharp, ThreeSixty } from '@material-ui/icons';
export class UserList extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue: [],
            page: 1,
            totalRecords: 0,
            size: 10,
            trackNTrace: false,
            passwordModal: false,
            first: 0,
            isSearch: false,
            searchKey: "",
            searchColumn: ""
        };

        this.httpService = new HttpService();
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    onClick(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }
        this.setState(state);
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false
        });
    }
    renderFooter(name) {
        return (
            <div>
                <Button label="Cancel" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-text" />
                <Button label="Save" icon="pi pi-check" onClick={() => this.savePassword(name)} />
            </div>
        );
    }

    componentDidMount() {

        this.getList(this.state.page - 1);
        if (localStorage.getItem("track&Trace") != undefined && localStorage.getItem("track&Trace") === "true")
            this.setState({ trackNTrace: true })
    }
    getList = (page) => {
        trackPromise(
            this.httpService.getApi('users?page=' + page + "&size=" + this.state.size + "&sort=createdDate,desc").then((response) => {
                this.setState({ dataTableValue: response.data.content, totalRecords: response.data.totalElements });
            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            }))
    }
    showMessage(severity, summary, message) {
        let msg = { severity: severity, summary: summary, detail: message };
        this.growl.show(msg);
    }
    onPageChange = (event) => {
        this.setState({
            page: event.page,
            first: event.first
        })
        if (this.state.isSearch) {
            this.customFilter(this.state.searchKey, this.state.searchColumn, event.page)
        } else {
            this.getList(event.page)
        }

    }
    add = () => {
        this.props.history.push('user_add')
    }
    edit = (rowData) => {
        this.props.history.push({
            pathname: '/user_edit',
            state: { id: rowData.id }
        })
    }
    addAuthority = (rowData) => {
        this.httpService
            .getApi("user-priviladges")
            .then((response) => {
                this.props.history.push({
                    pathname: '/user_privladge',
                    state: { id: rowData.id, username: rowData.login, userPriviladges: response.data }
                })
            })
            .catch((error) => { });
    }

    showPasswordModal = (rowData) => {
        this.onClick('passwordModal');
        this.setState({
            user: rowData,
            newPassword: "",
            confirmPassword: "",
            email: rowData.login
        });
    }

    savePassword = (name) => {
        let obj = {
            id: this.state.user.id,
            newPassword: this.state.newPassword,
            confirmPassword: this.state.confirmPassword
        };
        let inValid = false, message = '';
        if (obj.newPassword == '') {
            message = "Password can not be empty";
            inValid = true;
        }
        else if (obj.confirmPassword == '') {
            message = "Confirm Password can not be empty";
            inValid = true;
        }
        else if (obj.confirmPassword !== obj.newPassword) {
            message = "Passord and Confirm Password does not match";
            inValid = true;
        }
        if (inValid) {
            this.showMessage(
                "error",
                "Error Message",
                message,
                this.growl
            );
            return false;
        }
        this.httpService
            .getApiMultipart("change-password", obj)
            .then((response) => {
                this.onHide(name);
            })
            .catch((error) => {
                if (checkForError(error))
                    this.showMessage(
                        "error",
                        "Error Message",
                        error.response.data.title,
                        this.growl
                    );
            })
    }

    delete = (rowData) => {
        trackPromise(
            this.httpService.deleteApi('users/' + rowData.id).then((response) => {
                this.getList(0);
            }).catch((error) => {
                if (checkForError(error)) {

                    this.showMessage('error', 'Error Message', error.response.data.title)

                }
            }));
    }
    actionTemplate = (rowData, column) => {
        return <div>
            <Button type="button" tooltip="Edit" tooltipOptions={{ position: 'bottom' }} icon="pi-md-pencil" className="p-button-warning" onClick={() => this.edit(rowData)} />
            {/* {!this.state.trackNTrace && */}
            <Button type="button" tooltip="Add Priviladge" tooltipOptions={{ position: 'bottom' }} icon="pi-md-security" className="p-button-info" onClick={() => this.addAuthority(rowData)} />
            <Button type="button" tooltip="Change password" tooltipOptions={{ position: 'bottom' }} icon="pi-md-work" className="p-button-info" onClick={() => this.showPasswordModal(rowData)} />
            {/* } */}
            <Button type="button" tooltip="Delete" tooltipOptions={{ position: 'bottom' }} icon="pi-md-delete-forever" className="p-button-danger" onClick={() => this.delete(rowData)} />
        </div>;
    }

    customFilter(searchValue, columnName, pageNo) {
        if (searchValue == "") {
            this.setState({ isSearch: false })
            this.getList(0);
        } else {
            this.setState({
                searchKey: searchValue,
                searchColumn: columnName,
                isSearch: true
            })
            this.httpService.searchApi(`user/search?columnName=${columnName}&searchKey=${searchValue}&page=${pageNo}&size=${this.state.size}`).then((response) => {
                this.setState({ dataTableValue: response.data.content, totalRecords: response.data.totalElements, page: 1 });
            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            })
        }

    }

    renderSearchElement(columnName) {
        return (
            <React.Fragment>
                <InputText onChange={(e) => this.customFilter(e.target.value, columnName, 0)} placeholder="Contains" ></InputText>
            </React.Fragment>
        );
    }

    render() {
        let actionHeader = <Button type="button" icon="pi-md-settings" />;

        return <>

            <AppBreadcrumb breadcrumdItems={[{ label: 'User List' }]} />

            <div className="p-grid">
                <div className="p-col-12">
                    <Growl ref={(el) => this.growl = el} style={{ marginTop: '75px' }} />

                    <div className="card card-w-title datatable-demo">
                        <div className="p-col-12">
                            <font className="font21Weight500" >Users</font>

                            <Button className="floatRight" icon="pi-md-plus"
                                tooltip="Add user" label="Add" tooltipOptions={{ position: 'left' }} onClick={() => this.add()} />
                        </div>
                        <div className="p-col-12">
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue} header="List of user"
                            responsive={true} paginator={true} resizableColumns={true} columnResizeMode="fit" rows={10}
                            lazy onPage={(event) => this.onPageChange(event)} first={this.state.first} totalRecords={this.state.totalRecords}
                        // selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({dataTableSelection1: event.value})}
                        >

                            <Column
                                filterElement={this.renderSearchElement("firstName")}
                                field="firstName"
                                header="First Name"
                                sortable={true}
                                filter={true}
                                filterPlaceholder="Contains" />
                            <Column
                                filterElement={this.renderSearchElement("lastName")}
                                field="lastName" header="Last Name" sortable={true} filter={true} filterPlaceholder="Contains" />
                            <Column
                                filterElement={this.renderSearchElement("mobileNo")}
                                field="mobileNo" header="Mobile no." sortable={true} filter={true} filterPlaceholder="Contains" />
                            <Column
                                filterElement={this.renderSearchElement("login")}
                                field="login" header="username" sortable={true} filter={true} filterPlaceholder="Contains" />
                            <Column
                                filterElement={this.renderSearchElement("email")}
                                field="email" header="Email" sortable={true} filter={true} filterPlaceholder="Contains" />

                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '15em' }} />


                        </DataTable>
                        {/* <Paginator   first={this.state.page} rows={this.state.size}  totalRecords={this.state.totalRecords} onPageChange={(e) => this.onPageChange(e)}></Paginator> */}

                        <Dialog header="Change Password" visible={this.state.passwordModal} style={{ width: '30vw' }} footer={this.renderFooter('passwordModal')} onHide={() => this.onHide('passwordModal')}>
                            <div className="p-grid  p-fluid form-group">
                                <div class="p-col-12">
                                    <span className="p-float-label">
                                        <InputText className='disabled' readOnly="true" required="true" autoComplete='off' id="in" type="email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                        <label htmlFor="in">Email</label>
                                    </span>
                                </div>
                                <div class="p-col-12">
                                    <span className="p-float-label">
                                        <InputText required="true" autoComplete='off' id="in" type="password" value={this.state.newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })} />
                                        <label htmlFor="in">New Password</label>
                                    </span>
                                </div>
                                <div class="p-col-12">
                                    <span className="p-float-label">
                                        <InputText required="true" autoComplete='off' id="in2" type='password' value={this.state.confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value })} />
                                        <label htmlFor="in2">Confirm Password</label>
                                    </span>
                                </div>
                            </div>
                        </Dialog>

                    </div>
                </div>
            </div></>
    }
}