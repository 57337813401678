
import { Button } from 'primereact/button';
import React, { useEffect, useState, useRef } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import constant from './../../constant/Constant';
import { InputSwitch } from 'primereact/inputswitch';
import { ColorPicker } from 'primereact/colorpicker';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Controller, useForm } from 'react-hook-form';
import './../../style/mystyle.css';
import colors from './../../constant/colors';
import format from './../../constant/format';
import { TabView, TabPanel } from 'primereact/tabview';
import rule from './../../constant/Rule';
import { AppBreadcrumb } from '../../AppBreadcrumb';


function DesignSetting(props) {



    const httpService = new HttpService();
    var [color, setColor] = useState("")
    const { handleSubmit, errors, control, setValue } = useForm({ mode: "onBlur" });

    const growl = useRef(null)


    var id = ""


    if (props === undefined || props === null || props.location === undefined
        || props.location === null || props.location.state === undefined
        || props.location.state === null
        || props.location.state.id === undefined || props.location.state.id === null) {
        props.history.push('customer_list')
    } else {
        id = props.location.state.id;

    }

    const validateColor = (color1) => {
        if (color1 == "" || color1 == undefined) {
            return;
        }
        color1 = color1?.toLowerCase();
        let c = window.w3color(color1);
        if (c.valid) {
            setColor(c.toHexString())
        }
    }

    const goToDesign = (type) => {
        props.history.push({
            pathname: '/design_template',
            state: { id: id, type: type }
        })
    }



    const save = () => {
        trackPromise(
            httpService.postApi('updateTemplateStatus', { id: id }).then((response) => {
                back()
            }).catch((error) => {

            }));
    }
    const back = () => {
        props.history.push('template_histories')
    }

    return (<><div className="p-grid">


        <div className="p-col-12 displayFlex">
            <h4>Setting</h4>
            <br></br>
        </div>
        <div className="p-col-12">
            <TabView>
                <TabPanel header="General" headerClassName={"width31"} leftIcon="pi-md-settings">
                    <div className="p-col-12 displayFlex">
                        <div className="p-col-5">
                            <label>Width</label>
                            <InputText id="QRcodeInputSX"  name={"width"} value={props.width} type="number" placeholder="Width" onInput={(e) =>{ window.myscale();props.handleChange(e)}} />
                        </div>
                        <div className="p-col-2"></div>
                        <div className="p-col-5">
                            <label>Height</label>
                            <InputText id="QRcodeInputSY" type="number"  name={"height"} value={props.height}  placeholder="Height" onInput={(e) => {window.myscale(); props.handleChange(e)}} />
                        </div>
                    </div>
                    {(props.type === constant.TEXT || props.type === constant.TEXT2
                         || props.type === constant.TEXT3 ) && <>
                        <div className="p-col-12  displayFlex">
                            <div className="p-col-5 ">
                                <label>Text</label>
                            </div>
                            <div className="p-col-2">
                            </div>
                            <div className="p-col-5">
                                <InputText placeholder="Text" name="text" value={props.text}  onChange={(e) => props.handleChange(e)} />
                            </div>
                         </div>
                       </>
                    }

                    <div className="p-col-12 displayFlex "   >
                        <div className="p-col-5" >
                            <label>Rotation</label>
                            <InputText type="number" placeholder="Rotation" name="rotation" value={props.rotation}  onChange={(e) => { window.rotate(e); props.handleChange(e); }} />
                        </div>
                        <div className="p-col-2"></div>
                        <div className="p-col-5" >
                            <img className="arrow" id="arrow" src="assets/layout/images/arrow.png" alt="arrow" />
                        </div>
                    </div>
                    <div className="p-col-12 displayFlex ">
                        <div className="p-col-7">
                            <label>Show Grid</label>
                        </div>
                        <div className="p-col-5">
                            <InputSwitch checked={props.showGrid} inputId={"grid"}  value={props.showGrid} onChange={(e) => props.showHideGrid(e)} />
                        </div>
                    </div>
                    {/* <div className="p-col-12 displayFlex ">
                        <div className="p-col-7">
                            <label>Absolute Position</label>
                        </div>
                        <div className="p-col-5">
                            <InputSwitch checked={props.exact} inputId={"exact"} value={props.exact} onChange={(e) => props.setExact1(e.value)} />
                        </div>
                    </div> */}
                    <div className="p-col-12  displayFlex">
                        <div className="p-col-4">
                            <label>Format</label>
                        </div>
                    </div>
                    <div className="p-col-12 displayFlex">
                        <div className="p-col-12">
                            <Dropdown style={{ width: "100%" }} filter className={errors.format ? "p-error" : ''} rules={{}} as={Dropdown} control={control} options={format} placeholder={"Format"} name="format" value={props.format} onChange={(e) => props.handleFormat(e)} />
                            <span className="errorSpan merginLeft30">{errors.format?.message}</span>

                        </div>
                    </div>

                    <div className="p-col-12  displayFlex">
                        <div className="p-col-4">
                            <label>Format Color</label>
                        </div>
                    </div>

                    <div className="p-col-12 displayFlex">
                        <div >
                            <Dropdown placeholder="Farmat Color" style={{ width: "100%" }} optionLabel={"name"} filter filterBy="name" optionValue={"name"} editable options={colors} className={errors.column ? "p-error" : ''} placeholder="formatColor" name="formatColor" value={props.formatColor} rules={{}} as={Dropdown} control={control} onChange={(e) => props.setColors(e)} />
                            <span className="errorSpan merginLeft30">{errors.formatColor?.message}</span>
                        </div>
                        <div className="p-col-3">
                            <ColorPicker format="hex" name="formatColor" value={props.formatColor} onChange={(e) => props.setColors(e)} />
                        </div>
                    </div>

                   
                    <div className="p-col-12 displayFlex "></div>
                </TabPanel>
                <TabPanel header="Font" headerClassName={"width31"} leftIcon="pi-md-format-color-text">
                    <div className="p-col-12 displayFlex ">
                        <label>Font Name</label>
                    </div>
                    <div className="p-col-12 displayFlex">
                        <Dropdown placeholder="Font Name" style={{ width: "100%" }} filter className={errors.fontName ? "p-error" : ''} rules={{}} as={Dropdown} control={control} options={props.fontType} name="fontName" value={props.fontName} onChange={(e) => props.handleChange(e)} />
                        <span className="errorSpan merginLeft30">{errors.fontName?.message}</span>
                    </div>
                    <div className="p-col-12 displayFlex ">
                        <label>Font Size</label>
                    </div>
                    <div className="p-col-12 displayFlex">
                        <InputText placeholder="Font Size" className={errors.fontSize ? "p-error" : ''} name="fontSize" type="number" value={props.fontSize}  rules={{
                            ...rule.RULE.maxLengthRule7,
                            ...rule.RULE.minRuleVal1
                        }} as={InputText} control={control} onChange={(e) => props.handleChange(e)} />
                        <span className="errorSpan merginLeft30">{errors.fontSize?.message}</span>
                    </div>
                    <div className="p-col-12 displayFlex ">
                        <label>Font Color</label>
                    </div>

                    <div className="p-col-12 displayFlex">
                        <div >
                            <Dropdown placeholder="Font Color" style={{ width: "100%" }} optionLabel={"name"} filter filterBy="name" optionValue={"name"} editable options={colors} className={errors.column ? "p-error" : ''} placeholder="Color" name="color" value={props.color} rules={{}} as={Dropdown} control={control} onChange={(e) => props.setColors(e)} />
                            <span className="errorSpan merginLeft30">{errors.color?.message}</span>
                        </div>
                        <div className="p-col-3">
                            <ColorPicker format="hex" name="color" value={props.color} onChange={(e) => props.setColors(e)} />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Template" headerClassName={"width31"} leftIcon="pi-md-settings">
                    <div className="p-col-12 displayFlex">
                        <div className="p-col-5">
                            <label>Template Width</label>
                            <InputText id="QRcodeInputSX"  name={"innerFileWidth"} value={props.innerFileWidth} type="number" placeholder="Template Width" onInput={(e) =>{ props.handleChange(e)}} />
                        </div>
                        <div className="p-col-2"></div>
                        <div className="p-col-5">
                            <label>Template Height</label>
                            <InputText id="QRcodeInputSY" type="number"  name={"innerFileHeight"} value={props.innerFileHeight}  placeholder="Template Height" onInput={(e) => { props.handleChange(e)}} />
                        </div>
                    </div>
                    <div className="p-col-12 displayFlex">
                        <div className="p-col-5">
                            <label>X space</label>
                            <InputText id="QRcodeInputSX"  name={"xspace"} value={props.xspace} type="number" placeholder="X space" onInput={(e) =>{ props.handleChange(e)}} />
                        </div>
                        <div className="p-col-2"></div>
                        <div className="p-col-5">
                            <label>Y space</label>
                            <InputText id="QRcodeInputSY" type="number"  name={"yspace"} value={props.yspace}  placeholder="Y space" onInput={(e) => {props.handleChange(e)}} />
                        </div>
                    </div>
                   
                  
                    <div className="p-col-12 displayFlex "></div>
                </TabPanel>

            </TabView>
        </div>

    </div ></>)

}
export default withRouter(DesignSetting);
