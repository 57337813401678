import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean,showMessage } from '../../utility/AppUtility';
import rule from './../../constant/Rule';
import ContectPersonAdd from './ContectPersonAdd';
import { AppBreadcrumb } from '../../AppBreadcrumb';

function VendorAdd(props) {

    var [obj, setObj] = useState({})
    var [contectPersons, setContectPersons] = useState([])
    var [activeIndex, setActiveIndex] = useState(0)

    const growl = useRef(null)
    const { handleSubmit, errors, control } = useForm({ mode: "onBlur" });
    const httpService = new HttpService();
    
    

    const handleChange = (e, type) => {
        let val = e.target.value;

        setObj({ ...obj, [e.target.name]: val });
        return val;
    }

    const cancel = () => {
        props.history.push('vendor_list')
    }
    
    var saveContectPersons=data=>{
        setContectPersons(data)
        setActiveIndex(0)
        
    }
    const save = data => {
        obj = clean(obj)
        obj.contectPersons = contectPersons;
        trackPromise(
            httpService.postApi('vendors', obj).then((response) => {
                cancel();
            }).catch((error) => {
                if (checkForError(error)) {
                   showMessage('error', 'Error Message', error.response.data.title,growl)
                }
            }));

    }




    return <>
        <AppBreadcrumb breadcrumdItems={[{ label: 'Vendor List',url:"#vendor_list" },{ label: 'Vendor Add' }]} />

    
    <div className="p-grid ">
        <Growl ref={growl} style={{ marginTop: '75px' }} />

        <div className="p-col-10 ">
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Vendor">


                    <div className="card card-w-title">

                        <h1>Add Vendor</h1>
                        <div className="p-grid p-fluid form-group">

                            <div className="p-col-8">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">account_circle</i></span>
                                    <span className="md-inputfield">
                                        <InputText className={errors.name ? "p-error" : ''} name="name" value={obj.name} rules={{ ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal100 }} as={InputText} control={control} onChange={(e) => handleChange(e)} />
                                        <label >Name</label>
                                    </span>
                                 </div>
                                <span className="errorSpan merginLeft30">{errors.name?.message}</span>
                            </div>
                            <div className="p-col-8">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">account_circle</i></span>
                                    <span className="md-inputfield">
                                        <InputText className={errors.address ? "p-error" : ''} name="address" value={obj.address} rules={{ ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal250 }} as={InputText} control={control} onChange={(e) => handleChange(e)} />
                                        <label >Address</label>
                                    </span>
                                </div>
                                <span className="errorSpan merginLeft30">{errors.address?.message}</span>
                            </div>

                        </div>
                        <div className="p-col-12">
                            <Button icon="pi pi-check" label="Save" onClick={save} />
                            {/* <Button icon="pi-md-clear" label="Clear" className="p-button-secondary" onClick={() => clear()} /> */}
                            <Button icon="pi-md-cancel" label="Cancel" className="p-button-secondary" onClick={() => cancel()} />

                        </div>
                        <div className="p-col-8">
                        </div>

                    </div>
                </TabPanel>
                <TabPanel header="Contact Person">
                < ContectPersonAdd setContectPersons={saveContectPersons} contectPersons={contectPersons} ></ContectPersonAdd>

                </TabPanel>
            </TabView>

        </div>
        <div className="p-col-4"></div>
    </div></>

}
export default withRouter(VendorAdd);
