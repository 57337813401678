import React, { PureComponent } from 'react'
import { HttpService } from '../service/HttpService';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/components/button/Button';
import { Marquee } from './Marquee';
import { Chart } from 'primereact/chart';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import './AnalyticalDashboard.css';
import WeekdaysRow from './WeekDays/WeekdaysRow';
import {CustomGMap} from './Maps/CustomGMap';
// import MyCustomMapComponent from './Maps/CustomMap';

class AnalyticsDashboard extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            center: {
                lat: 23.259933,
                lng: 77.412613
            },
            startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()),
            endDate: new Date(),
            validScanList: [],
            invalidScanList: [],
            weekDayCountList: [],
            totalProductScan: 0,
            averageProductScan: 0,
            backgroundColor: ['#e23535', '#b963ee', '#e57314', '#97d2d4', '#ffde14', '#546918', '#2c7ef2'],
            borderColor: ['#e23535', '#b963ee', '#e57314', '#97d2d4', '#ffde14', '#546918', '#2c7ef2'],
            barData: {},

        }
        this.httpService = new HttpService();
        this.options = this.getLightTheme();
    }

    componentDidMount() {

        // const startDate = this.formatDate(this.state.startDate.getFullYear(), this.state.startDate.getMonth() + 1, this.state.startDate.getDate());
        // const endDate = this.formatDate(this.state.endDate.getFullYear(), this.state.endDate.getMonth() + 1, this.state.endDate.getDate());

        this.getBarChartData();
        this.getValidProductScan();
        this.getInvalidProductScan();
    }

    getValidProductScan = () => {

        const startDate = this.formatDate(this.state.startDate.getFullYear(), this.state.startDate.getMonth() + 1, this.state.startDate.getDate())
        const endDate = this.formatDate(this.state.endDate.getFullYear(), this.state.endDate.getMonth() + 1, this.state.endDate.getDate())

        this.httpService.getApi(`valid-scans/map?startDate=${startDate}&endDate=${endDate}`).then((response) => {
            console.log(response);
            this.setState({
                validScanList: response.data
            })
        })
    }

    getInvalidProductScan = () => {

        const startDate = this.formatDate(this.state.startDate.getFullYear(), this.state.startDate.getMonth() + 1, this.state.startDate.getDate())
        const endDate = this.formatDate(this.state.endDate.getFullYear(), this.state.endDate.getMonth() + 1, this.state.endDate.getDate())

        this.httpService.getApi(`invalid-scans/map?startDate=${startDate}&endDate=${endDate}`).then((response) => {
            console.log(response);
            this.setState({
                invalidScanList: response.data
            })
        })
    }

    getBarChartData = () => {
        let barBackgroundColor = []
        let barBorderColor = []
        let barCountData = []
        let barLabels = []

        const startDate = this.formatDate(this.state.startDate.getFullYear(), this.state.startDate.getMonth() + 1, this.state.startDate.getDate())
        const endDate = this.formatDate(this.state.endDate.getFullYear(), this.state.endDate.getMonth() + 1, this.state.endDate.getDate())

        this.httpService.getApi(`dashboard/report/product-scan-details?startDate=${startDate}&endDate=${endDate}`)
            .then((response) => {
                response.data.barChartCountList.forEach((item) => {
                    barCountData.push(item.count)
                    barLabels.push(item.date)
                    let date = new Date(item.date);
                    // console.log(date);
                    let dayIndex = date.getDay();
                    // console.log(dayIndex, "and color at particular index ", this.state.backgroundColor[dayIndex]);
                    barBackgroundColor.push(this.state.backgroundColor[dayIndex]);
                    barBorderColor.push(this.state.borderColor[dayIndex]);
                })

                barCountData.push(0);
                this.setState({
                    weekDayCountList: response.data?.weekDayCountDTOList,
                    totalProductScan: response.data?.totalProductScan,
                    averageProductScan: response.data?.averageProductScan,
                    barData: {
                        labels: barLabels,
                        datasets: [
                            {
                                label: 'Monthly Product Scaned',
                                backgroundColor: barBackgroundColor,
                                borderColor: barBorderColor,
                                data: barCountData
                            }
                        ]
                    }
                })

            })
    }

    handleClickClearDateFilter = () => {
        this.setState({ startDate: new Date(), endDate: new Date() }, () => {
            console.log(this.state.startDate);
            console.log(this.state.endDate);
        })
    }

    handleClickDateFilter = () => {
        this.getBarChartData();
        this.getValidProductScan();
        this.getInvalidProductScan();
    }

    formatDate = (year, month, day) => {
        const yyyy = year.toString();
        const mm = month.toString().padStart(2, '0');
        const dd = day.toString().padStart(2, '0');

        return `${yyyy}-${mm}-${dd}`;
    }

    getLightTheme() {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .8,
            plugins: null,
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };
        return {
            basicOptions,
        }
    }

    render() {
        const { basicOptions, horizontalOptions, multiAxisOptions, stackedOptions } = this.options;
        return <>
            <Marquee text="Real Time Product Scan Dashboard" />
            <div className="p-grid dashboard" style={{ marginTop: '40px' }}>
                <div className="p-col-12 p-md-2">
                    <span className='p-float-label'>
                        <Calendar dateFormat='yy-mm-dd' maxDate={this.state.endDate} inputId="start_date" value={this.state.startDate} onChange={(e) => this.setState({ startDate: e.value })} showIcon />
                        <label htmlFor="start_date">Start</label>
                    </span>
                </div>
                <div className="p-col-12 p-md-2">
                    <span className='p-float-label'>
                        <Calendar onClearButtonClick={this.handleClickClearDateFilter} dateFormat='yy-mm-dd' minDate={this.state.startDate} inputId="end_date" value={this.state.endDate} onChange={(e) => this.setState({ endDate: e.value })} showIcon />
                        <label htmlFor="end_date">End</label>
                    </span>
                </div>
                <div className="p-col-12 p-md-2">
                    <Button label="Filter" onClick={this.handleClickDateFilter} />
                    {/* <Button label="Clear" severity="secondary" onClick={this.handleClickClearDateFilter} /> */}
                </div>
                <div className="p-col-12 p-md-6">
                </div>

                <div className='p-col-12 p-md-5' style={{ marginTop: '20px' }}>
                    <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: '500' }}>Product scan location</p>
                        {/* <MyCustomMapComponent coordinates={this.state.center} validList={this.state.validScanList} invalidList={this.state.invalidScanList} /> */}
                        {this.state.validScanList.length > 0 && this.state.invalidScanList.length > 0 &&
                            <CustomGMap 
                                vaildScans={this.state.validScanList}
                                invalidScans={this.state.invalidScanList}
                            />
                        }
                </div>
                <div className="p-col-12 p-md-7" style={{ marginTop: '20px' }}>
                    <p style={{ textAlign: 'center', fontSize: '16px', fontWeight: '500' }}>Number of product scan per day</p>
                    <div className='row p-grid' style={{ marginLeft: '60px' }}>
                        <WeekdaysRow></WeekdaysRow>
                    </div>
                    <Chart type="bar" data={this.state.barData} options={basicOptions} />
                    <div className="row p-grid p-col-12 tableDataInfoDiv">
                        <div className='tableDiv'>
                            <DataTable ref={(el) => this.dt = el} value={this.state.weekDayCountList}>
                                <Column style={{ textAlign: 'center' }} field="day" header="Day" ></Column>
                                <Column style={{ textAlign: 'center' }} field="count" header="Frequency"></Column>
                            </DataTable>
                        </div>
                        <div className='dataInfoDiv'>
                            <div className='p-col-4'>
                                <div className='titleDiv'>Number of scanned product categories</div>
                                <div className=' textDiv'>2</div>
                            </div>
                            <div className='p-col-4'>
                                <div className='titleDiv'>Number of total scanned product</div>
                                <div className='textDiv'>{this.state.totalProductScan}</div>
                            </div>
                            <div className='p-col-4'>
                                <div className='titleDiv'>Average number of products scanned / day</div>
                                <div className='textDiv'>{this.state.averageProductScan.toFixed(2)}</div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </>
    }
}

export default AnalyticsDashboard