import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import { trackPromise } from "react-promise-tracker";
import { HttpService } from "./../service/HttpService";
import { checkForError, showMessage } from "./../utility/AppUtility";
import { AppBreadcrumb } from "./../AppBreadcrumb";
import moment from "moment";

export class InventoryList extends Component {
  constructor() {
    super();
    this.state = {
      dataTableValue: [],
      page: 1,
      first: 0,
      totalRecords: 0,
      size: 10,
      expandedRows: null,
      isSearch: false,
      searchKey: "",
      searchColumn: ""
    };

    this.httpService = new HttpService();
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
  }

  componentDidMount() {
    this.getList(this.state.page - 1);
  }



  getList = (page) => {
    trackPromise(
      this.httpService
        .getApi(
          "v1/inventory_item/getAll?page=" +
          page +
          "&size=" +
          this.state.size +
          "&sort=createdDate,desc"
        )
        .then((response) => {
          console.log(response.data.content)
          this.setState({
            totalRecords: response.data.totalElements,
            dataTableValue: response.data.content,
          });

        })
        .catch((error) => {
          if (checkForError(error))
            this.showMessage(
              "error",
              "Error Message",
              error.response.data.title
            );
        })
    );
  };
  showMessage(severity, summary, message) {
    let msg = { severity: severity, summary: summary, detail: message };
    this.growl.show(msg);
  }
  onPageChange = (event) => {
    this.setState({
      page: event.page,
      first: event.first
    });
    if (this.state.isSearch) {
      this.customFilter(this.state.searchKey, this.state.searchColumn, this.state.page)
    } else {
      this.getList(event.page);
    }
  };


  rowExpansionTemplate(data) {
    console.log(data)
    return (
      <div className="orders-subtable">
        <h4>Inventory items </h4>
        <DataTable value={data.inventoryList?.reverse()}>
          <Column
            field="productId.productName"
            header="Product Name"
            sortable
          ></Column>
          <Column
            field="productId.productCode"
            header="Product Code"
            sortable
          ></Column>
          <Column field="quantity" header="Quantity" sortable></Column>
          <Column
            field={this.returnValue}
            header="Inward Date"
            sortable
          ></Column>
        </DataTable>
      </div>
    );
  }

  returnHeader = () => {
    return (
      <div>
        <div>Inventory</div>

        <div className="p-datatable-globalfilter-container">
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
          />
        </div>
      </div>
    );
  };

  returnValue = (rowData) => {
    return moment(new Date(rowData.createdDate))
      .format("DD-MM-YY hh:mm a")
      .toString();
  };
  returnOutwardDate = (rowData) => {
    return moment(new Date(rowData.outwardDate))
      .format("DD-MM-YY hh:mm a")
      .toString();
  };


  customFilter(searchValue, columnName, pageNo) {
    if (searchValue == "") {
      this.setState({ isSearch: false })
      this.getList(0);
    } else {
      this.setState({
        searchKey: searchValue,
        searchColumn: columnName,
        isSearch: true
      })
      this.httpService.searchApi(`v1/inventory_item/search?column=${columnName}&searchKey=${searchValue}&page=${pageNo}&size=${this.state.size}`).then((response) => {
        this.setState({ dataTableValue: response.data.content, totalRecords: response.data.totalElements, page: 1 });
      }).catch((error) => {
        if (checkForError(error))
          this.showMessage('error', 'Error Message', error.response.data.title)
      })
    }

  }

  renderSearchElement(columnName) {
    return (
      <React.Fragment>
        <InputText onChange={(e) => this.customFilter(e.target.value, columnName, 0)} placeholder="Contains" ></InputText>
      </React.Fragment>
    );
  }

  // expandAll() {
  //   let expandedRows = {};
  //   this.state.dataTableValue.forEach(
  //     (p) => (expandedRows[`${p.productId.id}`] = true)
  //   );
  //   this.setState({ expandedRows });
  // }

  // collapseAll() {
  //   this.setState({
  //     expandedRows: null,
  //   });
  // }

  render() {
    let actionHeader = <Button type="button" icon="pi-md-settings" />;

    const header = (
      <>
        <font>List of Inventory</font>
        <br></br>

        <div className="merginTop10">
          {/* <Button
            icon="pi pi-plus"
            label="Expand All"
            onClick={this.expandAll}
            className="p-mr-2"
          />
          <Button
            icon="pi pi-minus"
            label="Collapse All"
            onClick={this.collapseAll}
          /> */}
        </div>
      </>
    );

    return (
      <>
        <AppBreadcrumb breadcrumdItems={[{ label: "Inventory List" }]} />
        <div className="p-grid">
          <div className="p-col-12">
            <Growl
              ref={(el) => (this.growl = el)}
              style={{ marginTop: "75px" }}
            />
            <div className=" datatable-demo">
              <div className="p-col-12">
                <font className="font21Weight500">Inventory</font>
              </div>
              <div className="p-col-12"></div>
              <DataTable
                ref={(el) => (this.dt = el)}
                value={this.state.dataTableValue}
                lazy
                paginator
                rows={10}
                totalRecords={this.state.totalRecords}
                expandedRows={this.state.expandedRows}
                rowExpansionTemplate={this.rowExpansionTemplate}
                dataKey="productId.id"
                header={header}
                onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                responsive={false}
                resizableColumns={true}
                columnResizeMode="fit"
                onPage={(event) => this.onPageChange(event)}
                first={this.state.first}
              >

                <Column expander style={{ width: "3em" }} />
                <Column
                  filterElement={this.renderSearchElement("product.productName")}
                  field="productId.productName"
                  header="Product Name"
                  headerStyle={{ fontWight: "600" }}
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  filterElement={this.renderSearchElement("product.productCode")}
                  field="productId.productCode"
                  header="Product Code"
                  headerStyle={{ fontWight: "600" }}
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  filterElement={this.renderSearchElement("quantity")}
                  field="quantity"
                  header="Actual Quantity"
                  headerStyle={{ fontWight: "600" }}
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  filterElement={this.renderSearchElement("quantity")}
                  field="inwardQuantity"
                  header="Ontward Quantity"
                  headerStyle={{ fontWight: "600" }}
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />

                <Column
                  filterElement={this.renderSearchElement("quantity")}
                  field="remainingQuantity"
                  header="Available Stock"
                  headerStyle={{ fontWight: "600" }}
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  filterElement={this.renderSearchElement("createdDate")}
                  field={this.returnOutwardDate}
                  header="Outward Date"
                  headerStyle={{ fontWight: "600" }}
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  filterElement={this.renderSearchElement("product.createdDate")}
                  field={this.returnValue}
                  header="Created Date"
                  headerStyle={{ fontWight: "600" }}
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                {/* <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} /> */}
              </DataTable>
            </div>
          </div>
        </div>
      </>
    );
  }
}
