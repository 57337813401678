const format = [
    {
        label: "Code11",
        value: "CODE11"
    },
    {
        label: "Australia Post Standard Customer",
        value: "AUSTRALIA_POST_STANDARD_CUSTOMER"
    },
    {
        label: "Channel Code",
        value: "CHANNEL_CODE"
    },
    {
        label: "Aztec Runes",
        value: "AZTEC_RUNES"
    },
    {
        label: "Aztec Code",
        value: "AZTEC_CODE"
    },
    {
        label: "Australia Post Redirection",
        value: "AUSTRALIA_POST_REDIRECTION"
    },
    {
        label: "Australia Post Routing",
        value: "AUSTRALIA_POST_ROUTING"
    },
    {
        label: "Australia Post Reply Paid",
        value: "AUSTRALIA_POST_REPLY_PAID"
    },
    {
        label: "Industrial 2 of 5",
        value: "INDUSTRIAL_2_OF_5"
    },
    {
        label: "Matrix 2 of 5",
        value: "MATRIX_2_OF_5"
    },
    {
        label: "Code 16k",
        value: "CODE_16K"
    },
    {
        label: "Code 128",
        value: "CODE_128"
    }, {
        label: "Codablock F",
        value: "CODABLOCK_F"
    },
    {
        label: "Code 32 (Italian pharmacode)",
        value: "CODE_32"
    },
    {
        label: "Deutsche Post Identcode",
        value: "DEUTSCHE_POST_IDENTCODE"
    },
    {
        label: "Deutsche Post Leitcode",
        value: "DEUTSCHE_POST_LEITCODE"
    }, {
        label: "ITF-14",
        value: "ITF_14"
    },
    {
        label: "Interleaved 2 of 5",
        value: "INTERLEAVED_2_OF_5"
    },
    {
        label: "Datalogic 2 of 5",
        value: "DATALOGIC_2_OF_5"
    },
    {
        label: "IATA 2 of 5",
        value: "IATA_2_OF_5"
    },
    {
        label: "Codabar",
        value: "CODABAR"
    },
    {
        label: "EAN-8",
        value: "EAN_8"
    },
    {
        label: "EAN-13",
        value: "EAN_13"
    },
    {
        label: "Data Matrix",
        value: "DATA_MATRIX"
    },
    {
        label: "Composite",
        value: "COMPOSITE"
    }, {
        label: "Code One",
        value: "CODE_ONE"
    },
    {
        label: "Code 93",
        value: "CODE_93"
    },
    {
        label: "Code 49",
        value: "CODE_49"
    },
    {
        label: "Code 3 of 9 Extended (Code 39 Extended)",
        value: "CODE_3_OF_9_EXTENDED"
    },
    {
        label: "Code 3 of 9 (Code 39)",
        value: "CODE_3_OF_9"
    },
    {
        label: "Japan Post",
        value: "JAPAN_POST"
    },
    {
        label: "GS1 DataBar Limited",
        value: "GS1_DATABAR_LIMITED"
    },
    {
        label: "GS1 DataBar Expanded Stacked",
        value: "GS1_DATABAR_EXPANDED_STACKED"
    },
    {
        label: "GS1 DataBar Expanded",
        value: "GS1_DATABAR_EXPANDED"
    }, {
        label: "GS1 DataBar Stacked Omnidirectional",
        value: "GS1_DATABAR_STACKED_OMNIDIRECTIONAL"
    },
    {
        label: "GS1 DataBar Stacked",
        value: "GS1_DATABAR_STACKED"
    },
    {
        label: "GS1 DataBar",
        value: "GS1_DATABAR"
    },
    {
        label: "Grid Matrix",
        value: "GRID_MATRIX"
    },
    {
        label: "Pharmacode",
        value: "PHARMACODE"
    },
    {
        label: "Micro PDF417",
        value: "MICRO_PDF417"
    },
    {
        label: "Truncated PDF417",
        value: "TRUNCATED_PDF417"
    },
    {
        label: "PDF417",
        value: "PDF417"
    }, {
        label: "MSI (Modified Plessey)",
        value: "MSI"
    },
    {
        label: "MaxiCode",
        value: "MAXICODE"
    },
    {
        label: "LOGMARS",
        value: "LOGMARS"
    },
    {
        label: "Korea Post",
        value: "KOREA_POST"
    },
    {
        label: "USPS OneCode (Intelligent Mail)",
        value: "USPS_ONECODE"
    },
    {
        label: "UPC-E",
        value: "UPC_E"
    },
    {
        label: "UPC-A",
        value: "UPC_A"
    },
    {
        label: "Telepen Numeric",
        value: "TELEPEN_NUMERIC"
    },
    {
        label: "Telepen",
        value: "TELEPEN"
    }, {
        label: "Royal Mail 4 State (RM4SCC)",
        value: "ROYAL_MAIL"
    },
    {
        label: "QR Code",
        value: "QR_CODE"
    },
    {
        label: "POSTNET / PLANET",
        value: "POSTNET_PLANET"
    },
    {
        label: "Pharmacode Two-Track",
        value: "PHARMACODE_TWO_TRACK"
    }
    
]

export default format