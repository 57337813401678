import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Growl } from "primereact/growl";
import React, { Component } from "react";
import { trackPromise } from "react-promise-tracker";
import { withRouter } from "react-router-dom";
import { AppBreadcrumb } from "../../AppBreadcrumb";
import { HttpService } from "../../service/HttpService";
import { checkForError, showMessage } from "../../utility/AppUtility";
import CheckPriviladge from "./../../hoc/CheckPriviladge";
import { PriviladgeContext } from "./../../context/priviladge-context";
import constant from "../../constant/Constant";
import { clean } from "../../utility/AppUtility";
import { FileUpload } from "primereact/fileupload";

import axios from "axios";
import { icons } from "react-icons";
import { InputText } from "primereact/inputtext";
export class CustomerList extends Component {
  constructor() {
    super();
    this.myRef = React.createRef();
    this.state = {
      dataTableValue: [],
      page: 1,
      totalRecords: 0,
      size: 10,
      trackNTrace: false,
      file: null,
      disableFile: false,
      growl: null,
      obj: {},
      upload: {},
      first: 0,
      searchKey: "",
      searchColumn: "",
      isSearch: false
    };

    this.httpService = new HttpService();
    this.properties = [
      { key: "companyName", name: "Company Name" },
      { key: "email", name: "Email" },
      { key: "mobileNo", name: "mobile" },
      {
        key: "address",
        name: "Address",
      },
    ];
    var AppBreadCrumbWithRouter = withRouter(AppBreadcrumb);
  }

  static contextType = PriviladgeContext;
  componentDidMount() {
    if (
      localStorage.getItem("track&Trace") != undefined &&
      localStorage.getItem("track&Trace")
    ) {
      this.setState({ trackNTrace: true });
      this.getList(this.state.page - 1, true);
    } else {
      this.getList(this.state.page - 1, false);
    }
  }
  getList = (page, trackNTrace) => {
    let url = "customers/company";
    if (trackNTrace) url = "business_partners";
    trackPromise(
      this.httpService
        .getApi(
          url +
          "?page=" +
          page +
          "&size=" +
          this.state.size +
          "&sort=createdDate,desc&isPage=true"
        )
        .then((response) => {
          this.setState({
            dataTableValue: response.data.content,
            totalRecords: response.data.totalElements,
          });
        })
        .catch((error) => {
          if (checkForError(error))
            this.showMessage(
              "error",
              "Error Message",
              error.response.data.title
            );
        })
    );
  };
  showMessage(severity, summary, message) {
    let msg = { severity: severity, summary: summary, detail: message };
    this.growl.show(msg);
  }
  onPageChange = (event) => {
    this.setState({
      page: event.page,
      first: event.first,
    });
    if (this.state.isSearch) {
      this.customFilter(this.state.searchKey, this.state.searchColumn, event.page);
    } else {
      this.getList(event.page);
    }
  };
  // onFileSelect = (e) => {
  // 	// this.setState({ obj:this.state.obj, files: e.files[0] });
  // 	this.setState({disableFile:true})
  // 	console.log('e.files[0]=============='+e)

  // }

  customFilter(searchValue, columnName, pageNo) {
    if (searchValue == "") {
      this.setState({ isSearch: false, page: 1, first: 0 })
      this.getList(0, this.state.trackNTrace);
    } else {
      this.setState({
        searchKey: searchValue,
        searchColumn: columnName,
        isSearch: true
      })
      let type = "";
      if (this.state.trackNTrace) {
        type = "business_partners"
      }
      if (searchValue == "") {
        this.getList(0, this.state.trackNTrace)
      } else {
        this.httpService.searchApi(`customers/search?columnName=${columnName}&searchKey=${searchValue}&type=${type}&page=${pageNo}&size=${this.state.size}`).then((response) => {
          this.setState({ dataTableValue: response.data.content, totalRecords: response.data.totalElements });
        }).catch((error) => {
          if (checkForError(error))
            this.showMessage('error', 'Error Message', error.response.data.title)
        })
      }
    }

  }

  renderSearchElement(columnName) {
    return (
      <React.Fragment>
        <InputText onChange={(e) => this.customFilter(e.target.value, columnName, 0)} placeholder="Contains" ></InputText>
      </React.Fragment>
    );
  }

  onFileChange = (e, rowData) => {
    console.log(
      "================================================" + this.state.upload.id
    );
    // Update the state
    this.setState({ files: e.target.files[0] });
    let formData = new FormData();
    // let customerId = this.state.dataTableValue.id;
    let httpService = new HttpService();
    formData.append("files", e.target.files[0]);
    trackPromise(
      httpService
        .getApiMultipart(
          "product/saveData?customerId=" + this.state.upload.id,
          formData
        )
        .then((response) => {
          if (response.status == 200) {
            this.showMessage(
              "success",
              "Success Message",
              "Product will upload soon...",
              this.state.growl
            );
          }
        })
        .catch((error) => {
          if (checkForError(error)) {
            this.showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              this.state.growl
            );
          }
        })
    );
  };

  // save = (e, data) => {

  // 	const obj = clean(obj)
  // 	let formData = new FormData()
  // 	for (const property in obj) {
  // 		if (property === "files") {
  // 			if (obj[property] !== undefined)
  // 				obj[property].forEach(element => {
  // 					formData.append("files", element = e.files[0]);
  // 				});

  // 		} else
  // 			if (obj[property] !== undefined)
  // 				formData.append(property, obj[property]);
  // 	}
  // 	trackPromise(
  // 		httpService.getApiMultipart('https://funqid.com/token_printing/api/product/saveData?customerId=' + customerId, formData).then((response) => {
  // 		}).catch((error) => {
  // 			if (checkForError(error)) {
  // 				showMessage('error', 'Error Message', error.response.data.title, this.state.growl)
  // 			}
  // 		}));

  // }

  download = () => {
    var a = document.createElement("a");
    a.href = constant.VIEW_SAMPLE_URI + "ProductSampleExcel.xlsx";
    a.download = "ProductSampleExcel.xlsx";
    a.click();
    a.remove();
  };

  edit = (rowData) => {
    let url = "/customer_edit";
    if (this.state.trackNTrace) url = "/business_partner_edit";
    this.props.history.push({
      pathname: url,
      state: { id: rowData.id },
    });
  };
  view = (rowData) => {
    let uri = "customer_list";
    let url = "customers/";
    let title = "Customer";
    if (this.state.trackNTrace) {
      uri = "business_partner_list";
      url = "business_partners/";
      title = "Business Partner";
    }
    this.props.history.push({
      pathname: "/view",
      state: {
        data: rowData,
        title: title,
        uri: uri,
        properties: this.properties,
        url: url,
      },
    });
  };
  addTemplate = (rowData) => {
    this.props.history.push({
      pathname: "/template_add",
      state: { id: rowData.id },
    });
  };

  add = () => {
    let url = "customer_add";
    if (this.state.trackNTrace) url = "business_partner_add";
    this.props.history.push(url);
  };
  delete = (rowData) => {
    trackPromise(
      this.httpService
        .deleteApi("customers/" + rowData.id)
        .then((response) => {
          this.getList(0);
        })
        .catch((error) => {
          if (checkForError(error)) {
            this.showMessage(
              "error",
              "Error Message",
              error.response.data.title
            );
          }
        })
    );
  };

  handleClick = (event) => {
    this.setState({ upload: event });
    this.myRef.current.click();
  };
  handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // props.handleFile(fileUploaded);
    console.log(fileUploaded);
  };
  // actionTemplate = (rowData, column) => {
  //     return <div style={{ width: "110px" }}>
  //         <Button type="button" tooltip="Add Template" tooltipOptions={{ position: 'bottom' }} icon="pi-md-attachment" className="p-button-info" onClick={() => this.addTemplate(rowData)} />
  //         <Button type="button" tooltip="Delete" tooltipOptions={{ position: 'bottom' }} icon="pi-md-delete-forever" className="p-button-danger" onClick={() => this.delete(rowData)} style={{ marginRight: '.5em' }} />
  //         <Button type="button" tooltip="Edit" tooltipOptions={{ position: 'bottom' }} icon="pi-md-pencil" className="p-button-warning" onClick={() => this.edit(rowData)} />

  //     </div>;
  // }
  actionTemplate = (rowData, column) => {
    const uploadOptions = {
      icon: "pi pi-fw pi-cloud-upload",
      iconOnly: true,
      className:
        "custom-upload-btn p-button-success p-button-rounded p-button-outlined",
    };

    if (!this.state.trackNTrace)
      return (
        <div
          style={{
            width: "170px",
            flexDirection: "row",
            justifyItems: "flex-end",
          }}
        >
          <CheckPriviladge priviladge={{ name: "Customer", priority: 3 }}>
            {" "}
            <Button
              type="button"
              tooltip="Delete"
              tooltipOptions={{ position: "bottom" }}
              icon="pi-md-delete-forever"
              className="p-button-danger"
              onClick={() => this.delete(rowData)}
              style={{ marginRight: ".5em" }}
            />
          </CheckPriviladge>
          <CheckPriviladge priviladge={{ name: "Customer", priority: 2 }}>
            {" "}
            <Button
              type="button"
              tooltip="Edit"
              tooltipOptions={{ position: "bottom" }}
              icon="pi-md-pencil"
              className="p-button-warning"
              onClick={() => this.edit(rowData)}
            />
          </CheckPriviladge>
          <CheckPriviladge priviladge={{ name: "Customer", priority: 1 }}>
            {" "}
            <Button
              type="button"
              tooltip="View"
              tooltipOptions={{ position: "bottom" }}
              icon="pi-md-remove-red-eye"
              className="p-button-info"
              onClick={() => this.view(rowData)}
            />
          </CheckPriviladge>
          <CheckPriviladge priviladge={{ name: "Customer", priority: 1 }}>
            <Button
              type="button"
              tooltip="Upload Products"
              tooltipOptions={{ position: "bottom" }}
              icon="pi-md-file-upload"
              className="p-button-success"
              onClick={() => this.handleClick(rowData)}
            />

            <input
              type="file"
              ref={this.myRef}
              onChange={(event) =>
                this.onFileChange(event, this.state.upload.id)
              }
              style={{ display: "none" }}
            />
          </CheckPriviladge>
        </div>
      );
    else
      return (
        <div style={{ width: "120px" }}>
          <Button
            type="button"
            tooltip="Delete"
            tooltipOptions={{ position: "bottom" }}
            icon="pi-md-delete-forever"
            className="p-button-danger"
            onClick={() => this.delete(rowData)}
            style={{ marginRight: ".5em" }}
          />
          <Button
            type="button"
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            icon="pi-md-pencil"
            className="p-button-warning"
            onClick={() => this.edit(rowData)}
          />
          <Button
            type="button"
            tooltip="View"
            tooltipOptions={{ position: "bottom" }}
            icon="pi-md-remove-red-eye"
            className="p-button-info"
            onClick={() => this.view(rowData)}
          />
        </div>
      );
  };

  render() {
    let actionHeader = <Button type="button" icon="pi-md-settings" />;

    return (
      <>
        <AppBreadcrumb
          breadcrumdItems={[
            {
              label: this.state.trackNTrace
                ? "Business Partner List"
                : "Customer List",
            },
          ]}
        />
        <div className="p-grid">
          <div className="p-col-12">
            <Growl
              ref={(el) => (this.growl = el)}
              style={{ marginTop: "75px" }}
            />

            <div className="card card-w-title datatable-demo">
              <div className="p-col-12">
                <font className="font21Weight500">
                  {this.state.trackNTrace ? "Business Partners" : "Customers"}
                </font>

                {this.state.trackNTrace ? (
                  <>
                    {" "}
                    <Button
                      className="floatRight"
                      icon="pi-md-plus"
                      tooltip={
                        this.state.trackNTrace
                          ? "Add Business Partner"
                          : "Add Customer"
                      }
                      label="Add"
                      tooltipOptions={{ position: "left" }}
                      onClick={() => this.add()}
                    />
                  </>
                ) : (
                  <>
                    <CheckPriviladge
                      priviladge={{
                        name: this.state.trackNTrace
                          ? "Business Partner"
                          : "Customer",
                      }}
                    >
                      {" "}
                      <Button
                        className="floatRight"
                        icon="pi-md-file-download"
                        tooltip={"Download Sample Excel"}
                        label="Download"
                        tooltipOptions={{ position: "left" }}
                        onClick={() => this.download()}
                      />
                    </CheckPriviladge>
                    <CheckPriviladge
                      priviladge={{
                        name: this.state.trackNTrace
                          ? "Business Partner"
                          : "Customer",
                        priority: 2,
                      }}
                    >
                      {" "}
                      <Button
                        className="floatRight"
                        icon="pi-md-plus"
                        tooltip={
                          this.state.trackNTrace
                            ? "Add Business Partner"
                            : "Add Customer"
                        }
                        label="Add"
                        tooltipOptions={{ position: "left" }}
                        onClick={() => this.add()}
                      />
                    </CheckPriviladge>
                  </>
                )}
              </div>
              <div className="p-col-12"></div>
              <DataTable
                ref={(el) => (this.dt = el)}
                value={this.state.dataTableValue}
                header={
                  this.state.trackNTrace
                    ? "List of Business Partner"
                    : "List of Customer"
                }
                paginator={true}
                rows={10}
                lazy
                onPage={(event) => this.onPageChange(event)}
                totalRecords={this.state.totalRecords}
                first={this.state.first}
                responsive={true}
                resizableColumns={true}
                columnResizeMode="fit"
              >
                <Column
                  filterElement={this.renderSearchElement("companyName")}
                  field="companyName"
                  header="Company Name"
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  filterElement={this.renderSearchElement("mobileNo")}
                  field="mobileNo"
                  header="Phone No."
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  filterElement={this.renderSearchElement("email")}
                  field="email"
                  header="Email"
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  filterElement={this.renderSearchElement("address")}
                  field="address"
                  header="Address"
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  filterElement={this.renderSearchElement("companyCode")}
                  field="companyCode"
                  header="Company Code"
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />

                <Column
                  filterElement={this.renderSearchElement("messageOnScan")}
                  field="messageOnScan"
                  header="Message on Qr Code Scanning"
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  header={actionHeader}
                  body={this.actionTemplate}
                  style={{ textAlign: "center", width: "15em" }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </>
    );
  }
}
