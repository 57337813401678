import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputSwitch } from 'primereact/inputswitch';
import React, { useEffect, useState, useRef } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean, showMessage } from '../../utility/AppUtility';
import constant from './../../constant/Constant';
import { Sidebar } from 'primereact/sidebar';
import SelectOption from './SelectOption';
import DesignSetting from './DesignSetting'
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { AppBreadcrumb } from '../../AppBreadcrumb';


const axios = require('axios').default;

function DragNDrop(props) {

    var [id, setId] = useState("TEMP00026")
    var [type, setType] = useState(constant.QR_CODE)
    var [fontType, setFontType] = useState([])
    var [savedComponent, setSavedComponent] = useState([])

    if (props === undefined || props === null
        || props.location === undefined || props.location === null
        || props.location.state === undefined || props.location.state === null || props.location.state.id === undefined ||
        props.location.state.id === null) {
        props.history.push('template_design')
    } else {
        id = props.location.state.id;

    }

    const imageAsset = "assets/layout/images/"
    var [obj, setObj] = useState({ templateId: id, color: "#000000",formatColor: "#000000"})
    var [showGrid, setShowGrid] = useState(false)
    var [exact, setExact] = useState(false)
    var [visibleLeft, setVisibleLeft] = useState(false)
    var [visibleRight, setVisibleRight] = useState(false)
    var [format, setFormat] = useState(type)
    const handleFormat = (e) => {
        setFormat(e.target.value)
    }

    const growl = useRef(null)
    const httpService = new HttpService();

    useEffect(() => {

        loadFileFromServer()
        getFontList()
    }, [])
    const changeType = (type) => {
        setType(type)
        setFormat(type)
    }
    const resetComponent = () => {
        const QRcode = document.getElementById('QRcode');
        // const QRcodeX = document.getElementById('QRcodeX');
        // const QRcodeY = document.getElementById('QRcodeY');
        QRcode.style.transform = null;
        // QRcodeX.style.transform = null;
        // QRcodeY.style.transform = null;
        QRcode.style.width = '50pt';
        // QRcodeX.style.width = '50pt';
        // QRcodeY.style.width = '50pt';
        QRcode.style.height = '50pt';
        // QRcodeX.style.height = '50pt';
        // QRcodeY.style.height = '50pt';
        QRcode.removeAttribute("data-xx")
        QRcode.removeAttribute("data-yy")
        QRcode.removeAttribute("data-x")
        QRcode.removeAttribute("data-y")
        // QRcodeX.removeAttribute("data-xx")
        // QRcodeX.removeAttribute("data-yy")
        // QRcodeX.removeAttribute("data-x")
        // QRcodeX.removeAttribute("data-y")
        // QRcodeY.removeAttribute("data-xx")
        // QRcodeY.removeAttribute("data-yy")
        // QRcodeY.removeAttribute("data-x")
        // QRcodeY.removeAttribute("data-y")
        document.getElementById('QRcodeInputX').value = "";
        document.getElementById('QRcodeInputY').value = "";
        // document.getElementById('QRcodeInputXX').value = "";
        // document.getElementById('QRcodeInputXY').value = "";
        // document.getElementById('QRcodeInputYX').value = "";
        // document.getElementById('QRcodeInputYY').value = "";
        setObj({ templateId: id, color: "#000000" });



    }
    const getFontList = () => {
        trackPromise(
            httpService.getApi('fontList').then((response) => {
                setFontType(response.data)
            }).catch((error) => {
                if (checkForError(error))
                    showMessage('error', 'Error Message', error.response.data.title, growl)
            }))
    }
    const deleteSavedComponent = (e) => {
        const temp = [...savedComponent]
        let index = temp.findIndex(el => el == e);
        temp.splice(index, 1);
        setSavedComponent(temp)

    }
    const handleChange = (e, type) => {
        let val = e.target.value;

        setObj({ ...obj, [e.target.name]: val });
        return val;
    }
    const setColors = (e) => {
        if (e.target.name == "color" || e.target.name == "formatColor" ) {
            if (e.target.value.name == undefined) {
                validateColor(e.target.value,e.target.name)

            } else {
                validateColor(e.target.value.name,e.target.name)
            }
        }
    }
    const validateColor = (color1,name) => {
        if (color1 == "" || color1 == undefined) {
            return;
        }
        color1 = color1?.toLowerCase();
        let c = window.w3color(color1);
        if (c.valid) {
            setObj({ ...obj, [name]: c.toHexString() });

        }
    }


    const showHideGrid = (event) => {
        setShowGrid(event.value)
        var overlay = document.getElementById('overlay');
        if (event.value) {
            showGrid1(overlay);
        } else {
            hideGrid(overlay);
        }
    }
    const showGrid1 = (overlay) => {
        overlay.classList.remove('displayNone');
        setWidthAndHeight(overlay)
    }



    const hideGrid = (overlay) => {
        overlay.classList.add('displayNone');
    }
    const setExact1 = (exact1) => {
        setExact(exact1)
    }
    const setWidthAndHeight = (overlay) => {
        var canvas = document.getElementById('the-canvas');
        overlay.style.height = canvas.height + "px";
        overlay.style.width = canvas.width + "px";
    }

    const getAuthorization = () => {
        return { 'Authorization': 'Bearer ' + localStorage.getItem("token") }
    }
    const loadFileFromServer = () => {
        const method = 'GET';
        const url = constant.BASEURl + "getTemplate?id=" + id;
        axios
            .request({
                url,
                method,
                responseType: 'blob', //important
                headers: getAuthorization()
            })
            .then(({ data }) => {
                const downloadUrl = window.URL.createObjectURL(new Blob([data]));
                functionLoadpdf(downloadUrl)

            });
    }

    window.pdfjsLib.GlobalWorkerOptions.workerSrc =
        'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.0.943/pdf.worker.min.js';

    function functionLoadpdf(val) {

        var loadingTask = window.pdfjsLib.getDocument(val);
        loadingTask.promise.then(function (pdf) {
            //
            // Fetch the first page
            //
            pdf.getPage(1).then(function (page) {
                var scale = 1.32;
                var viewport = page.getViewport(scale);
                //
                // Prepare canvas using PDF page dimensions
                //
                var canvas = document.getElementById('the-canvas');
                if (canvas != null) {
                    var context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    var overlay = document.getElementById('overlay');
                    if (overlay != null) {
                        overlay.style.height = viewport.height + "px";
                        overlay.style.width = viewport.width + "px";
                    }
                    var pageContainer = document.getElementById('pageContainer');
                    pageContainer.style.height = (viewport.height + 20) + "px";
                    //
                    // Render PDF page into canvas context
                    //
                    var renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };
                    //page.render(renderContext);
                    page.render(renderContext).then(function () {
                        window.$(document).trigger("pagerendered");
                    }, function () {
                        console.log("ERROR");
                    });
                }
            });
        });
    }

    const getCordinnateArray = () => {
        var cordinates = [];
       let  isInvalid=false;
        window.$('.resize-drag').each(function (index) {
            var name = this.name;
            var width = this.style.width
            var height = this.style.height
            if (this.style.width === "") {
                width = 50;
            } else {
                width = width.substring(0, width.length - 2)
            }
            if (this.style.height === "") {
                height = 50;
            } else {
                height = height.substring(0, height.length - 2)
            }
            let x = 0;
            let y = 0;
            if (this.dataset !== null && this.dataset !== undefined) {
                if (name === type) {
                    x = parseFloat(this.dataset.xx);
                    y = parseFloat(this.dataset.yy);
                    if (isNaN(x)) {
                        isInvalid=true
                        alert("Please positioned your element")
                    }

                }
                // if (name === type + 'X') {
                //     x = parseFloat(this.dataset.xx);
                //     y = parseFloat(this.dataset.yy);
                // }
                // if (name === type + 'Y') {
                //     x = parseFloat(this.dataset.xx);
                //     y = parseFloat(this.dataset.yy);
                // }
            }

            var val = { "name": name, "type": format, "x":x,"y":y, "width": parseFloat(width), "height": parseFloat(height), "rotation": obj.rotation };
            cordinates.push(val);
        });
        if(isInvalid){
            return []
        }
        return cordinates;

    }


    window.interact('.canvasdrop').dropzone({
        // only accept elements matching this CSS selector
        accept: '.resize-drag',
        // Require a 100% element overlap for a drop to be possible
        overlap: 1,
        // listen for drop related events:
        ondropactivate: function (event) {
            // add active dropzone feedback
            event.target.classList.add('drop-active');
        },
        ondragenter: function (event) {
            var draggableElement = event.relatedTarget,
                dropzoneElement = event.target;
            // feedback the possibility of a drop
            dropzoneElement.classList.add('drop-target');
            draggableElement.classList.add('can-drop');
            draggableElement.classList.remove('dropped-out');
            //draggableElement.textContent = 'Dragged in';
        },
        ondragleave: function (event) {
            // remove the drop feedback style
            event.target.classList.remove('drop-target');
            event.relatedTarget.classList.remove('can-drop');
            event.relatedTarget.classList.add('dropped-out');
            //event.relatedTarget.textContent = 'Dragged out';
        },
        ondrop: function (event) {
            //event.relatedTarget.textContent = 'Dropped';
        },
        ondropdeactivate: function (event) {
            // remove active dropzone feedback
            event.target.classList.remove('drop-active');
            event.target.classList.remove('drop-target');
        }
    });
    const tryPrint = () => {
        var cordinates = getCordinnateArray();
        if (cordinates.length > 0) {
            obj = clean(obj)
            obj.cordinates = cordinates;
            obj.exact = exact;
            trackPromise(
                httpService.postApi('tryit', obj).then((response) => {
                    var a = document.createElement('a');
                    a.href = constant.VIEW_SAMPLE_URI + response.data + ".pdf"
                    a.download = response.data;
                    a.target = '_blank';
                    a.click();
                    a.remove();
                }).catch((error) => {
                    if (checkForError(error)) {
                        showMessage('error', 'Error Message', error.response.data.title, growl)
                    }
                }));
        }
    }

    const saveDetail = () => {
        var cordinates = getCordinnateArray();
        if (cordinates.length > 0) {
            obj = clean(obj)
            obj.cordinates = cordinates;
            obj.exact = exact;
            trackPromise(
                httpService.postApi('saveDndTemplate', obj).then((response) => {
                    // setObj(obj, { templateId: response.data.id })
                    const savedComponentTemp = [...savedComponent];
                    savedComponentTemp.push(type)
                    setSavedComponent(savedComponentTemp)
                    showMessage('info', 'Info Message', 'Changes saved successfully', growl)
                }).catch((error) => {
                    if (checkForError(error)) {
                        showMessage('error', 'Error Message', error.response.data.title, growl)
                    }
                }));
        }
    }



    return (<>
        <AppBreadcrumb breadcrumdItems={[{ label: 'Add Template', url: "#template_design" }, { label: 'Design Template' }]} />

        <div className="p-grid ">
            <Sidebar className="marginTop111" visible={visibleLeft} baseZIndex={1000000} onHide={() => setVisibleLeft(false)}>
                <SelectOption resetComponent={resetComponent} id={id} type={type} deleteSavedComponent={deleteSavedComponent} savedComponent={savedComponent} change={(e) => changeType(e)} />
            </Sidebar>
            <Sidebar visible={visibleRight} className="ui-sidebar-lg marginTop111" style={{ width: "30%" }} position="right" baseZIndex={1000000} onHide={() => setVisibleRight(false)}>
                <DesignSetting fontType={fontType} type={type} showGrid={showGrid} exact={exact} {...obj} format={format} handleFormat={(e) => handleFormat(e)} handleChange={(e) => handleChange(e)} setColors={(e) => setColors(e)} setExact1={(e) => setExact1(e)} showHideGrid={(e) => showHideGrid(e)} setExact={(e) => setExact(e)} change={(e) => changeType(e)} />
            </Sidebar>
            <Growl ref={growl} style={{ marginTop: '75px' }} />

            <div className="col-md-12" >
                <Toolbar>
                    <div className="p-toolbar-group-left">
                        <Button label="Add Component" tooltip="Add Component" tooltipOptions={{ position: 'bottom' }} icon="pi-md-plus" className="p-button-info " onClick={() => setVisibleLeft(true)} />
                        <Button label="Setting" tooltip="Setting" tooltipOptions={{ position: 'bottom' }} className="p-button-info " icon="pi-md-settings" onClick={() => setVisibleRight(true)} />
                    </div>
                    <div className="p-toolbar-group-right">
                        <Button label="Save" tooltip="Save" tooltipOptions={{ position: 'bottom' }} icon="pi-md-save" className="p-button-info  " onClick={() => saveDetail()} />
                        <Button label="View Sample" tooltip="Sample" tooltipOptions={{ position: 'bottom' }} icon="pi-md-remove-red-eye" className="p-button-info  " onClick={() => tryPrint()} />

                    </div>
                </Toolbar>

                <div className="col-md-12"></div>
                {/* <img className="cordinateSystem" src="assets/layout/images/2DCoordinateSystem.png" alt="cordinate" /> */}
                <div className="col-md-12">
                    <div className="col-md-12 card marginTop21">
                        <div className="col-md-4">
                            <div className="col-md-12">
                                <div className="col-md-4">
                                    <img className="resize-drag widthAndheight" id="QRcode" name={type} src={imageAsset + (type + ".png")} alt="qxcode" />
                                </div>
                                <div className="col-md-4 marginTop10">
                                    <InputText id="QRcodeInputX" type="number" placeholder="X" onInput={() => window.mytransform()} />
                                    <InputText id="QRcodeInputY" type="number" placeholder="Y" onInput={() => window.mytransform()} />
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                            <div className="col-md-12 marginTop12 textAlignCenter">
                                <div className="col-md-4 textAlignCenter">
                                    <label >{type}  </label>
                                </div>
                                <div className="col-md-8 ">
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-4">
                            <div className="col-md-12">
                                <div className="col-md-4">
                                    <img className="resize-drag widthAndheight" id="QRcodeX" name={type + "X"} src={imageAsset + (type + "x.png")} alt="qxcode" />
                                </div>
                                <div className="col-md-4 marginTop10">
                                    <InputText id="QRcodeInputXX" type="number" placeholder="X" onInput={() => window.mytransformx()} />
                                    <InputText id="QRcodeInputXY" type="number" placeholder="Y" onInput={() => window.mytransformx()} />
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                            <div className="col-md-12 marginTop12 textAlignCenter">
                                <div className="col-md-4 textAlignCenter">
                                    <label >{type}  (X)</label>
                                </div>
                                <div className="col-md-8 ">
                                </div>

                            </div>
                        </div> */}

                        {/* <div className="col-md-4">
                            <div className="col-md-12">
                                <div className="col-md-4">
                                    <img className="resize-drag widthAndheight" id="QRcodeY" name={type + "Y"} src={imageAsset + (type + "y.png")} alt="qxcode" />
                                </div>
                                <div className="col-md-4 marginTop10">
                                    <InputText id="QRcodeInputYX" type="number" placeholder="X" onInput={() => window.mytransformy()} />
                                    <InputText id="QRcodeInputYY" type="number" placeholder="Y" onInput={() => window.mytransformy()} />
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                            <div className="col-md-12 marginTop12 ">
                                <div className="col-md-4 textAlignCenter">
                                    <label >{type}  (Y)</label>
                                </div>
                                <div className="col-md-8 ">
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="row positionStatic marginTop70 "  >
                        <div className="col-md-12 positionStatic marginTop10" id="pdfManager"  >
                            <div className="row positionStatic" id="selectorContainer" >
                                <div className="col-fixed-240 positionStatic" id="parametriContainer" >
                                </div>
                                <div className="col-fixed-605 positionStatic" >
                                    <div id="pageContainer" className="pdfViewer singlePageView dropzone nopadding positionStatic" style={{ backgroundColor: 'transparent', position: 'relative' }}>
                                        <canvas className="positionStatic canvasdrop" id="the-canvas" style={{ border: '1px  solid black', }}></canvas>
                                        <div id="overlay" className="artboard-grid displayNone" ></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></>)

}
export default withRouter(DragNDrop);
