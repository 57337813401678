import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean, showMessage } from '../../utility/AppUtility';
import { FileUpload } from 'primereact/fileupload';
import constant from './../../constant/Constant';
import rule from './../../constant/Rule';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import colors from './../../constant/colors';
import { ColorPicker } from 'primereact/colorpicker';
import { AppBreadcrumb } from '../../AppBreadcrumb';
import { InputSwitch } from "primereact/inputswitch";



var _ = require('lodash');

function GenerateToken(props) {



    var [obj] = useState({})
    var [generateObj, setGenerateObj] = useState({})
    var [byFile, setByFile] = useState(false)

    const growl = useRef(null)
    var [list, setList] = useState([])
    var [list1, setList1] = useState([])
    var [changeState, setChangeState] = useState(false)
    var [disableFile, setDisableFile] = useState(false)
    var [product, setProduct] = useState({})
    var [isBoxPacking, setIsBoxPacking] = useState(false);
    // var [guillocheImageList, setGuillocheImageList] = useState([])


    const [displayGenerateDialog, setDisplayGenerateDialog] = useState(false)


    const { handleSubmit, errors, control, reset, setValue } = useForm({ mode: "onBlur" });
    const httpService = new HttpService();


    useEffect(() => {
        getList()
        // getGuillocheList()

    }, [])
    const getList = () => {
        trackPromise(
            httpService.getApi('templates?sort=createdDate,desc').then((response) => {
                setList(response.data)
            }).catch((error) => {
                if (checkForError(error))
                    showMessage('error', 'Error Message', error.response.data.title, growl)
            }))
    }
    // const getGuillocheList = () => {
    //     trackPromise(
    //         httpService.getApi('guilloche-images').then((response) => {
    //             setGuillocheImageList(response.data)
    //         }).catch((error) => {
    //             if (checkForError(error))
    //                 showMessage('error', 'Error Message', error.response.data.title, growl)
    //         }))
    // }

    const onFileSelect = (e) => {
        setGenerateObj({ ...generateObj, file: e.files[0] });
        setValue('file', e.files[0], { shouldDirty: true })
        setDisableFile(true)
    }
    const onRemoveImg = () => {
        _.unset(generateObj, 'file');
        setValue('file', null)
        setDisableFile(false)
    }

    const handleChangeGenerate = (e) => {
        let val = e.target.value

        setGenerateObj({ ...generateObj, [e.target.name]: val });
        return e.target.value;
    }
    const deletes = (rowData) => {
        trackPromise(
            httpService.deleteApi('templates/' + rowData.id).then((response) => {
                getList()

            }).catch((error) => {
                if (checkForError(error)) {

                    showMessage('error', 'Error Message', error.response.data.title, growl)

                }
            }));
    }

    const onSelectObj = (e) => {

        setList1(e);
        // return e.target.value;
    }



    const clearGenerate = () => {
        setGenerateObj({ ...generateObj, quantity: '' })
        reset({
            quantity: '',
            description: ''
        }, {
            errors: false, // errors will not be reset 
            dirtyFields: false, // dirtyFields will not be reset
            dirty: false, // dirty will not be reset
            isSubmitted: false,
            touched: false,
            isValid: false,
            submitCount: false,
            data: false
        })
    }


    const generate = data => {
        let obj = { ...generateObj }
        obj = clean(obj)
        obj.templateId = obj.id;

        obj.fileName = product.productCode
        console.log(obj)
        _.unset(obj, 'id');
        let formData = new FormData()
        for (const property in obj) {
            if (property === "files") {
                if (obj[property] !== undefined)
                    obj[property].forEach(element => {
                        formData.append("file", element);
                    });

            } else
                if (obj[property] !== undefined)
                    formData.append(property, obj[property]);
        }
       formData.append("isBoxPacking", isBoxPacking)
        trackPromise(
            httpService.getApiMultipart
                ('template-histories', formData).then((response) => {
                    pringData(response.data.id)
                    setDisableFile(false)
                }).catch((error) => {
                    setDisableFile(false)
                    if (checkForError(error))
                        showMessage('error', 'Error Message', error.response.data.title, growl)
                }));

    }
    const pringData = (id) => {
        trackPromise(
            httpService.getApi
                ('template-histories/design/' + id).then((response) => {

                    clearGenerate()
                    setDisableFile(false)
                    setDisplayGenerateDialog(false)
                    setChangeState(!changeState)
                }).catch((error) => {
                    setDisableFile(false)
                    if (checkForError(error))
                        showMessage('error', 'Error Message', error.response.data.title, growl)
                }));
    }

    const setColors = (e) => {
        if (e.target.value.name == undefined) {
            validateColor(e.target.value)

        } else {
            validateColor(e.target.value.name)
        }

    }
    const validateColor = (color1) => {
        if (color1 == "" || color1 == undefined) {
            return;
        }
        color1 = color1?.toLowerCase();
        let c = window.w3color(color1);
        if (c.valid) {
            setGenerateObj({ ...generateObj, "guillocheColor": c.toHexString() });

        }
    }

    const generateFile = (edobj) => {
        setGenerateObj({ ...generateObj, ...edobj })
        console.log(edobj.product)
        setValue('product', edobj.product)
        reset({ ...edobj, 'productCode': edobj.product.productCode })
        setProduct({ ...edobj.product })

        console.log(edobj)
        setDisplayGenerateDialog(true)
    }
    const download = (rowData) => {
        var a = document.createElement('a');
        a.href = constant.MASTER_TEMPLATE_URl + rowData.name;
        a.download = rowData.name;
        a.target = '_blank';
        a.click();
        a.remove();
    }

    let actionHeader = <Button type="button" icon="pi-md-settings" />;
    const actionTemplate = (rowData, column) => {
        return <div style={{ width: "110px" }}>
            <Button type="button" tooltip="Delete" tooltipOptions={{ position: 'bottom' }} icon="pi-md-delete-forever" className="p-button-danger" onClick={() => deletes(rowData)} style={{ marginRight: '.5em' }} />

            <Button type="button" tooltip="Download" tooltipOptions={{ position: 'bottom' }} icon="pi-md-file-download" className="p-button-info" onClick={() => download(rowData)} style={{ marginRight: '.5em' }} />
            {rowData.status !== 'PENDING' && <Button type="button" tooltip="Generate Data" tooltipOptions={{ position: 'bottom' }} icon="pi-md-insert-drive-file" className="p-button-success" onClick={() => generateFile(rowData)} />}

        </div>;
    }

    const cancelGenerate = () => {
        setDisplayGenerateDialog(false)
    }



    let dialogFooterGenarate = <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="Cancel" icon="pi-md-clear" className="p-button-secondary" onClick={() => cancelGenerate()} />
        <Button label="Generate" icon="pi pi-check"
            onClick={handleSubmit(generate)} />
    </div>

    return <>

        <AppBreadcrumb breadcrumdItems={[{ label: 'Template Histories' }]} />


        <div className="p-grid">
            <div className="p-col-12">
                <Growl ref={growl} style={{ marginTop: '75px' }} />
                <div className=" card card-w-title datatable-demo">
                    <div className="p-col-12">
                        <font className="font21Weight500" >Template Histories</font>

                    </div>
                    <div className="p-col-12">
                    </div>
                    <div className="p-col-12">
                    </div>
                    <DataTable value={list} selectionMode="single" header="List of template" paginator={true} rows={10}
                        responsive={true} selection={list1} onSelectionChange={event => onSelectObj(event)} resizableColumns={true} columnResizeMode="fit">
                        <Column field="description" header="Template Code" sortable={true} filter={true} filterPlaceholder="Contains" />
                        <Column field="customer.companyName" header="Company Name" sortable={true} filter={true} filterPlaceholder="Contains" />
                        <Column field="customer.name" header="Contact person" sortable={true} filter={true} filterPlaceholder="Contains" />
                        <Column field="status" header="Status" sortable={true} filter={true} filterPlaceholder="Contains" />
                        <Column header={actionHeader} body={actionTemplate} style={{ textAlign: 'center', width: '9em' }} />
                    </DataTable>
                </div>
            </div>

            <Dialog id="editServiceDialog" className="positionAbs" visible={displayGenerateDialog} header={"Generate Data"} modal={true} footer={dialogFooterGenarate} onHide={() => cancelGenerate()}>
                {
                    <>

                        <div className="p-grid p-fluid form-group">
                            <div className="p-field-radiobutton">
                                <RadioButton inputId="System" name="city" value={false} onChange={(e) => setByFile(e.value)} checked={!byFile} />
                                <label htmlFor="System">System</label>
                            </div>
                            <div className="p-field-radiobutton">
                                <RadioButton inputId="File" name="city" value={true} onChange={(e) => setByFile(e.value)} checked={byFile} />
                                <label htmlFor="File">File</label>
                            </div>

                            {!byFile && <div className="p-col-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                    <span className="md-inputfield">
                                        <Controller className={errors.quantity ? "p-error" : ''} name="quantity" type="number" value={generateObj.quantity} rules={{
                                            ...rule.RULE.requiredRule,
                                            ...rule.RULE.maxLengthRule7,
                                            ...rule.RULE.minRuleVal1
                                        }} as={InputText} control={control} onChange={(e) => handleChangeGenerate(e[0])} />
                                        <label >Quantity</label>
                                    </span>

                                </div>
                                <span className="errorSpan merginLeft30">{errors.quantity?.message}</span>

                            </div>
                            }
                            <div className="p-col-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">account_circle</i></span>
                                    <span className="md-inputfield">
                                        <Controller className={errors.poNumber ? "p-error" : ''} name="poNumber" type="text" value={generateObj.poNumber} rules={{
                                        }} as={InputText} control={control} onChange={(e) => handleChangeGenerate(e[0])} />
                                        <label >PO NUmber</label>
                                    </span>

                                </div>
                                <span className="errorSpan merginLeft30">{errors.poNumber?.message}</span>

                            </div>
                            {byFile &&
                                <div className="p-col-12">
                                    <div className="p-inputgroup">
                                        <Controller style={{ width: "400px !important" }} className={errors.file ? "p-error" : ''} disabled={disableFile} rules={{ ...rule.RULE.requiredRule }} as={FileUpload} control={control} name="file"
                                            onSelect={e => onFileSelect(e)} accept=".csv,text/csv" onRemove={() => onRemoveImg()} maxFileSize={10000000000} customUpload={true} />
                                    </div>
                                    <span className="errorSpan merginLeft30">{errors.file?.message}</span>
                                </div>}
                            {byFile && <div className="p-col-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                    <span className="md-inputfield">
                                        <Controller className={errors.qrCodeIndex ? "p-error" : ''} name="qrCodeIndex" type="number" value={generateObj.qrCodeIndex} rules={{

                                            ...rule.RULE.maxLengthRule7,
                                            ...rule.RULE.minRuleVal1
                                        }} as={InputText} control={control} onChange={(e) => handleChangeGenerate(e[0])} />
                                        <label >QR Code Index</label>
                                    </span>

                                </div>
                                <span className="errorSpan merginLeft30">{errors.qrCodeIndex?.message}</span>

                            </div>
                            }
                            {byFile && <div className="p-col-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                    <span className="md-inputfield">
                                        <Controller className={errors.codeIndex ? "p-error" : ''} name="codeIndex" type="number" value={generateObj.codeIndex} rules={{
                                            ...rule.RULE.requiredRule,
                                            ...rule.RULE.maxLengthRule7,
                                            ...rule.RULE.minRuleVal1
                                        }} as={InputText} control={control} onChange={(e) => handleChangeGenerate(e[0])} />
                                        <label >Code Index</label>
                                    </span>

                                </div>
                                <span className="errorSpan merginLeft30">{errors.codeIndex?.message}</span>

                            </div>
                            }
                            {byFile && (<><div className="p-col-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                    <span className="md-inputfield">
                                        <Controller className={errors.textIndex ? "p-error" : ''} name="textIndex" type="number" value={generateObj.textIndex} rules={{

                                            ...rule.RULE.maxLengthRule7,
                                            ...rule.RULE.minRuleVal1
                                        }} as={InputText} control={control} onChange={(e) => handleChangeGenerate(e[0])} />
                                        <label >Text 1 Index</label>
                                    </span>

                                </div>
                                <span className="errorSpan merginLeft30">{errors.textIndex?.message}</span>

                            </div>
                                <div className="p-col-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                        <span className="md-inputfield">
                                            <Controller className={errors.text2Index ? "p-error" : ''} name="text2Index" type="number" value={generateObj.text2Index} rules={{

                                                ...rule.RULE.maxLengthRule7,
                                                ...rule.RULE.minRuleVal1
                                            }} as={InputText} control={control} onChange={(e) => handleChangeGenerate(e[0])} />
                                            <label >Text 2 Index</label>
                                        </span>

                                    </div>
                                    <span className="errorSpan merginLeft30">{errors.text2Index?.message}</span>

                                </div>
                                <div className="p-col-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                        <span className="md-inputfield">
                                            <Controller className={errors.text3Index ? "p-error" : ''} name="text3Index" type="number" value={generateObj.text3Index} rules={{

                                                ...rule.RULE.maxLengthRule7,
                                                ...rule.RULE.minRuleVal1
                                            }} as={InputText} control={control} onChange={(e) => handleChangeGenerate(e[0])} />
                                            <label >Text 3 Index</label>
                                        </span>

                                    </div>
                                    <span className="errorSpan merginLeft30">{errors.text3Index?.message}</span>

                                </div>
                            </>)
                            }
                            {!byFile &&
                                <> <div className="p-col-12">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon"><i className="material-icons">format_size</i></span>
                                        <span className="md-inputfield">
                                            <Controller className={errors.text1 ? "p-error" : ''} name="text1" value={generateObj.text1} rules={{
                                                ...rule.RULE.maxLengthRuleVal250

                                            }} as={InputText} control={control} onChange={(e) => handleChangeGenerate(e[0])} />
                                            <label >Text 1 </label>
                                        </span>
                                    </div>
                                </div>
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon"><i className="material-icons">format_size</i></span>
                                            <span className="md-inputfield">
                                                <Controller className={errors.text1 ? "p-error" : ''} name="text2" value={generateObj.text2} rules={{
                                                    ...rule.RULE.maxLengthRuleVal250

                                                }} as={InputText} control={control} onChange={(e) => handleChangeGenerate(e[0])} />
                                                <label >Text 2</label>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="p-col-12">
                                        <div className="p-inputgroup">
                                            <span className="p-inputgroup-addon"><i className="material-icons">format_size</i></span>
                                            <span className="md-inputfield">
                                                <Controller className={errors.text1 ? "p-error" : ''} name="text3" value={generateObj.text3} rules={{
                                                    ...rule.RULE.maxLengthRuleVal250

                                                }} as={InputText} control={control} onChange={(e) => handleChangeGenerate(e[0])} />
                                                <label >Text 3</label>
                                            </span>
                                        </div>
                                    </div>

                                </>}
                            <div className="p-col-12">
                                <div className="p-inputgroup" >
                                    {/* <div className="p-col-12 displayFlex">
                               <div className="p-col-9"> */}
                                    <span className="md-inputfield">
                                        <span className="p-inputgroup-addon"><i className="material-icons">business</i></span>
                                        <Dropdown placeholder="Guilloche Color" style={{ width: "100%" }} optionLabel={"name"} filter filterBy="name" optionValue={"name"} editable options={colors} className={errors.column ? "p-error" : ''} name="guillocheColor" value={generateObj.guillocheColor} rules={{}} as={Dropdown} control={control} onChange={(e) =>
                                            setColors(e)} />
                                    </span>
                                    {/* </div>
                               <div className="p-col-3"> */}
                                    <ColorPicker format="hex" name="guillocheColor" style={{ width: "40px", marginLeft: "40px" }} value={generateObj.guillocheColor} onChange={(e) => setColors(e)} />

                                    {/* </div>
                                </div> */}


                                </div>
                                <span className="errorSpan merginLeft30">{errors.guillocheImageId?.message}</span>
                            </div>
                            {/* <div className="p-col-12">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">format_size</i></span>
                                <span className="md-inputfield">
                                    <Controller disabled className={errors.fileName ? "p-error" : ''} name="fileName" value={product.productCode} rules={{
                                        ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal200,
                                        ...rule.RULE.minRuleVal1
                                    }} as={InputText} control={control} onChange={(e) => handleChangeGenerate(e[0])} />
                                    <label >Product Name</label>

                                </span>

                            </div>
                            <span className="errorSpan merginLeft30">{errors.fileName?.message}</span>

                        </div> */}

                            <div className="p-col-12">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">account_circle</i></span>
                                    <span className="md-inputfield">
                                        <Controller disabled className={errors.productCode ? "p-error" : ''} name="productCode" value={product.productCode} rules={{ ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal100 }} as={InputText} control={control} />
                                        <label >Product</label>
                                    </span>
                                </div>
                                <span className="errorSpan merginLeft30">{errors.productCode?.message}</span>
                            </div>
                        </div></>    
                }
                <div className="p-col-8">
                <div className="p-inputgroup">
                  <InputSwitch
                    checked={isBoxPacking}
                    name="isBoxPacking"
                    inputId={isBoxPacking}
                    ariaLabelledBy={isBoxPacking}
                    value={isBoxPacking}
                    onChange={() => setIsBoxPacking(!isBoxPacking)}
                  />
                  <label
                    htmlFor={isBoxPacking}
                    className="p-checkbox-label merginLeft15"
                  >
                    Enable BoxPackaging
                  </label>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.isBoxPacking?.message}
                </span>
              </div>
            </Dialog>
        </div></>

}
export default withRouter(GenerateToken);
