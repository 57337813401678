import React, { Component } from 'react';
import LoginFormFunction from './LoginHookForm';

export default class Login extends Component {
	
	
	render() {
		return <LoginFormFunction/>
	}
		
		
}