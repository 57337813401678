import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import CheckPriviladge from '../../hoc/CheckPriviladge';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean } from '../../utility/AppUtility';
import constant from './../../constant/Constant';
import { Dialog } from 'primereact/dialog';
import { PriviladgeContext } from './../../context/priviladge-context'
import { Dropdown } from 'primereact/dropdown';
import { AppBreadcrumb } from '../../AppBreadcrumb';


export class RollEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            obj: {},
            roll: [],
            rollGroup: [],
            companyInfo: [],
            displayConfirmation: false
        };
        this.jsxx = []
        this.httpService = new HttpService();
        this.id = '';
        if (props === undefined || props.location === undefined || props.location.state === undefined || props.location.state.id === undefined) {
            props.history.push('label_production_list')
        } else {
            this.id = props.location.state.id;
        }
    }
    static contextType = PriviladgeContext

    componentDidMount() {
        this.getById();

    }
    getById = () => {
        trackPromise(
            this.httpService.getApi('roll-details/' + this.id).then((response) => {
                let temp = response.data;
                temp.up = response.data.companyInfo.up;
                temp.companyInfo = { "id": response.data.companyInfo.id }
                this.setState({ obj: temp })
                this.setState({ roll: temp.rolls })
            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            }));
    }


    handleChange = (e) => {
        let val = e.target.value;
        if (e.target.name === "companyInfo") {
            this.setState({ obj: { ...this.state.obj, [e.target.name]: val, 'totalQuantity': val.quantity, 'rollQuantity': val.noOfLabelPerRoll, 'up': val.up } })
            this.createRoll(val.quantity, val.noOfLabelPerRoll)
        } else {
            this.setState({ obj: { ...this.state.obj, [e.target.name]: val } })
        }

    }
    rollhandler = (e, index1, name, row, col) => {
        let index = e.target.dataset.counter;
        const tempRoll = [...this.state.roll];
        const obj = { ...tempRoll[index] };
        obj[name] = e.target.value;
        obj["rollGroupNo"] = col;
        tempRoll[index] = obj;
        this.setState({ roll: tempRoll })

        let tempRollGroup = [...this.state.rollGroup];
        let tempRowRollGroup = tempRollGroup[row];

        if (tempRowRollGroup === undefined || tempRowRollGroup.lenth == 0) {
            tempRowRollGroup = [];
        }
        tempRowRollGroup[col] = obj;
        tempRollGroup[row] = tempRowRollGroup

        this.setState({ rollGroup: tempRollGroup })

    }
    createRoll = (totalQuantity, rollQuantity) => {
        var rolls = parseInt(totalQuantity) / parseInt(rollQuantity);
        rolls = Math.floor(rolls)
        if (parseInt(totalQuantity) % parseInt(rollQuantity) !== 0) {
            rolls = rolls + 1;
        }
        let myarray = [];
        for (let index = 0; index < rolls; index++) {
            myarray.push({ startingToken: null, endingToken: null })
        }
    }







    download = (e) => {
        let fileName = e.target.dataset.filename;
        var a = document.createElement('a');
        a.href = constant.TEMPLATE_URl + fileName;
        a.download = fileName;
        a.target = '_blank';
        a.click();
        a.remove();
    }

    save = (status) => {

        let obj = clean(this.state.obj)
        obj["rolls"] = clean(this.state.roll);
        if (status === 'Close') {
            obj["productionStatus"] = "CLOSE"
        }

        trackPromise(
            this.httpService.putApi('roll-details', obj).then((response) => {
                this.cancel();
            }).catch((error) => {
                if (checkForError(error)) {
                    this.showMessage('error', 'Error Message', error.response.data.title)
                }
            }));
    }
    reopen=()=>{
        trackPromise(
            this.httpService.getApi('roll-details/reopen/'+this.id ).then((response) => {
                this.cancel();
            }).catch((error) => {
                if (checkForError(error)) {
                    this.showMessage('error', 'Error Message', error.response.data.title)
                }
            }));
    }
    showConfirmDisplay = () => {
        this.setState({ displayConfirmation: true })
    }
    hideConfirmDisplay = () => {
        this.setState({ displayConfirmation: false })
    }
    showMessage(severity, summary, message) {
        let msg = { severity: severity, summary: summary, detail: message };
        this.growl.show(msg);
    }

    cancel = () => {
        this.props.history.push('label_production_list')
    }

    actionTemplate = (rowData, column) => {
        return <div style={{ width: "120px", "marginLeft": "-9px" }}>
            {rowData.status !== 'RUNNING' ? <>
                <Button type="button" label="Excel" tooltip="Excel" tooltipOptions={{ position: 'bottom' }} className="p-button-info" onClick={() => this.download(rowData, 'xlsx')} />
                <Button type="button" label="Csv" tooltip="Csv" tooltipOptions={{ position: 'bottom' }} className="p-button-info" onClick={() => this.download(rowData, 'csv')} /></>
                : null}
        </div>;
    }
    renderFooter() {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => this.hideConfirmDisplay()} className="p-button-text" />
                <Button label="Yes" icon="pi pi-check" onClick={() => this.save('Close')} autoFocus />
            </div>
        );
    }
    render() {

        var rolls = parseInt(this.state.obj.totalQuantity) / parseInt(this.state.obj.rollQuantity);
        rolls = Math.floor(rolls)
        if (parseInt(this.state.obj.totalQuantity) % parseInt(this.state.obj.rollQuantity) !== 0) {
            rolls = rolls + 1;
        }
        let totalInput = 1;

        if (this.state.obj.rollGenerated !== null && this.state.obj.rollGenerated !== undefined && this.state.obj.rollGenerated != 0) {
            totalInput = parseInt(this.state.obj.rollGenerated) / parseInt(this.state.obj.up)
            if (parseInt(this.state.obj.rollGenerated) % parseInt(this.state.obj.up) !== 0) {
                totalInput = totalInput + 1;
            } else if (parseInt(this.state.obj.rollGenerated) % parseInt(this.state.obj.up) == 0) {
                totalInput = totalInput + 1;
            }
            totalInput = parseInt(totalInput)
        }
        let masterArray = []
        let counter = 0;
        let counter1 = 0;
        let counter2 = 0;
        let counterIndex = 0;
        let counterIndex1 = 0;
        let counterIndex2 = 0;
        for (let row = 0; row < totalInput; row++) {
            let subArray = []
            for (let col = 0; (col < this.state.obj.up) && counter < rolls; col++) {
                let oneElement = <div className="p-col-3 dlsplayInlineBlock">
                    <div className="p-inputgroup">
                        <span className="md-inputfield">
                            <InputText name={"startingToken"} disabled={this.state.roll[(counterIndex)]?.fileName} data-counter={counterIndex} value={this.state.roll[(counterIndex)]?.startingToken} onChange={(e) => this.rollhandler(e, (counterIndex), 'startingToken', row, col)} />
                            <label >Start Token </label>
                        </span>
                    </div>
                </div>

                subArray.push(oneElement)
                counter = counter + 1;
                counterIndex = counterIndex + 1;
            }
            let mydiv = <div className="p-col-12">{subArray}</div>

            subArray = []
            for (let col = 0; (col < this.state.obj.up) && counter1 < rolls; col++) {
                let oneElement = <div className="p-col-3 dlsplayInlineBlock">
                    <div className="p-inputgroup">
                        <span className="md-inputfield">
                            <InputText name={"endingToken"} disabled={this.state.roll[(counterIndex1)]?.fileName} data-counter={counterIndex1} value={this.state.roll[(counterIndex1)]?.endingToken} onChange={(e) => this.rollhandler(e, (counterIndex1), 'endingToken', row, col)} />
                            <label >End Token </label>
                        </span>
                    </div>
                </div>


                subArray.push(oneElement)
                counter1 = counter1 + 1;
                counterIndex1 = counterIndex1 + 1;
            }

            let mydiv2 = <div className="p-col-12">{subArray}</div>

            subArray = []
            for (let col = 0; (col < this.state.obj.up) && counter2 < rolls; col++) {
                let oneElement = <>{
                    this.state.roll[(counterIndex2)]?.status ?
                        this.state.roll[(counterIndex2)]?.status === 'RUNNING' ?
                            <div className="p-col-3 dlsplayInlineBlock">
                                {this.state.roll[(counterIndex2)]?.status}
                            </div> : <div className="p-col-3 dlsplayInlineBlock">
                                <button label="Download" data-filename={this.state.roll[(counterIndex2)]?.fileName} onClick={(e) => this.download(e)}>Download </button>
                            </div> : <div className="p-col-3 dlsplayInlineBlock">
                        </div>
                }</>

                subArray.push(oneElement)
                counter2 = counter2 + 1;
                counterIndex2 = counterIndex2 + 1;
            }
            let mydivstatus = <div className="p-col-12">{subArray}</div>
            let mydiv3 = <> <div className="p-col-12">{mydiv}{mydiv2}{mydivstatus}</div>

                <hr /></>
            masterArray.push(mydiv3)

        }
        this.jsxx = masterArray;

        return <>
                        <AppBreadcrumb breadcrumdItems={[{ label: 'Label Production List',url:"#label_production_list" },{ label: 'Label Production Edit' }]} />

         <div className="p-grid">
            <div className="p-col-12">
                <Growl ref={(el) => this.growl = el} style={{ marginTop: '75px' }} />

                <div className="p-col-8 ">
                    <div className="card card-w-title">
                        <h1>Edit Label Production</h1>
                        <div className="p-grid p-fluid form-group">
                            <div className="p-col-8">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                    <span className="md-inputfield">
                                        <InputText name="description" value={this.state.obj.description} onChange={(e) => this.handleChange(e)} />
                                        <label >Description</label>
                                    </span>
                                </div>
                                {/* <span className="errorSpan merginLeft30">{errors.description?.message}</span> */}
                            </div>

                            <div className="p-col-8">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">business</i></span>
                                    <span className="md-inputfield">
                                        <InputText disabled={true} name="totalQuantity" value={this.state.obj.totalQuantity} onChange={(e) => this.handleChange(e)} />
                                        <label >Total Quantity</label>
                                    </span>
                                </div>
                                {/* <span className="errorSpan merginLeft30">{errors.totalQuantity?.message}</span> */}
                            </div>
                            <div className="p-col-8">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">account_circle</i></span>
                                    <span className="md-inputfield">
                                        <InputText disabled={true} name="rollQuantity" value={this.state.obj.rollQuantity} onChange={(e) => this.handleChange(e)} />
                                        <label >Roll Quantity</label>
                                    </span>
                                </div>
                                {/* <span className="errorSpan merginLeft30">{errors.rollQuantity?.message}</span> */}
                            </div>



                            {
                                this.jsxx.map((lion, index) => {
                                    return lion
                                })}





                        </div>
                        <div className="p-col-12">
                            {
                              (this.state.obj.productionStatus == 'CLOSE' && this.context.isAdmin) &&  <Button icon="pi pi-check" label="Reopen Label Production" onClick={() => this.reopen()} />
                            }
                            <CheckPriviladge priviladge={{ name: "Label Production", priority: 2 }}>
                                {this.state.obj.productionStatus !== 'CLOSE' && <Button icon="pi pi-check" label="Save" onClick={() => this.save('')} />}
                            </CheckPriviladge>
                            <CheckPriviladge priviladge={{ name: "Label Production", priority: 3 }}> {this.state.obj.productionStatus !== 'CLOSE' && <>
                                <Button icon="pi pi-check" label="Close Label Production" onClick={() => this.showConfirmDisplay()} /></>}
                            </CheckPriviladge>

                        </div>
                        <Dialog header="Confirmation" visible={this.state.displayConfirmation} modal style={{ width: '350px' }} footer={this.renderFooter()} onHide={() => this.hideConfirmDisplay()}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                <span>Are you sure you want to close label production?</span>
                            </div>
                        </Dialog>



                    </div>

                </div>
                <div className="p-col-4"></div>
            </div>
        </div></>
    }
}