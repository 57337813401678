import { Grid, Input } from '@material-ui/core';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import React from 'react';
import { AppBreadcrumb } from '../AppBreadcrumb';
import { HttpService } from '../service/HttpService';
import { Button } from 'primereact/button';
import { Component } from 'react';
import { checkForError } from "../utility/AppUtility";
import { withRouter } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import Constant from '../constant/Constant';


class SearchQrCodeList extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue: [],
            page: 1,
            first: 0,
            totalRecords: 0,
            size: 10,
            expandedRows: null,
            qrCode: '',
            customerId: '',
            openModel: '',
            customerList: [],
            type: "Customer",
            val1: Boolean,
            searchKey: "",
            searchColumn: "",
            isSearch: false
        }
        let actionHeader = <Button type="button" icon="pi-md-settings" />;
        this.httpService = new HttpService();
    }



    showMessage(severity, summary, message) {
        let msg = { severity: severity, summary: summary, detail: message };
        this.growl.show(msg);
    }
    style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        p: 4,
    };


    componentDidMount() {
        this.setState({ val1: false })
        this.getSearchList(this.state.page - 1);
    }

    getSearchList = (pageNo) => {
        console.log(pageNo)
        this.httpService.getApi(`qrCode/get_list?page=${pageNo}&size=${this.state.size}&sort=createdDate,desc`).then(response => {
            this.setState({
                dataTableValue: response.data.content,
                totalRecords: response.data.totalElements
            })
            console.log(response.data.content)
        }).catch((error) => {
            if (checkForError(error))
                this.showMessage(
                    "error",
                    "Error Message",
                    error.response.data.title
                );
        })
    }



    onPageChange = (event) => {
        this.setState({
            page: event.page,
            first: event.first,
        })
        if (this.state.isSearch) {
            this.customFilter(this.state.searchKey, this.state.searchColumn, event.page)
        } else {
            this.getSearchList(event.page)
        }
    }

    getCustomerList = () => {
        this.httpService.getApi(`customers/type?type=${this.state.type}`).then(response => {
            this.setState({
                customerList: response.data,
                openModel: true
            })
        })
    }

    searchQrCode = () => {
        this.httpService.getApi(`qrCode/search?qrCode=${this.state.qrCode}&customerId=${this.state.customerId}`).then(response => {
            this.setState({
                openModel: false,
            })
            this.getCustomerList()
        })
    }

    search = () => {
        this.props.history.push("/search");
    }
    check(value) {
        return (this.state.dataTableValue[1]?.qrCode?.includes(value))
    }

    customFilter(searchValue, columnName, pageNo) {
        if (searchValue == "") {
            this.setState({ isSearch: false })
            this.getSearchList(0);
        } else {
            this.setState({
                searchKey: searchValue,
                searchColumn: columnName,
                isSearch: true
            })
            this.httpService.searchApi(`qrCode/contains?column=${columnName}&searchKey=${searchValue}&page=${pageNo}&size=${this.state.size}`).then((response) => {
                this.setState({ dataTableValue: response.data.content, totalRecords: response.data.totalElements, page: 0 });
            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            })
        }

    }

    renderYearElements(columnName) {
        return (
            <React.Fragment>
                <InputText onChange={(e) => this.customFilter(e.target.value, columnName,0)} placeholder="Contains" ></InputText>
            </React.Fragment>
        );
    }
    download = (rowData) => {
        var a = document.createElement('a');
        // a.href = Constant.MASTER_TEMPLATE_URl + rowData.name;
        a.href = Constant.TEMPLATE_URl + rowData.qrCodeFileName
        a.download = rowData.qrCodeFilePath;
        a.target = '_blank';
        a.click();
        a.remove();
    }

    actionTemplate = (rowData, column) => {
        return <div style={{ width: "110px" }}>
            {rowData.status == 'COMPLETED' &&
                <Button type="button" tooltip="Download" tooltipOptions={{ position: 'bottom' }} icon="pi-md-file-download" className="p-button-info" onClick={() => this.download(rowData)} style={{ marginRight: '.5em' }} />
            }
        </div>;
    }


    render() {

        const header = (
            <>
                <font>Qr Code Search List</font>
                <br></br>

                <div className="merginTop10">
                </div>
            </>
        );


        return (
            <>
                <AppBreadcrumb breadcrumdItems={[{ label: "QrCode Search" }]} />
                <div className="p-grid">
                    <div className="p-col-12">
                    <Growl ref={(el) => this.growl = el} style={{ marginTop: '75px' }} />
                        <div className=" datatable-demo">
                            <Grid container>
                                <div className="p-col-6">
                                    <font className="font21Weight500">QrCode Search History</font>
                                </div>
                                <div className="p-col-6" dir='rtl'>
                                    {
                                        // () => this.getCustomerList()
                                    }
                                    <Button type="button" icon='pi pi-search' label='Search' onClick={() => this.search()} />
                                </div>
                            </Grid>
                            <div className="p-col-12"></div>

                            <DataTable
                                ref={(el) => (this.dt = el)}
                                value={this.state.dataTableValue}
                                header={header}
                                lazy
                                paginator
                                rows={10}
                                totalRecords={this.state.totalRecords}
                                expandedRows={this.state.expandedRows}
                                dataKey="data.id"
                                onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                                responsive={true}
                                resizableColumns={true}
                                columnResizeMode="fit"
                                onPage={(event) => this.onPageChange(event)}
                                first={this.state.first}
                                emptyMessage="NOT FOUND"
                            >

                                <Column
                                    field="qrCode"
                                    header="Qr Code"
                                    headerStyle={{ fontWight: "600" }}
                                    sortable={true}
                                    filter={true}
                                    filterElement={this.renderYearElements("qrCode")}
                                    filterMatchMode="custom"
                                    filterPlaceholder="Contains"
                                />

                                <Column
                                    filterElement={this.renderYearElements("customerId")}
                                    field="customerName"
                                    header="Customer Name"
                                    headerStyle={{ fontWight: "600" }}
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="Contains"
                                />

                                <Column
                                    filterElement={this.renderYearElements("qrCodeCreatedBy")}
                                    field="qrCodeCreatedBy"
                                    header="Created By"
                                    headerStyle={{ fontWight: "600" }}
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="Contains"
                                />
                                <Column
                                    filterElement={this.renderYearElements("qrCodeCreatedDate")}
                                    field="qrCodeCreatedDate"
                                    header="Created Date"
                                    headerStyle={{ fontWight: "600" }}
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="Contains"
                                />

                                <Column
                                    filterElement={this.renderYearElements("status")}
                                    field="status"
                                    header="Status"
                                    headerStyle={{ fontWight: "600" }}
                                    sortable={true}
                                    filter={true}
                                    filterPlaceholder="Contains"
                                />
                                <Column header={this.actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '9em' }} />

                                {/* <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} /> */}
                            </DataTable>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default withRouter(SearchQrCodeList)