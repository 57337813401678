import { async } from 'regenerator-runtime';
import Constant from './../constant/Constant'
const axios = require('axios').default;
const contendType = { 'Content-Type': 'application/json' };

export class HttpService {

    getAuthorization() {
        return { 'Authorization': 'Bearer ' + localStorage.getItem("token") }
    }
    getHeader() {
        return { ...contendType, ...this.getAuthorization() }
    }

    login(url, body) {
        return axios.post(Constant.BASEURl + url, body, { headers: contendType })
    }
    logout() {
        return axios.get(Constant.LOGOUT, { headers: contendType })
    }
    getApi(url, params) {
        return axios.get(Constant.BASEURl + url,
            { params: params, headers: this.getHeader() }
        )
    }

    searchApi = async (url) => {
        return await axios.get(Constant.BASEURl + url, { headers: this.getHeader() })
    }



    postApi(url, body, params) {
        return axios.post(Constant.BASEURl + url, body, { params: params, headers: this.getHeader() })
    }
    postApiForCheck(url, body) {
        return axios.post(Constant.BASEURl + url, body, { headers: contendType })
    }

    sentOtp(body) {
        return axios.post(Constant.BASEURl + 'sendOtp', body, { headers: { 'Content-Type': 'application/json', 'sendotpkey': '$2a$04$Tfod0GnOxyEEg25b61i6.OSAMhKGTDyVD1OQuek7FuNjkDMe.0bqK' } })
    }
    sentOtp1(body) {
        return axios.post(Constant.BASEURl + 'account/sendOtp', body, { headers: { 'Content-Type': 'application/json', 'sendotpkey': '$2a$04$Tfod0GnOxyEEg25b61i6.OSAMhKGTDyVD1OQuek7FuNjkDMe.0bqK' } })
    }
    resetPassowrd(body) {
        return axios.post(Constant.BASEURl + 'account/reset-password', body, { headers: { 'Content-Type': 'application/json', 'resetPasswordKey': '$2a$04$vF9G1hm3tlaTXYq4ugNveuQkf8Fib7qRD3b93wL8zzAKWsxZ.6fY2' } })
    }



    putApi(url, body) {
        return axios.put(Constant.BASEURl + url, body, { headers: this.getHeader() })
    }
    deleteApi(url, params) {
        return axios.delete(Constant.BASEURl + url,
            { params: params, headers: this.getHeader() }
        )
    }
    getApiMultipart(url, body) {
        return axios.post(Constant.BASEURl + url, body,
            { headers: this.getAuthorization() }
        )
    }
    getApiMultipartPut(url, body) {
        return axios.put(Constant.BASEURl + url, body,
            { headers: this.getAuthorization() }
        )
    }
}