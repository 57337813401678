import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import { withRouter } from "react-router-dom";
import { HttpService } from "../../../service/HttpService";
import { checkForError, clean, showMessage } from "../../../utility/AppUtility";
import rule from "../../../constant/Rule";
import { AppBreadcrumb } from "../../../AppBreadcrumb";
import { InputSwitch } from "primereact/inputswitch";

var _ = require("lodash");

var PackagingAdd = (props) => {
  var [obj, setObj] = useState({});
  const growl = useRef(null);
  var [packagingConfig, setpackagingConfig] = useState([]);
  var [enabled, setEnabled] = useState(false);
  var [products, setProducts] = useState([]);

  const { handleSubmit, errors, control, reset, setValue, watch } = useForm({
    mode: "onBlur",
  });
  const httpService = new HttpService();
  useEffect(() => {
    getProducts();
  }, []);


  const getProducts = () => {
    trackPromise(
      httpService
        .getApi("products/company")
        .then((response) => {
          setProducts(response.data);
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };

  const addPackagingLevel = () => {
    let pp = [...packagingConfig];
    pp.push({ name: null, count: null });

    setpackagingConfig(pp);
  };

  const handleChange = (e, type) => {
    let val = e.target.value;

    setObj({ ...obj, [e.target.name]: val });
    return val;
  };
  const handlePackagingLevelChange = (e, index, name) => {
    const temppackagingConfig = [...packagingConfig];
    const obj = { ...temppackagingConfig[index] };
    obj[name] = e.target.value;
    temppackagingConfig[index] = obj;
    setpackagingConfig(temppackagingConfig);
    return e.target.value;
  };

  const cancel = () => {
    props.history.push("packaging_list");
  };

  const save = (data) => {
    obj = clean(obj);

    let parentConfig = {
      name: obj.parentName,
      count: 1
    };
    let parent = parentConfig;
    for (let i = 0; i < packagingConfig.length; i++) {
      let packagingConfig1 = {};
      packagingConfig1.name = packagingConfig[i].name;
      packagingConfig1.count = packagingConfig[i].count;
      parent.child = packagingConfig1;
      parent = packagingConfig1;
    }
    let form = {
      name: obj.name,
      enabled: enabled,
      packagingConfig: parentConfig,
      product: obj.product
    };
    trackPromise(
      httpService
        .postApi("package/create", form)
        .then((response) => {
          cancel();
        })
        .catch((error) => {
          if (checkForError(error)) {
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
          }
        })
    );
  };

  const deleteProduct1 = (index) => {
    let pp = [...packagingConfig];
    pp.splice(index, 1);

    setpackagingConfig(pp);
  };

  return (
    <>
      <AppBreadcrumb
        breadcrumdItems={[
          { label: "Packaging List", url: "#packaging_list" },
          { label: "Packaging Add" },
        ]}
      />

      <div className="p-grid ">
        <Growl ref={growl} style={{ marginTop: "75px" }} />

        <div className="p-col-10 ">
          <div className="card card-w-title">
            <h1>Add Packaging</h1>
            <div className="p-grid p-fluid form-group">
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">account_circle</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.name ? "p-error" : ""}
                      name="name"
                      value={obj.name}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRuleVal100,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Packaging Name</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.name?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">account_circle</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.parentName ? "p-error" : ""}
                      name="parentName"
                      value={obj.parentName}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRuleVal100,
                      }}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Parent Level</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.parentName?.message}
                </span>
              </div>

              <div className="p-col-8">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="material-icons">shop</i>
                      </span>
                      <span className="md-inputfield">
                        <Controller
                          optionLabel="productCode"
                          optionValue="id"
                          filter
                          filterBy="productCode"
                          className={errors.product ? "p-error" : ""}
                          rules={{ ...rule.RULE.requiredRule }}
                          as={Dropdown}
                          control={control}
                          options={products}
                          placeholder={"Product"}
                          name="product"
                          value={obj.product}
                          onChange={(e) => handleChange(e[0])}
                        />
                      </span>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.product?.message}
                    </span>
                  </div>

              <>
                <div className="p-col-8">
                  <div className="p-col-3">
                    <Button
                      icon="pi pi-plus"
                      label="Add Child Level"
                      onClick={addPackagingLevel.bind(this)}
                    />
                  </div>
                </div>

                {packagingConfig.map((pp, index) => {
                  return (
                    <div className="p-col-8">

                      <div className="p-col-5 dlsplayInlineBlock">
                        <div className="p-inputgroup">
                          <span className="md-inputfield">
                            <Controller
                              className={
                                errors?.packagingConfig?.[index]
                                  ?.name
                                  ? "p-error"
                                  : ""
                              }
                              name={
                                "packagingConfig[" +
                                index +
                                "].name"
                              }
                              type="text"
                              value={
                                packagingConfig[index]?.name
                              }
                              rules={{
                                ...rule.RULE.requiredRule,
                                ...rule.RULE.maxLengthRuleVal100,
                              }}
                              as={InputText}
                              control={control}
                              onChange={(e) =>
                                handlePackagingLevelChange(
                                  e[0],
                                  index,
                                  "name"
                                )
                              }
                            />
                            <label>Name</label>
                          </span>
                        </div>
                        <span className="errorSpan merginLeft30">
                          {
                            errors?.packagingConfig?.[index]
                              ?.name?.message
                          }
                        </span>
                      </div>

                      <div className="p-col-5 dlsplayInlineBlock">
                        <div className="p-inputgroup">
                          <span className="md-inputfield">
                            <Controller
                              className={
                                errors?.packagingConfig?.[index]
                                  ?.count
                                  ? "p-error"
                                  : ""
                              }
                              name={
                                "packagingConfig[" +
                                index +
                                "].count"
                              }
                              type="number"
                              value={
                                packagingConfig[index]?.count
                              }
                              rules={{
                                ...rule.RULE.requiredRule,
                                ...rule.RULE.maxLengthRule7,
                              }}
                              as={InputText}
                              control={control}
                              onChange={(e) =>
                                handlePackagingLevelChange(
                                  e[0],
                                  index,
                                  "count"
                                )
                              }
                            />
                            <label>Count</label>
                          </span>
                        </div>
                        <span className="errorSpan merginLeft30">
                          {
                            errors?.packagingConfig?.[index]
                              ?.count?.message
                          }
                        </span>
                      </div>
                      <div className="p-col-2 dlsplayInlineBlock">
                        <Button
                          icon="pi-md-delete"
                          onClick={deleteProduct1.bind(this, index)}
                        />
                      </div>
                    </div>
                  );
                })}
              </>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <InputSwitch
                    checked={enabled}
                    name={"enabled"}
                    inputId={enabled}
                    ariaLabelledBy={enabled}
                    value={enabled}
                    onChange={() => setEnabled(!enabled)}
                  />
                  <label
                    htmlFor={enabled}
                    className="p-checkbox-label merginLeft15"
                  >
                    Enable Packaging
                  </label>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.enabled?.message}
                </span>
              </div>
            </div>

            <div className="p-col-12">
              <Button
                icon="pi pi-check"
                label="Save"
                onClick={handleSubmit(save)}
              />
              <Button
                icon="pi-md-cancel"
                label="Cancel"
                className="p-button-secondary"
                onClick={() => cancel()}
              />
            </div>
            <div className="p-col-8"></div>
          </div>

        </div>

        <div className="p-col-4"></div>
      </div>
    </>
  );
}
export default withRouter(PackagingAdd);
