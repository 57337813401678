import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState ,useRef} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean ,showMessage } from '../../utility/AppUtility';
import ContectPersonAdd from './ContectPersonAdd'
import { TabView, TabPanel } from 'primereact/tabview';
import { AppBreadcrumb } from '../../AppBreadcrumb';

import rule from './../../constant/Rule';



function VendorEdit(props) {

    var [obj, setObj] = useState({})
    const growl = useRef(null)
    var [activeIndex, setActiveIndex] = useState(0)
    var [contectPersons, setContectPersons] = useState([])

    const { handleSubmit, errors, control, reset } = useForm({ mode: "onBlur" });
    const httpService = new HttpService();
    
    var id = '';
    if (props === undefined || props.location === undefined || props.location.state === undefined || props.location.state.id === undefined) {
        props.history.push('vendor_list')
    } else {
        id = props.location.state.id;
    }


    useEffect(() => {
        
        
        trackPromise(
            httpService.getApi('vendors/' + id).then((response) => {
                setObj({ ...response.data })
                setContectPersons(response.data.contectPersons)
                reset({ ...response.data })
            }).catch((error) => {
                if (checkForError(error))
                   showMessage('error', 'Error Message', error.response.data.title,growl)
            }));
    }, [id])



    const handleChange = (e, type) => {
        let val = e.target.value;

        setObj({ ...obj, [e.target.name]: val });
        return val;
    }

    
    const cancel = () => {
        props.history.push('vendor_list')
    }
    const saveContectPersons=data=>{
        obj = clean(obj)
        obj.contectPersons = data;
        trackPromise(
            httpService.putApi('vendors', obj).then((response) => {
                cancel()
               
            }).catch((error) => {
                if (checkForError(error))
                   showMessage('error', 'Error Message', error.response.data.title,growl)
            }));
    }

    const save = data => {
        obj = clean(obj)
        obj.contectPersons = contectPersons;
        trackPromise(
            httpService.putApi('vendors', obj).then((response) => {
                cancel();
            }).catch((error) => {
                if (checkForError(error))
                   showMessage('error', 'Error Message', error.response.data.title,growl)
            }));

    }





    return <>
            <AppBreadcrumb breadcrumdItems={[{ label: 'Vendor List',url:"#vendor_list" },{ label: 'Vendor Edit' }]} />

    
    <div className="p-grid ">
        <Growl ref={growl} style={{ marginTop: '75px' }} />

        <div className="p-col-10 ">
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="Vendor">
                    <div className="card card-w-title">
                        <h1>Edit Vendor</h1>
                        <div className="p-grid p-fluid form-group">

                            <div className="p-col-8">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">account_circle</i></span>
                                    <span className="md-inputfield">
                                        <Controller className={errors.name ? "p-error" : ''} name="name" value={obj.name} rules={{ ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal100 }} as={InputText} control={control} onChange={(e) => handleChange(e[0])} />
                                        <label >Name</label>
                                    </span>
                                </div>
                                <span className="errorSpan merginLeft30">{errors.name?.message}</span>
                            </div>
                            <div className="p-col-8">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">account_circle</i></span>
                                    <span className="md-inputfield">
                                        <Controller className={errors.address ? "p-error" : ''} name="address" value={obj.address} rules={{ ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal250 }} as={InputText} control={control} onChange={(e) => handleChange(e[0])} />
                                        <label >Address</label>
                                    </span>
                                </div>
                                <span className="errorSpan merginLeft30">{errors.address?.message}</span>
                            </div>


                        </div>
                        <div className="p-col-12">
                            <Button icon="pi pi-check" label="Save" onClick={handleSubmit(save)} />
                            <Button icon="pi-md-cancel" label="Cancel" className="p-button-secondary" onClick={() => cancel()} />

                        </div>
                        <div className="p-col-8">

                        </div>

                    </div>  
                    </TabPanel>
                <TabPanel header="Contact Person">
                    < ContectPersonAdd  setContectPersons={saveContectPersons} contectPersons={contectPersons} ></ContectPersonAdd>

                </TabPanel>
            </TabView>
        </div>

    </div></>

}
export default withRouter(VendorEdit);
