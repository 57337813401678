const COLORS=[
    { name:"AliceBlue"},
    { name:"AntiqueWhite"},
    { name:"Aqua"},
    { name:"Aquamarine"},
    { name:"Azure"},
    { name:"Beige"},
    { name:"Bisque"},
    { name:"Black"},
    { name:"BlanchedAlmond"},
    { name:"Blue"},
    { name:"BlueViolet"},
    { name:"Brown"},
    { name:"BurlyWood"},
    { name:"CadetBlue"},
    { name:"Chartreuse"},
    { name:"Chocolate"},
    { name:"Coral"},
    { name:"CornflowerBlue"},
    { name:"Cornsilk"},
    { name:"Crimson"},
    { name:"Cyan"},
    { name:"DarkBlue"},
    { name:"DarkCyan"},
    { name:"DarkGoldenRod"},
    { name:"DarkGray"},
    { name:"DarkGrey"},
    { name:"DarkGreen"},
    { name:"DarkKhaki"},
    { name:"DarkMagenta"},
    { name:"DarkOliveGreen"},
    { name:"DarkOrange"},
    { name:"DarkOrchid"},
    { name:"DarkRed"},
    { name:"DarkSalmon"},
    { name:"DarkSeaGreen"},
    { name:"DarkSlateBlue"},
    { name:"DarkSlateGray"},
    { name:"DarkSlateGrey"},
    { name:"DarkTurquoise"},
    { name:"DarkViolet"},
    { name:"DeepPink"},
    { name:"DeepSkyBlue"},
    { name:"DimGray"},
    { name:"DimGrey"},
    { name:"DodgerBlue"},
    { name:"FireBrick"},
    { name:"FloralWhite"},
    { name:"ForestGreen"},
    { name:"Fuchsia"},
    { name:"Gainsboro"},
    { name:"GhostWhite"},
    { name:"Gold"},
    { name:"GoldenRod"},
    { name:"Gray"},
    { name:"Grey"},
    { name:"Green"},
    { name:"GreenYellow"},
    { name:"HoneyDew"},
    { name:"HotPink"},
    { name:"IndianRed"},
    { name:"Indigo"},
    { name:"Ivory"},
    { name:"Khaki"},
    { name:"Lavender"},
    { name:"LavenderBlush"},
    { name:"LawnGreen"},
    { name:"LemonChiffon"},
    { name:"LightBlue"},
    { name:"LightCoral"},
    { name:"LightCyan"},
    { name:"LightGoldenRodYellow"},
    { name:"LightGray"},
    { name:"LightGrey"},
    { name:"LightGreen"},
    { name:"LightPink"},
    { name:"LightSalmon"},
    { name:"LightSeaGreen"},
    { name:"LightSkyBlue"},
    { name:"LightSlateGray"},
    { name:"LightSlateGrey"},
    { name:"LightSteelBlue"},
    { name:"LightYellow"},
    { name:"Lime"},
    { name:"LimeGreen"},
    { name:"Linen"},
    { name:"Magenta"},
    { name:"Maroon"},
    { name:"MediumAquaMarine"},
    { name:"MediumBlue"},
    { name:"MediumOrchid"},
    { name:"MediumPurple"},
    { name:"MediumSeaGreen"},
    { name:"MediumSlateBlue"},
    { name:"MediumSpringGreen"},
    { name:"MediumTurquoise"},
    { name:"MediumVioletRed"},
    { name:"MidnightBlue"},
    { name:"MintCream"},
    { name:"MistyRose"},
    { name:"Moccasin"},
    { name:"NavajoWhite"},
    { name:"Navy"},
    { name:"OldLace"},
    { name:"Olive"},
    { name:"OliveDrab"},
    { name:"Orange"},
    { name:"OrangeRed"},
    { name:"Orchid"},
    { name:"PaleGoldenRod"},
    { name:"PaleGreen"},
    { name:"PaleTurquoise"},
    { name:"PaleVioletRed"},
    { name:"PapayaWhip"},
    { name:"PeachPuff"},
    { name:"Peru"},
    { name:"Pink"},
    { name:"Plum"},
    { name:"PowderBlue"},
    { name:"Purple"},
    { name:"RebeccaPurple"},
    { name:"Red"},
    { name:"RosyBrown"},
    { name:"RoyalBlue"},
    { name:"SaddleBrown"},
    { name:"Salmon"},
    { name:"SandyBrown"},
    { name:"SeaGreen"},
    { name:"SeaShell"},
    { name:"Sienna"},
    { name:"Silver"},
    { name:"SkyBlue"},
    { name:"SlateBlue"},
    { name:"SlateGray"},
    { name:"SlateGrey"},
    { name:"Snow"},
    { name:"SpringGreen"},
    { name:"SteelBlue"},
    { name:"Tan"},
    { name:"Teal"},
    { name:"Thistle"},
    { name:"Tomato"},
    { name:"Turquoise"},
    { name:"Violet"},
    { name:"Wheat"},
    { name:"White"},
    { name:"WhiteSmoke"},
    { name:"Yellow"},
    { name:"YellowGreen"},
    ]
    export default COLORS