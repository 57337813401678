import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import React, { useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import '../App.css';
import { HttpService } from './../service/HttpService';
import { showMessage } from './../utility/AppUtility'
import moment from 'moment';

function LoginFormFunction(props) {

    var [username, setUsername] = useState("")
    var [password, setPassword] = useState("")
    var [emailMask, setEmailMask] = useState("")
    var [mobileMask, setMobileMask] = useState("")
    var [askForOtp, setAskForOtp] = useState(false)
    var [radioValue, setRadioValue] = useState("")
    var [otp, setOtp] = useState("")
    var [password, setPassword] = useState("")
    const growl = useRef(null)
    const httpService = new HttpService();
    const { handleSubmit, errors, control } = useForm({ mode: "onBlur" });

    const login = credencial => {
        httpService
            .login('authenticate', { "username": username, "password": password, "otp": otp, "sendOn": radioValue })
            .then((response) => {
                localStorage.setItem("token", response.data.id_token)
                userInfo();
            })
            .catch((error) => {
                showMessage('warn', 'Warn Message', error.response.data.title, growl)
            })
    }
    const getMask = credencial => {

        httpService
            .postApiForCheck('account/checklogin', { "username": credencial.username, "password": credencial.password })
            .then((response) => {
                setEmailMask(response.data.email)
                setMobileMask(response.data.mobileNo)
                if(response.data.idToken  !== undefined && response.data.idToken !== null){
                    localStorage.setItem("token", response.data.idToken)
                    const date = new Date();
                    const loginTime = moment(date).format("hh:mm A").toString();
                    localStorage.setItem("loginTime", loginTime);
                    userInfo();
                } else {
                    setAskForOtp(true);
                }
                // setAskForOtp(true)

            })
            .catch((error) => {
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined
                    && error.response.data.title !== undefined)
                    showMessage('error', 'Error Message', error.response.data.title, growl)
                else
                    showMessage('warn', 'Warn Message', 'Bad Credencial', growl)

            })
    }
    const sendOtp = (event) => {
        setRadioValue(event.value)
        httpService
            .sentOtp1({ "username": username, "password": password, "type": 'login', 'sendOn': event.value })
            .then((response) => {
            })
            .catch((error) => {
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined
                    && error.response.data.title !== undefined)
                    showMessage('error', 'Error Message', error.response.data.title, growl)
                else
                    showMessage('warn', 'Warn Message', 'Bad Credencial', growl)

            })
    }
    const userInfo = () => {

        httpService
            .getApi('account')
            .then((response) => {

                localStorage.setItem("user", JSON.stringify(response.data))
                props.history.push('/dashboard')
            })
            .catch((error) => {
                if (error !== undefined && error.response !== undefined && error.response.data !== undefined
                    && error.response.data.message !== undefined)
                    showMessage('error', 'Error Message', error.response.data.message, growl)

            })

    }


    const forgotPassowrd = () => {
        props.history.push('/forgotpassowrd')
    }
    return (<div className="login-body">

        <div className="login-type">
            <Growl ref={growl} style={{ marginTop: '75px' }} />


            <div className=" login-panel p-fluid">
                <div className="p-grid">
                    <div className="p-col-12 loginFont">
                        Login
                    </div>
                    {askForOtp ?
                        <>

                            <div className="p-col-12 textAlignLeft ">
                                <RadioButton value="EMAIL" inputId="rb1" onChange={event => sendOtp(event)} checked={radioValue === "EMAIL"} />
                                <label htmlFor="rb1" className="p-radiobutton-label">{emailMask}</label>
                            </div>
                            <div className="p-col-12 textAlignLeft">
                                <RadioButton value="Mobile" inputId="rb2" onChange={event => sendOtp(event)} checked={radioValue === "Mobile"} />
                                <label htmlFor="rb2" className="p-radiobutton-label">{mobileMask}</label>
                            </div>
                            <div className="p-col-12">

                                < label className="signinForm__input_label"> Enter OTP</label>

                                <Controller className={'signinForm__input'} as={InputText} type="text" id="otp" name="otp"
                                    rules={{ required: { value: true, message: "This field is required" }, maxLength: { value: 25, message: "Value must be less then 25" } }}
                                    value={otp} onChange={(event) => { setOtp(event[0].target.value); return event[0].target.value }}
                                    control={control} />
                                <span className="errorSpan">{errors.otp?.message}</span>
                            </div>

                        </> :
                        <>
                            <div className="p-col-12">

                                < label className="signinForm__input_label"> Username</label>

                                <Controller className={'signinForm__input'} as={InputText} type="text" id="username" name="username"
                                    rules={{ required: { value: true, message: "This field is required" }, maxLength: { value: 50, message: "Value must be less then 50" } }}
                                    value={username} onChange={(event) => { setUsername(event[0].target.value); return event[0].target.value }}
                                    control={control} />


                                <span className="errorSpan">{errors.username?.message}</span>
                            </div>
                            <div className="p-col-12">
                                <label className="signinForm__input_label">Password</label>
                                <Controller className={'signinForm__input'} as={InputText} type="password" id="password" name="password"
                                    rules={{ required: { value: true, message: "This field is required" }, maxLength: { value: 25, message: "Value must be less then 25" } }}
                                    value={password} onChange={(event) => { setPassword(event[0].target.value); return event[0].target.value }}
                                    control={control} />


                                <span className="errorSpan">{errors.password?.message}</span>
                            </div></>
                    }

                    <div className="p-col-12">
                        {askForOtp ? <Button label="Submit" className="signinForm__submit" icon="pi-md-person" onClick={handleSubmit(login)} />
                            :
                            <Button label="Login" className="signinForm__submit" icon="pi-md-person" onClick={handleSubmit(getMask)} />}
                        <Button label="Forgot Password" icon="pi-md-help" onClick={() => forgotPassowrd()} className="signinForm__forgot" />


                    </div>
                </div>
            </div>
        </div>


        {/* <div className="login-footer">PrimeReact Ultima</div> */}
    </div>
    )

}
export default withRouter(LoginFormFunction)
