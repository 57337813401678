import { SET_TEMPLATE,GET_TEMPLATE,DELETE_TEMPLATE } from "../actionTypes";

const initialState = {
  template: {name:"cj"}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TEMPLATE: {
      const { template} = action.payload;
      return {
        ...state,
        template: {...state.template, ...template}
      };
    }
    case GET_TEMPLATE: {
      return {
        ...state
      };
    }
    case DELETE_TEMPLATE: {
     const temp={...state}
      temp.template={};
      state=temp;
      return temp
    }
    default:
      return state;
  }
}
