
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Chart } from 'primereact/components/chart/Chart';
import { Panel } from 'primereact/components/panel/Panel';
import { GMap } from 'primereact/gmap';
import { Growl } from 'primereact/growl';
import React, { Component } from 'react';
import Avatar from 'react-avatar';
import { trackPromise } from 'react-promise-tracker';
import { CarService } from '../service/CarService';
import { checkForError } from '../utility/AppUtility';
import constant from './../constant/Constant';
import { HttpService } from './../service/HttpService';

const google = window.google;

export class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tasks: [],
            productCount: 0,
            userCount: 0,
            purchaseProductCount: 0,
            campaignCount: 0,
            dealerCount: 0,
            offerCount: 0,
            rewardCount: 0,
            redeemCount: 0,
            badgeUserCount: [],
            topUser: [],
            topServiceCenter: [],
            topProduct: [],
            barData: {},
            barDataAppointment: {},
            mapData: [],


            city: null,
            chartData: {
                labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
                datasets: [
                    {
                        label: 'First Dataset',
                        data: [65, 59, 80, 81, 56, 55, 40],
                        fill: false,
                        borderColor: '#4bc0c0'
                    },
                    {
                        label: 'Second Dataset',
                        data: [28, 48, 40, 19, 86, 27, 90],
                        fill: false,
                        borderColor: '#565656'
                    }
                ]
            },
            events: [
                {
                    "id": 1,
                    "title": "All Day Event",
                    "start": "2017-02-01"
                },
                {
                    "id": 2,
                    "title": "Long Event",
                    "start": "2017-02-07",
                    "end": "2017-02-10"
                },
                {
                    "id": 3,
                    "title": "Repeating Event",
                    "start": "2017-02-09T16:00:00"
                },
                {
                    "id": 4,
                    "title": "Repeating Event",
                    "start": "2017-02-16T16:00:00"
                },
                {
                    "id": 5,
                    "title": "Conference",
                    "start": "2017-02-11",
                    "end": "2017-02-13"
                },
                {
                    "id": 6,
                    "title": "Meeting",
                    "start": "2017-02-12T10:30:00",
                    "end": "2017-02-12T12:30:00"
                },
                {
                    "id": 7,
                    "title": "Lunch",
                    "start": "2017-02-12T12:00:00"
                },
                {
                    "id": 8,
                    "title": "Meeting",
                    "start": "2017-02-12T14:30:00"
                },
                {
                    "id": 9,
                    "title": "Happy Hour",
                    "start": "2017-02-12T17:30:00"
                },
                {
                    "id": 10,
                    "title": "Dinner",
                    "start": "2017-02-12T20:00:00"
                },
                {
                    "id": 11,
                    "title": "Birthday Party",
                    "start": "2017-02-13T07:00:00"
                },
                {
                    "id": 12,
                    "title": "Click for Google",
                    "url": "http://google.com/",
                    "start": "2017-02-28"
                }
            ],
            fullcalendarOptions: {
                plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
                defaultView: 'dayGridMonth',
                defaultDate: '2017-02-01',
                header: {
                    left: 'prev,next',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
                editable: true
            }
        };
        this.onTaskChange = this.onTaskChange.bind(this);
        this.onCityChange = this.onCityChange.bind(this);
        this.carservice = new CarService();
        this.httpService = new HttpService();
        this.options = {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        callback: function (value) { if (value % 1 === 0) { return value; } }
                    }
                }]
            },
            legend: {
                display: false
            }
        };
        this.optionsMap = {
            center: { lat: 36.890257, lng: 30.707417 },
            zoom: 7
        };
        this.overLays = []

    }


    onTaskChange(e) {
        let selectedTasks = [...this.state.tasks];
        if (e.checked)
            selectedTasks.push(e.value);
        else
            selectedTasks.splice(selectedTasks.indexOf(e.value), 1);

        this.setState({ tasks: selectedTasks });
    }

    onCityChange(e) {
        this.setState({ city: e.value });
    }
    showMessage(severity, summary, message) {
        let msg = { severity: severity, summary: summary, detail: message };
        this.growl.show(msg);
    }

    componentDidMount() {
        // this.getValidScanCode();
        // this.carservice.getCarsSmall().then(data => this.setState({ cars: data }));
        // this.getProductCount();
        // this.getUserProductCount();
        // this.getUserCount();
        // this.getOfferCount();
        // this.getDealerCount();
        // this.getCampaignCount();
        // this.getBadgeUserCount();
        // this.getTopUserCount();
        // this.getTopServiceCenter();
        // this.getTopProduct();
        // this.getTotalReward();
        // this.getTotalRedeem();
        // this.getProductReport();
        // this.getAppointmentReport();

    }
    getProductCount = () => {
        trackPromise(this.httpService.getApi('products/count').then((response) => {
            this.setState({ productCount: response.data === "" ? 0 : response.data });
        }).catch((error) => {
            if (checkForError(error))
                this.showMessage('error', 'Error Message', error.response.data.title)
        }));
    }
    getUserProductCount = () => {
        trackPromise(
            this.httpService.getApi('userProduct/count').then((response) => {
                this.setState({ purchaseProductCount: response.data === "" ? 0 : response.data });
            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            }));
    }
    getUserCount = () => {
        trackPromise(this.httpService.getApi('users/count').then((response) => {
            this.setState({ userCount: response.data === "" ? 0 : response.data });
        }).catch((error) => {
            if (checkForError(error))
                this.showMessage('error', 'Error Message', error.response.data.title)
        }));
    }
    getOfferCount = () => {
        trackPromise(this.httpService.getApi('offer/count').then((response) => {
            this.setState({ offerCount: response.data === "" ? 0 : response.data });
        }).catch((error) => {
            if (checkForError(error))
                this.showMessage('error', 'Error Message', error.response.data.title)
        }));
    }
    getCampaignCount = () => {
        trackPromise(this.httpService.getApi('campaignHistory/count').then((response) => {
            this.setState({ campaignCount: response.data === "" ? 0 : response.data });
        }).catch((error) => {
            if (checkForError(error))
                this.showMessage('error', 'Error Message', error.response.data.title)
        }));
    }
    getDealerCount = () => {
        trackPromise(this.httpService.getApi('dealer/count').then((response) => {
            this.setState({ dealerCount: response.data === "" ? 0 : response.data });
        }).catch((error) => {
            if (checkForError(error))
                this.showMessage('error', 'Error Message', error.response.data.title)
        }));
    }

    getBadgeUserCount = () => {
        trackPromise(this.httpService.getApi('users/badge/count').then((response) => {
            this.setState({ badgeUserCount: response.data === "" ? 0 : response.data });
        }).catch((error) => {
            if (checkForError(error))
                this.showMessage('error', 'Error Message', error.response.data.title)
        }));
    }

    getTopUserCount = () => {
        trackPromise(this.httpService.getApi('user/top').then((response) => {
            this.setState({ topUser: response.data === "" ? 0 : response.data });
        }).catch((error) => {
            if (checkForError(error))
                this.showMessage('error', 'Error Message', error.response.data.title)
        }));
    }
    getTopServiceCenter = () => {
        trackPromise(this.httpService.getApi('serviceCenter/top').then((response) => {
            this.setState({ topServiceCenter: response.data === "" ? 0 : response.data });
        }).catch((error) => {
            if (checkForError(error))
                this.showMessage('error', 'Error Message', error.response.data.title)
        }));
    }
    getTopProduct = () => {
        trackPromise(this.httpService.getApi('product/top').then((response) => {
            this.setState({ topProduct: response.data === "" ? 0 : response.data });
        }).catch((error) => {
            if (checkForError(error))
                this.showMessage('error', 'Error Message', error.response.data.title)
        }));
    }
    getTotalReward = () => {
        trackPromise(this.httpService.getApi('total/reward').then((response) => {
            this.setState({ rewardCount: response.data === "" ? 0 : response.data });
        }).catch((error) => {
            if (checkForError(error))
                this.showMessage('error', 'Error Message', error.response.data.title)
        }));
    }
    getTotalRedeem = () => {

        trackPromise(this.httpService.getApi('total/redeem').then((response) => {

            this.setState({ redeemCount: response.data === "" ? 0 : response.data });

        }).catch((error) => {
            if (checkForError(error))
                this.showMessage('error', 'Error Message', error.response.data.title)
        }));
    }
    getProductReport = () => {

        trackPromise(this.httpService.getApi('products/report/data').then((response) => {

            this.setState({ barData: response.data });

        }).catch((error) => {
            if (checkForError(error))
                this.showMessage('error', 'Error Message', error.response.data.title)
        }));
    }
    getAppointmentReport = () => {

        trackPromise(this.httpService.getApi('serviceCenter/report/data').then((response) => {

            this.setState({ barDataAppointment: response.data });

        }).catch((error) => {
            if (checkForError(error))
                this.showMessage('error', 'Error Message', error.response.data.title)
        }));
    }
    getValidScanCode = () => {
        trackPromise(this.httpService.getApi('validScan').then((response) => {
            this.setState({ mapData: response.data.content }, () => { this.getCenterPoints() });

        }).catch((error) => {
            if (checkForError(error))
                this.showMessage('error', 'Error Message', error.response.data.title)
        }));
    }
    getCenterPoints = () => {
        this.overLays = []
        var bound = new google.maps.LatLngBounds();
        for (var i = 0; i < this.state.mapData.length; i++) {
            bound.extend(new google.maps.LatLng(this.state.mapData[i]["latitude"], this.state.mapData[i]["longitude"]));
            let color = this.state.mapData[i]["valid"] ? 'green' : 'red';

            let image = "http://maps.google.com/mapfiles/ms/icons/" + color + "-dot.png";
            this.overLays.push(new google.maps.Marker({
                position: { lat: this.state.mapData[i]["latitude"], lng: this.state.mapData[i]["longitude"] },
                icon: image
            }));
        }
        this.optionsMap.center = { lat: bound.getCenter().lat(), lng: bound.getCenter().lng() }

    }
    getColor(n) {
        let temp = n % 4;
        switch (temp) {
            case 0:
                return '#3e83f8'
            case 1:
                return '#1bb697'
            case 2:
                return "#9785b0"
            case 3:
                return "#ee8388"
        }
    }


    render() {
        let cities = [
            { label: 'New York', value: { id: 1, name: 'New York', code: 'NY' } },
            { label: 'Rome', value: { id: 2, name: 'Rome', code: 'RM' } },
            { label: 'London', value: { id: 3, name: 'London', code: 'LDN' } },
            { label: 'Istanbul', value: { id: 4, name: 'Istanbul', code: 'IST' } },
            { label: 'Paris', value: { id: 5, name: 'Paris', code: 'PRS' } }
        ];

        return <div className="p-grid dashboard">
            <Growl ref={(el) => this.growl = el} style={{ marginTop: '75px' }} />

            
        </div>
    }
}