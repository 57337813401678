import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean,showMessage } from '../../utility/AppUtility';
import rule from './../../constant/Rule';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AppBreadcrumb } from '../../AppBreadcrumb';


function ViewObject(props) {






    let showDetail = []
    for (const prope of props.properties) {
        let value = "";
        if (prope.key.includes(".")) {
            let str = prope.key.split(".")
            if (props.data[str[0]] !== undefined && (props.data[str[0]] !== null))
                value = props.data[str[0]][str[1]];
        } else {
            value = props.data[prope.key]
        }

        showDetail.push(<div className="p-grid" ><div className="p-col-12" style={{ display: "inherit" }}> <div className="p-col-5">{prope.name} </div>&nbsp;&nbsp;&nbsp;&nbsp;<div className="p-col-5">{value}</div></div><br /></div>)

    }

    const cancel = () => {
        props.history.goBack();
    }

    return (

        <>

            {showDetail.map(item => {
                return item;
            })}

            {props.plantProducts ? props.plantProducts.length > 0 ?

                <>
                    <div className="p-grid">
                        <div className="p-col-8">

                            <br />
                            <h2>Products</h2>
                            <br />
                            <DataTable responsive={true} columnResizeMode="fit" resizableColumns={true} value={props.plantProducts} className="p-datatable-striped">
                                <Column field="product.productCode" header="Code"></Column>
                                <Column field="noOfLabelPerRoll" header="No. Of Label Per Roll"></Column>

                            </DataTable>
                            <br />


                        </div>
                    </div>

                </>
                : null : null

            }


            <div className="p-col-8">

            </div></>)


}
export default withRouter(ViewObject);
