import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import { useForm ,Controller} from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import { checkForError,showMessage } from '../../utility/AppUtility';
import rule from './../../constant/Rule';


function ContectPersonAdd(props) {

    var [obj, setObj] = useState({})
    const growl = useRef(null)
    var [activeIndex, setActiveIndex] = useState(0)
    var [contectPersons, setContectPersons] = useState(props.contectPersons)

    const { handleSubmit, errors, control, reset, watch,setValue } = useForm({ mode: "onBlur" });
    const httpService = new HttpService();
    
    useEffect(() => {
        reset({ "contectPersons": [...contectPersons] })
    }, [])


    const add = () => {
        let pp = [...contectPersons];
        pp.push({ name: null, designation: null, email: null, mobile: null })
        setValue("contectPersons",pp)
        setContectPersons(pp)
    }


    const handleChange = (e, index, name) => {
        const temp = [...contectPersons];
        const obj = { ...temp[index] };
        obj[name] = e.target.value;
        temp[index] = obj;
        setContectPersons(temp);
        return e.target.value;
    }

    
    const save = data => {

 //  console.log(contectPersons)
        props.saveContectPersons(contectPersons)



    }
    const deleteCp = (id) => {

        trackPromise(
            httpService.deleteApi('contect-people/' + id).then((response) => {
                //cancel();
            }).catch((error) => {
                if (checkForError(error))
                   showMessage('error', 'Error Message', error.response.data.title,growl)
            }));

    }
    const deletes = (index) => {
        let pp = [...contectPersons];
        let obj = pp[index];
        if (obj != undefined && obj.id != undefined) {
            deleteCp(obj.id)
           props.setContectPersons(pp)
        }
       
        pp.splice(index, 1)
        reset({
            contectPersons: [...pp]
        })
     setValue("contectPersons",pp)
        setContectPersons([...pp])

     

    }


    return (
        <>
            <div className="p-grid p-fluid form-group">
                <div className="p-col-8">
                    <div className="p-col-3">
                        <Button icon="pi pi-plus" label="Add" onClick={add.bind(this)} />
                    </div>
                </div>

                {contectPersons.map((pp, index) => {
                    return <div className="p-col-8" >


                        <div className="p-col-5 dlsplayInlineBlock">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">account_circle</i></span>
                                <span className="md-inputfield">
                                    <Controller className={errors?.contectPersons?.[index]?.name ? "p-error" : ''} name={"contectPersons[" + index + "].name"} value={contectPersons[index]?.name} rules={{ ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal100 }} as={InputText} control={control} onChange={(e) => handleChange(e[0], index, 'name')} />
                                    <label >Name</label>
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">{errors?.contectPersons?.[index]?.name?.message}</span>
                        </div>

                        <div className="p-col-5 dlsplayInlineBlock">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">airline_seat_recline_extra</i></span>
                                <span className="md-inputfield">
                                    <Controller className={errors?.contectPersons?.[index]?.designation ? "p-error" : ''} name={"contectPersons[" + index + "].designation"} value={contectPersons[index]?.designation} rules={{  ...rule.RULE.maxLengthRuleVal100 }} as={InputText} control={control} onChange={(e) => handleChange(e[0], index, 'designation')} />
                                    <label >Designation</label>
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">{errors?.contectPersons?.[index]?.designation?.message}</span>
                        </div>
                        <br></br>
                        <br></br>

                        <div className="p-col-5 dlsplayInlineBlock">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">email</i></span>
                                <span className="md-inputfield">
                                    <Controller className={errors?.contectPersons?.[index]?.email ? "p-error" : ''} name={"contectPersons[" + index + "].email"} value={contectPersons[index]?.email} rules={{ ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal100 }} as={InputText} control={control} onChange={(e) => handleChange(e[0], index, 'email')} />
                                    <label >Email</label>
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">{errors?.contectPersons?.[index]?.email?.message}</span>
                        </div>
                        <div className="p-col-5 dlsplayInlineBlock">
                            <div className="p-inputgroup">
                                <span className="p-inputgroup-addon"><i className="material-icons">phone</i></span>
                                <span className="md-inputfield">
                                    <Controller className={errors?.contectPersons?.[index]?.mobile ? "p-error" : ''} type="number" name={"contectPersons[" + index + "].mobile"} value={contectPersons[index]?.mobile}  as={InputText} control={control} onChange={(e) => handleChange(e[0], index, 'mobile')} />
                                    <label >Mobile</label>
                                </span>
                            </div>
                            <span className="errorSpan merginLeft30">{errors?.contectPersons?.[index]?.mobile?.message}</span>
                        </div>
                        <div className="p-col-2 dlsplayInlineBlock">
                            <Button icon="pi-md-delete" onClick={deletes.bind(this, index)} />
                        </div>
                    </div>
                })}


            </div>
            <div className="p-col-12">
                <Button icon="pi pi-check" label="Save" onClick={handleSubmit(save)} />
                {/* <Button icon="pi-md-clear" label="Clear" className="p-button-secondary" onClick={() => clear()} /> */}
                {/* <Button icon="pi-md-cancel" label="Cancel" className="p-button-secondary" onClick={() => cancel()} /> */}

            </div></>)




}
export default withRouter(ContectPersonAdd);
