
import { Button } from 'primereact/button';
import React, { useEffect, useState ,useRef} from 'react';
import { useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import constant from './../../constant/Constant';
import NewComponent from './NewComponent'
import { AppBreadcrumb } from '../../AppBreadcrumb';
import { Growl } from 'primereact/growl';
import { checkForError, clean, showMessage } from '../../utility/AppUtility';


function SelectOption(props) {
    const httpService = new HttpService();
    const growl = useRef(null)

    var id = ""
    var [value, setValue] = useState([{ name: 'QR Code', value: constant.QR_CODE }])
    const deleteComponent = (e) => {
        trackPromise(
            httpService.deleteApi('deleteTemplatePDFel/'+props.id+"/by/"+e).then((response) => {
                props.deleteSavedComponent(e)
            }).catch((error) => {

            }));
    }
    const itemsObj = {
        'add': [
            {
                label: 'Add',
                icon: 'pi pi-plus',
                command: (e) => {
                    
                    onAdd(e.item.value)                }
            }],
        'delete': [
            {
                label: 'Delete',
                icon: 'pi-md-cancel',
                command: (e) => {
                    deleteComponent(e.item.value)
                }
            }] 
    }

    const allComponentData = [
        { name: 'QR Code', value: constant.QR_CODE, icon: "fas fa-qrcode", className: "p-button-warning", item: itemsObj['add'] },
        { name: 'Guilloche', value: constant.GLOSH, icon: "far fa-object-ungroup", className: "p-button-info", item: itemsObj['add'] },
        { name: 'Code', value: constant.CODE, icon: "far fa-code", className: "p-button-info", item: itemsObj['add'] },
        { name: 'Text 1', value: constant.TEXT, icon: "far fa-file-alt", className: "p-button-info", item: itemsObj['add'] },
        { name: 'Text 2', value: constant.TEXT2, icon: "far fa-file-alt", className: "p-button-info", item: itemsObj['add'] },
        { name: 'Text 3', value: constant.TEXT3, icon: "far fa-file-alt", className: "p-button-info", item: itemsObj['add'] },
        { name: 'Description', value: constant.DESCRIPTION, icon: "far fa-file-alt", className: "p-button-info", item: itemsObj['add'] },
        { name: 'Customer', value: constant.CUSTOMER, icon: "far fa-file-alt", className: "p-button-info", item: itemsObj['add'] },
        { name: 'Quantity', value: constant.QUANTITY, icon: "far fa-file-alt", className: "p-button-info", item: itemsObj['add'] },
        { name: 'Carton', value: constant.CARTON, icon: "far fa-file-alt", className: "p-button-info", item: itemsObj['add'] },
        { name: 'PO Number', value: constant.PO, icon: "far fa-file-alt", className: "p-button-info", item: itemsObj['add'] }

    ];
    var [allComponent, setAllComponent] = useState(allComponentData)

    if (props === undefined || props === null || props.location === undefined
        || props.location === null || props.location.state === undefined
        || props.location.state === null
        || props.location.state.id === undefined || props.location.state.id === null) {
        props.history.push('customer_list')
    } else {
        id = props.location.state.id;
 
    }
    useEffect(() => {
        updateColor()
    }, [props.savedComponent])
    const { handleSubmit } = useForm({ mode: "onSubmit" });

    // const goToDesign = (type) => {
    //     props.history.push({
    //         pathname: '/design_template',
    //         state: { id: id, type: type }
    //     })
    // }

    const save = () => {
        trackPromise(
            httpService.postApi('updateTemplateStatus', { id: id }).then((response) => {
                back()
            }).catch((error) => {
                if (checkForError(error)) {

                    showMessage('error', 'Error Message', error.response.data.title, growl)

                }
            }));
    }
    const back = () => {
        props.history.push('template_histories')
    }
    const handleOnChange = (event) => {
        setValue(event.value)
    }
   
    const onAdd = (event) => {
        props.change(event)
        props.resetComponent()
        const oldallComponent = [...allComponent]
        oldallComponent.map(component => {

            if (component.value == event) {
                component.className = "p-button-warning"
                component.item=itemsObj['add'];

            } else {
                component.className = "p-button-info"
                component.item=itemsObj['add'];

            }
            if (props.savedComponent.includes(component.value)) {
                component.className = "p-button-success"
                component.item=itemsObj['delete'];
            }
            return component
        })
        setAllComponent(oldallComponent)
    }
    const updateColor = () => {
        const oldallComponent = [...allComponent]
        if (props.savedComponent !== undefined && props.savedComponent !== null && props.savedComponent.length > -1) {
            oldallComponent.map(component => {

                if (props.savedComponent.includes(component.value)) {
                    component.className = "p-button-success"
                    component.item=itemsObj['delete'];
                } else if(component.value===props.type) {
                    component.className = "p-button-warning"
                    component.item=itemsObj['add'];
                }else{
                    component.className = "p-button-info"
                    component.item=itemsObj['add'];
                }
                return component
            })
            setAllComponent(oldallComponent)
        }
    }



    var allComponentList = allComponent.map(component => {
        console.log(component.name)
        return <><li key={component.name}><NewComponent   className={component.className} label={component.name} value={component.value} icon={component.icon} onAdd={(e) => onAdd(e)} items={component.item} /></li>  
            <br /></>
    })

    return (<><div className="p-grid">
         <Growl ref={growl} style={{ marginTop: '75px' ,marginLeft:"20px"}} />
        <div className="p-col-10">
            <div >
                <h4>Add Component</h4>
                <br></br>

                <hr />
               
                <div className="p-grid p-fluid ">
                    <ol>
                        {allComponentList}
                    </ol>
                </div>
               
                <div className="p-col-10" style={{ textAlign: "center"}}>
                    <Button label="Save" tooltip="Save" tooltipOptions={{ position: 'bottom' }} className="p-button-info" onClick={() => handleSubmit(save())} />
                    <Button label="Skip" tooltip="Add By developer" tooltipOptions={{ position: 'bottom' }} className="p-button-info" onClick={() => back} />
                </div >
            </div>
        </div>
    </div></>)

}
export default withRouter(SelectOption);
