import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Growl } from "primereact/growl";
import React, { useEffect, useRef, useState, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import { withRouter } from "react-router-dom";
import { HttpService } from "../../service/HttpService";
import { checkForError, clean, showMessage } from "../../utility/AppUtility";
import rule from "./../../constant/Rule";
import { AppBreadcrumb } from "../../AppBreadcrumb";
import { FileUpload } from "primereact/fileupload";
import { PriviladgeContext } from "../../context/priviladge-context";

var _ = require("lodash");
function LinkQrWithProduct(props) {
    const priviladgeContext = useContext(PriviladgeContext);
    var [disableSave, setDisableSave] = useState(false);
    var [clients, setClients] = useState([]);
    var [plants, setPlants] = useState([]);
    var [products, setProducts] = useState([]);
    var [obj, setObj] = useState({});

    const growl = useRef(null);
    const { handleSubmit, errors, control, setValue,reset  } = useForm({ mode: "onBlur" });
    const httpService = new HttpService();

    useEffect(() => {
        getCustomer();
    }, []);

    const getCustomer = () => {
        trackPromise(
            httpService
                .getApi("customers/company?type=Customer")
                .then((response) => {
                    setClients(response.data.content);
                })
                .catch((error) => {
                    if (checkForError(error))
                        showMessage(
                            "error",
                            "Error Message",
                            error.response.data.title,
                            growl
                        );
                })
        );
    };

    const getPlant = (id) => {
        trackPromise(
            httpService
                .getApi("plants/" + id + "/customer")
                .then((response) => {
                    setPlants(response.data);
                })
                .catch((error) => {
                    if (checkForError(error))
                        showMessage(
                            "error",
                            "Error Message",
                            error.response.data.title,
                            growl
                        );
                })
        );
    };
    const getPlantProduct = (id) => {
        trackPromise(
            httpService
                .getApi("products/" + id + "/plant")
                .then((response) => {
                    setProducts(response.data);
                })
                .catch((error) => {
                    if (checkForError(error))
                        showMessage(
                            "error",
                            "Error Message",
                            error.response.data.title,
                            growl
                        );
                })
        );
    };

    const handleChange = (e, type, name) => {
        let val = e.target.value;
        if (e.target.name === "customer") {
            getPlant(val.id);
            setObj({ ...obj, [e.target.name]: val, plant: null, product: null });
            setProducts([]);
            setValue("plant", null);
            setValue("product", null);
        } else if (e.target.name === "plant") {
            getPlantProduct(val.id);
            setObj({ ...obj, [e.target.name]: val, product: null });
            setValue("product", null);
        } else {
            setObj({ ...obj, [e.target.name]: val });
        }
        return val;
    };

    const cancel = (e) => {
        //reset(null);
        props.history.push("product_list");
    };

    const onFileSelect = (e, name) => {
        let fileArray = [];
        if (obj.files !== undefined && obj.files !== "") {
            fileArray = [...obj.files];
        }

        var file = e.files[0];
        const fileName = e.files[0].name;
        const lastDot = fileName.lastIndexOf(".");

        const ext = fileName.substring(lastDot + 1);
        file.originalFilename = name + "." + ext;
        fileArray.push(file);
        setObj({ ...obj, files: fileArray });
    };

    const onRemoveFile = (e) => {
        if (obj.files !== undefined && obj.files.length > 0) {
            let index = obj.files.findIndex((x) => x.name === e.file.name);
            if (index > -1) {
                obj.files.splice(index, 1);
            }
        }
    };

    const save = (data) => {
        obj = clean(obj);
        obj["customer.id"] = obj.customer.id;
        obj["product.id"] = obj.product.id;
        console.log(obj);
        let formData = new FormData();

        console.log(JSON.stringify(data.product));

        for (const property in obj) {
            if (property === "files") {
                if (obj[property] !== undefined)
                    obj[property].forEach((element) => {
                        formData.append("file", element, element.originalFilename);
                    });
            } else if (obj[property] !== undefined)
                formData.append(property, obj[property]);
        }

        trackPromise(
            httpService
                .getApiMultipart("link/productWithQr", formData)
                .then((response) => {
                    cancel();
                    showMessage(
                        "success",
                        "Success Message",
                        response.data,
                        growl
                    );
                    setObj(null)
                })
                .catch((error) => {
                    if (checkForError(error)) {
                        showMessage(
                            "error",
                            "Error Message",
                            error.response.data.title,
                            growl
                        );
                    }
                })
        );
    };

    return (
        <>
            <AppBreadcrumb
                breadcrumdItems={[
                    { label: "Link QrCode", url: "#link_qr" },
                ]}
            />

            <div className="p-grid ">
                <Growl ref={growl} style={{ marginTop: "75px" }} />

                <div className="p-col-8 ">
                    <div className="card card-w-title">
                        <h1>Link QRCODE</h1>
                        <div className="p-grid p-fluid form-group">

                            {priviladgeContext.isAdmin && (
                                <div className="p-col-8">
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="material-icons">perm_identity</i>
                                        </span>
                                        <span className="md-inputfield">
                                            <Controller
                                                optionLabel="companyName"
                                                optionValue="id"
                                                filter
                                                filterBy="companyName"
                                                className={errors.client ? "p-error" : ""}
                                                rules={{ ...rule.RULE.requiredRule }}
                                                as={Dropdown}
                                                control={control}
                                                options={clients}
                                                placeholder={"Customer"}
                                                name="customer"
                                                value={obj?.customer}
                                                onChange={(e) => handleChange(e[0])}
                                            />
                                        </span>
                                    </div>
                                    <span className="errorSpan merginLeft30">
                                        {errors.customer?.message}
                                    </span>
                                </div>
                            )}
                            <div className="p-col-8">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="material-icons">location_city</i>
                                    </span>
                                    <span className="md-inputfield">
                                        <Controller
                                            optionLabel="name"
                                            optionValue="id"
                                            filter
                                            filterBy="name"
                                            className={errors.plant ? "p-error" : ""}
                                            rules={{ ...rule.RULE.requiredRule }}
                                            as={Dropdown}
                                            control={control}
                                            options={plants}
                                            placeholder={"Plant"}
                                            name="plant"
                                            value={obj?.plant}
                                            onChange={(e) => handleChange(e[0])}
                                        />
                                    </span>
                                </div>
                                <span className="errorSpan merginLeft30">
                                    {errors.plant?.message}
                                </span>
                            </div>
                            <div className="p-col-8">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="material-icons">shop</i>
                                    </span>
                                    <span className="md-inputfield">
                                        <Controller
                                            optionLabel="productCode"
                                            optionValue="id"
                                            filter
                                            filterBy="productCode"
                                            className={errors.product ? "p-error" : ""}
                                            rules={{ ...rule.RULE.requiredRule }}
                                            as={Dropdown}
                                            control={control}
                                            options={products}
                                            placeholder={"Product"}
                                            name="product"
                                            value={obj?.product}
                                            onChange={(e) => handleChange(e[0])}
                                        />
                                    </span>
                                </div>
                                <span className="errorSpan merginLeft30">
                                    {errors.product?.message}
                                </span>
                            </div>
                        </div>

                        <div className="p-col-8">
                            <label>Upload File</label>
                            <div className="p-col-8"></div>
                            <div className="p-inputgroup">
                                <Controller
                                    className={errors.file ? "p-error" : ""}
                                    rules={{}}
                                    as={FileUpload}
                                    control={control}
                                    name="file"
                                    onSelect={(e) => onFileSelect(e, "productImage")}
                                    accept="txt/*"
                                    onRemove={(e) => onRemoveFile(e)}
                                    maxFileSize={1000000}
                                    customUpload={true}
                                />
                            </div>
                            <span className="errorSpan merginLeft30">
                                {errors.file?.message}
                            </span>
                        </div>

                        <div className="p-col-12">
                            <Button
                                icon="pi pi-check"
                                label="Save"
                                disabled={disableSave}
                                onClick={handleSubmit(save)}
                            />

                            <Button
                                icon="pi-md-cancel"
                                label="Cancel"
                                className="p-button-secondary"
                                onClick={() => cancel()}
                            />
                        </div>
                        <div className="p-col-8"></div>
                    </div>
                </div>
                <div className="p-col-4"></div>
            </div>
        </>
    );
}
export default withRouter(LinkQrWithProduct);
