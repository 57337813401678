import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TabView, TabPanel } from 'primereact/tabview';

export class AppConfig extends Component {

	static defaultProps = {
		layoutMode: 'slim',
		darkMenu: true,
		profileMode: 'inline',
		themeColor: 'indigo',
		configDialogActive: false
	}

	static propTypes = {
		layoutMode: PropTypes.string.isRequired,
		darkMenu: PropTypes.bool.isRequired,
		profileMode: PropTypes.string.isRequired,
		themeColor: PropTypes.string.isRequired,
		configDialogActive: PropTypes.bool.isRequired
	}

	render() {
		let themeColors = [
			{ name: 'Indigo Pink', file: 'indigo', image: 'indigo-pink.svg' },
			{ name: 'Brown Green', file: 'brown', image: 'brown-green.svg' },
			{ name: 'Blue Amber', file: 'blue', image: 'blue-amber.svg' },
			{ name: 'BlueGrey Green', file: 'blue-grey', image: 'bluegrey-green.svg' },
			{ name: 'Dark Blue', file: 'dark-blue', image: 'dark-blue.svg' },
			{ name: 'Dark Green', file: 'dark-green', image: 'dark-green.svg' },
			{ name: 'Green Yellow', file: 'green', image: 'green-yellow.svg' },
			{ name: 'Purple Cyan', file: 'purple-cyan', image: 'purple-cyan.svg' },
			{ name: 'Purple Amber', file: 'purple-amber', image: 'purple-amber.svg' },
			{ name: 'Teal Lime', file: 'teal', image: 'teal-lime.svg' },
			{ name: 'Cyan Amber', file: 'cyan', image: 'cyan-amber.svg' },
			{ name: 'Grey DeepOrange', file: 'grey', image: 'grey-deeporange.svg' }
		];

		return (
			<div className={classNames("layout-config", {'layout-config-active': this.props.configDialogActive})} onClick={this.props.onConfigClick}>
				<div className="layout-config-content">
					<button className="layout-config-button" id="layout-config-button" onClick={this.props.onConfigButtonClick}>
						<i className="pi-md-settings"/>
					</button>

					<button className="layout-config-close" onClick={this.props.onConfigCloseClick}>
						<i className="pi-md-close"/>
					</button>

					<TabView>
						<TabPanel header="Menu">
							<h1>Menu Modes</h1>
							<div className="panel-items">
								<div className="panel-item">
									<button className="p-link" onClick={event => this.props.changeMenuMode({ originalEvent: event, menuMode: 'static' })}>
										<img src="assets/layout/images/configurator/menu/ultima-static.png" alt="ultima"/>
										{this.props.layoutMode === 'static' && <i className="pi-md-check"/>}
									</button>
									<span>Static</span>
								</div>
								<div className="panel-item">
									<button className="p-link" onClick={event => this.props.changeMenuMode({ originalEvent: event, menuMode: 'overlay' })}>
										<img src="assets/layout/images/configurator/menu/ultima-overlay.png" alt="ultima"/>
										{this.props.layoutMode === 'overlay' && <i className="pi-md-check"/>}
									</button>
									<span>Overlay</span>
								</div>
								<div className="panel-item">
									<button className="p-link" onClick={event => this.props.changeMenuMode({ originalEvent: event, menuMode: 'horizontal' })}>
										<img src="assets/layout/images/configurator/menu/ultima-horizontal.png" alt="ultima"/>
										{this.props.layoutMode === 'horizontal' && <i className="pi-md-check"/>}
									</button>
									<span>Horizontal</span>
								</div>
								<div className="panel-item">
									<button className="p-link" onClick={event => this.props.changeMenuMode({ originalEvent: event, menuMode: 'slim' })}>
										<img src="assets/layout/images/configurator/menu/ultima-slim.png" alt="ultima"/>
										{this.props.layoutMode === 'slim' && <i className="pi-md-check"/>}
									</button>
									<span>Slim</span>
								</div>
							</div>

							<h1>Menu Colors</h1>
							<div className="panel-items">
								<div className="panel-item">
									<button className="p-link" onClick={event => this.props.changeMenuColor({ originalEvent: event, darkMenu: true })}>
										<img src="assets/layout/images/configurator/menu/ultima-dark.png" alt="ultima"/>
										{this.props.darkMenu === true && <i className="pi-md-check"/>}
									</button>
									<span>Dark</span>
								</div>
								<div className="panel-item">
									<button className="p-link" onClick={event => this.props.changeMenuColor({ originalEvent: event, darkMenu: false })}>
										<img src="assets/layout/images/configurator/menu/ultima-static.png" alt="ultima"/>
										{this.props.darkMenu === false && <i className="pi-md-check"/>}
									</button>
									<span>Light</span>
								</div>
							</div>
						</TabPanel>

						<TabPanel header="User Profile">
							<div className="panel-items">
								<div className="panel-item">
									<button className={classNames("p-link", {'p-disabled': this.props.layoutMode === 'horizontal'})}
											onClick={event => this.props.changeProfileMode({ originalEvent: event, profileMode: 'inline' })}>
										<img src="assets/layout/images/configurator/menu/ultima-inline.png" alt="ultima"/>
										{this.props.profileMode === 'inline' && <i className="pi-md-check"/>}
									</button>
									<span>Inline</span>
								</div>
								<div className="panel-item">
									<button  className={classNames("p-link", {'p-disabled': this.props.layoutMode === 'horizontal'})}
											 onClick={event => this.props.changeProfileMode({ originalEvent: event, profileMode: 'top' })}>
										<img src="assets/layout/images/configurator/menu/ultima-popup.png" alt="ultima"/>
										{this.props.profileMode === 'top' && <i className="pi-md-check"/>}
									</button>
									<span>Overlay</span>
								</div>
							</div>
						</TabPanel>

						<TabPanel header="Themes">
							<div className="panel-items">
								{themeColors && themeColors.map((t, index) => {
									return <div className="panel-item" key={index}>
										<button className="p-link layout-config-option"
												onClick={event => this.props.changeTheme({theme: t.file})}>
											<img src={"assets/layout/images/configurator/theme/" + t.image} alt={t.name}/>
											{this.props.themeColor === t.file && <i className="pi-md-check"/>}
										</button>
									</div>
								})
								}
							</div>
						</TabPanel>
					</TabView>
				</div>
			</div>
		);
	}
}
