import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState,useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { checkForError } from '../utility/AppUtility';
import rule from './../constant/Rule';
import { HttpService } from './../service/HttpService';
import { clean } from './../utility/AppUtility';
import { AppBreadcrumb } from './../AppBreadcrumb';

var _ = require('lodash');

function UserProfile(props) {

    var [obj, setObj] = useState({})
    var [minDate] = useState(new Date())
    var [disableFile, setDisableFile] = useState(false)
    const growl = useRef(null)

    
    var id = ""

    const { handleSubmit, errors, control, reset } = useForm({ mode: "onBlur" });
    const httpService = new HttpService();

    useEffect(() => {
        
        
        httpService.getApi('account').then((response) => {
            setObj({ ...response.data })
            let myres = response.data;
            if (myres.accountExpiredDate != null)
                myres.accountExpiredDate = new Date(myres.accountExpiredDate);
            reset({ ...myres })
        }).catch((error) => {

        })
    }, [id])

    const handleChange = (e, type) => {
        let val = e.target.value;
        if (type === 'date') {
            val = moment(new Date(e.target.value)).format("YYYY-MM-DDTHH:mm:ss").toString();
        }
        setObj({ ...obj, [e.target.name]: val });
        if (type === 'date') {
            return e.target.value;
        } else
            return val;
    }
    const onFileSelect = (e) => {
        setObj({ ...obj, file: e.files[0] });
        setDisableFile(true)
    }
    const onRemoveImg = () => {
        _.unset(obj, 'file');
        setDisableFile(false)
    }

    const cancel = () => {
        props.history.push('dashboard');
    }
    


    const save = data => {
        obj = clean(obj)
        let formData = new FormData()
        for (const property in obj) {

            if (obj[property] !== undefined)
                formData.append(property, obj[property]);
        }
        httpService.getApiMultipartPut('users', formData).then((response) => {

            userInfo();

        }).catch((error) => {
            if (checkForError(error))
                this.showMessage('error', 'Error Message', error.response.data.title)
        })

    }
    const userInfo = () => {
        httpService
            .getApi('account')
            .then((response) => {
                localStorage.setItem("user", JSON.stringify(response.data))
                cancel()
            })
            .catch((error) => {

            })
    }




    return <><div className="p-grid ">
        <div className="p-col-8 ">
            <Growl ref={growl} style={{ marginTop: '75px' }} />

            <div className="card card-w-title">
                <h1>User profile</h1>
                <div className="p-grid  p-fluid form-group">

                    <div className="p-col-8">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="material-icons">account_circle</i></span>
                            <span className="md-inputfield">
                                <Controller className={errors.firstName ? "p-error" : ''} as={InputText} control={control} rules={{ ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal100 }} name="firstName" value={obj.firstName} onChange={(e) => handleChange(e[0])} />
                                <label >First name</label>
                            </span>
                        </div>
                        <span className="errorSpan merginLeft30">{errors.firstName?.message}</span>
                    </div>
                    <div className="p-col-8">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="material-icons">account_circle</i></span>
                            <span className="md-inputfield">
                                <Controller className={errors.lastName ? "p-error" : ''} rules={{ ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal100 }}
                                    as={InputText} control={control} name="lastName" value={obj.lastName} onChange={(e) => handleChange(e[0])} />
                                <label >Last name</label>                            </span>
                        </div>
                        <span className="errorSpan merginLeft30">{errors.lastName?.message}</span>
                    </div>
                    <div className="p-col-8">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="material-icons">phone</i></span>
                            <span className="md-inputfield">
                                <Controller className={errors.mobileNo ? "p-error" : ''} type="number" name="mobileNo" rules={{ ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRule15 }} value={obj.mobileNo} as={InputText} control={control} onChange={(e) => handleChange(e[0])} />
                                <label>Mobile no.</label>
                            </span>
                        </div>
                        <span className="errorSpan merginLeft30">{errors.mobileNo?.message}</span>
                    </div>
                    <div className="p-col-8">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="material-icons">email</i></span>
                            <span className="md-inputfield">
                                <Controller className={errors.email ? "p-error" : ''} rules={{ ...rule.RULE.maxLengthRuleVal50 }} name="email" value={obj.email} as={InputText} control={control} onChange={(e) => handleChange(e[0])} />
                                <label>Email</label>
                            </span>
                        </div>
                        <span className="errorSpan merginLeft30">{errors.email?.message}</span>
                    </div>

                    <div className="p-col-8">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="material-icons">home</i></span>
                            <span className="md-inputfield">
                                <Controller className={errors.address ? "p-error" : ''} rules={{ ...rule.RULE.maxLengthRuleVal200 }} name="address" value={obj.address} as={InputText} control={control} onChange={(e) => handleChange(e[0])} />
                                <label>Address</label>
                            </span>
                        </div>
                        <span className="errorSpan merginLeft30">{errors.email?.message}</span>
                    </div>



                    <div className="p-col-8">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="material-icons">insert_invitation</i></span>
                            <span className="md-inputfield">
                                <Controller minDate={minDate} className={errors.accountExpiredDate ? "p-error" : ''} name="accountExpiredDate" value={obj.accountExpiredDate} rules={{ ...rule.RULE.minRuleVal1 }} as={Calendar} control={control} onChange={(e) => handleChange(e[0], 'date')}
                                    monthNavigator={true} yearNavigator={true} yearRange="2010:2070" dateFormat="yy-MM-dd" />
                                <label>Account expiration date</label>
                            </span>
                        </div>
                        <span className="errorSpan merginLeft30">{errors.accountExpiredDate?.message}</span>
                    </div>

                    <div className="p-col-8">
                        <label>Upload profile image</label>
                        <div className="p-col-8"></div>
                        <div className="p-inputgroup">
                            <Controller className={errors.file ? "p-error" : ''} disabled={disableFile} rules={{}} as={FileUpload} control={control} name="file"
                                onSelect={e => onFileSelect(e)} accept="image/*" onRemove={() => onRemoveImg()} maxFileSize={5000000} customUpload={true} />
                        </div>
                        <span className="errorSpan merginLeft30">{errors.file?.message}</span>
                    </div>
                </div>
                <div className="p-col-12">
                    <Button icon="pi pi-check" label="Save" onClick={handleSubmit(save)} />
                    <Button icon="pi-md-cancel" label="Cancel" className="p-button-secondary" onClick={() => cancel()} />
                </div>
                <div className="p-col-8">

                </div>

            </div>
        </div>
        <div className="p-col-4"></div>
    </div></>

}
export default withRouter(UserProfile);
