import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState,useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean,showMessage } from '../../utility/AppUtility';
import rule from './../../constant/Rule';
import { FileUpload } from 'primereact/fileupload';
import { AppBreadcrumb } from '../../AppBreadcrumb';

var _ = require('lodash');


function GuillocheImageAdd(props) {

    var [obj, setObj] = useState({})
    var [disableFile, setDisableFile] = useState(false)
    const growl = useRef(null)
    const { handleSubmit, errors, control } = useForm({ mode: "onBlur" });
    const httpService = new HttpService();
    
    

    const handleChange = (e, type) => {
        let val = e.target.value;

        setObj({ ...obj, [e.target.name]: val });
        return val;
    }
   
    const cancel = () => {
        props.history.push('guilloche_images_list')
    }
    
    const save = data => {
        obj = clean(obj)
        let formData = new FormData()
        for (const property in obj) {
            if (property === "files") {
                if (obj[property] !== undefined)
                    obj[property].forEach(element => {
                        formData.append("file", element);
                    });

            } else
                if (obj[property] !== undefined)
                    formData.append(property, obj[property]);
        }
        trackPromise(
            httpService.getApiMultipart('guilloche-images', formData).then((response) => {
                cancel();
            }).catch((error) => {
                if (checkForError(error)) {
                   showMessage('error', 'Error Message', error.response.data.title,growl)
                }
            }));

    }

    const onFileSelect = (e) => {
        setObj({ ...obj, file: e.files[0] });
        setDisableFile(true)
    }
    const onRemoveImg = () => {
        _.unset(obj, 'file');
        setDisableFile(false)
    }



    return <><div className="p-grid ">
        <Growl ref={growl} style={{ marginTop: '75px' }} />

        <div className="p-col-10 ">
            <div className="card card-w-title">
                <h1>Add Guilloche Image</h1>
                <div className="p-grid p-fluid form-group">

                    <div className="p-col-8">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="material-icons">account_circle</i></span>
                            <span className="md-inputfield">
                                <Controller className={errors.color ? "p-error" : ''} name="color" value={obj.color} rules={{ ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal100 }} as={InputText} control={control} onChange={(e) => handleChange(e[0])} />
                                <label >Color</label>
                            </span>
                        </div>
                        <span className="errorSpan merginLeft30">{errors.color?.message}</span>
                    </div>
                    <div className="p-col-8">
                        <label>Upload image</label>
                        <div className="p-col-8"></div>
                        <div className="p-inputgroup">
                            <Controller className={errors.file ? "p-error" : ''} disabled={disableFile} rules={{}} as={FileUpload} control={control} name="file"
                                onSelect={e => onFileSelect(e)} accept="image/*" onRemove={() => onRemoveImg()} maxFileSize={5000000} customUpload={true} />
                        </div>
                        <span className="errorSpan merginLeft30">{errors.file?.message}</span>
                    </div>

                </div>
                <div className="p-col-12">
                    <Button icon="pi pi-check" label="Save" onClick={handleSubmit(save)} />
                    {/* <Button icon="pi-md-clear" label="Clear" className="p-button-secondary" onClick={() => clear()} /> */}
                    <Button icon="pi-md-cancel" label="Cancel" className="p-button-secondary" onClick={() => cancel()} />

                </div>
                <div className="p-col-8">

                </div>

            </div>
        </div>
        <div className="p-col-4"></div>
    </div></>

}
export default withRouter(GuillocheImageAdd);
