import moment from "moment";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { Growl } from "primereact/growl";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import { withRouter } from "react-router-dom";
import { HttpService } from "../../service/HttpService";
import { checkForError, clean, showMessage } from "../../utility/AppUtility";
import rule from "./../../constant/Rule";
import { MultiSelect } from "primereact/multiselect";
import "./../../style/multiselect.scss";
import { PriviladgeContext } from "./../../context/priviladge-context";
import { AppBreadcrumb } from "../../AppBreadcrumb";

var _ = require("lodash");

function UserAdd(props) {
  var [obj, setObj] = useState({});
  var [message, setMessage] = useState("");
  var [disableFile, setDisableFile] = useState(false);
  const growl = useRef(null);
  var [minDate] = useState(new Date());
  var [companies, setCompanies] = useState([]);
  var [customers, setCustomers] = useState([]);
  var [authority, setAuthority] = useState([]);
  var [trackNTrace, setTrackNTrace] = useState(false);
  const priviladgeContext = useContext(PriviladgeContext);
  const { handleSubmit, errors, control, reset } = useForm({ mode: "onBlur" });
  const httpService = new HttpService();

  useEffect(() => {
    getCompanies();

    if (
      localStorage.getItem("track&Trace") != undefined &&
      localStorage.getItem("track&Trace") === "true"
    ) {
      setTrackNTrace(true);
      getCustomers(true);
      getAuthorities();
    } else {
      getCustomers(false);
    }
  }, []);
  const getCustomers = (track) => {
    let url = "customers/company?type=Customer";
    if (track) {
      url = "customers/company?type=Business Partner";
    }
    httpService
      .getApi(url)
      .then((response) => {
        setCustomers([...response.data.content]);
      })
      .catch((error) => {});
  };

  const getAuthorities = () => {
    trackPromise(
      httpService
        .getApi("users/authorities")
        .then((response) => {
          setAuthority(
            response.data.filter((x) => {
              if (
                x.displayName != "Admin" &&
                x.displayName != "Super Admin" &&
                x.displayName != "User"
              )
                return x;
            })
          );
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage("error", "Error Message", error.response.data.title);
        })
    );
  };

  const getCompanies = () => {
    httpService
      .getApi("companies")
      .then((response) => {
        setCompanies([...response.data]);
      })
      .catch((error) => {});
  };

  const handleChange = (e, type) => {
    let val = e.target.value;
    if (type === "date") {
      val = moment(new Date(e.target.value))
        .format("YYYY-MM-DDTHH:mm:ss")
        .toString();
    }
    setObj({ ...obj, [e.target.name]: val });
    if (type === "date") {
      return e.target.value;
    } else return val;
  };
  const onFileSelect = (e) => {
    setObj({ ...obj, file: e.files[0] });
    setDisableFile(true);
  };
  const onRemoveImg = () => {
    _.unset(obj, "file");
    setDisableFile(false);
  };

  const cancel = () => {
    props.history.push("user_list");
  };

  const save = (data) => {
    obj = clean(obj);

    if (priviladgeContext.isSuperAdmin) obj["company.id"] = obj.company1.id;
    if (priviladgeContext.isAdmin)
      obj["company.id"] = priviladgeContext.company.id;

    if (obj.customers1 != undefined && obj.customers1 != null) {
      if (obj.customers1.length >= 1) {
        for (let index = 0; index < obj.customers1.length; index++) {
          obj["userCustomers[" + index + "].id.customerId"] =
            obj.customers1[index].id;
        }
      } else {
        obj["userCustomers[" + 0 + "].id.customerId"] = obj.customers1.id;
      }
    }
    let formData = new FormData();
    for (const property in obj) {
      if (property === "files") {
        if (obj[property] !== undefined)
          obj[property].forEach((element) => {
            formData.append("file", element);
          });
      } else if (obj[property] !== undefined)
        formData.append(property, obj[property]);
    }

    if (trackNTrace) {
      const authorities = [];
      authorities.push(obj.authority.name);
      formData.append("authorities", authorities);
    }
    trackPromise(
      httpService
        .getApiMultipart("users", formData)
        .then((response) => {
          cancel();
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };

  return (
    <>
      <AppBreadcrumb
        breadcrumdItems={[
          { label: "User List", url: "#/user_list" },
          { label: "User Add" },
        ]}
      />

      <div className="p-grid ">
        <Growl ref={growl} style={{ marginTop: "75px" }} />

        <div className="p-col-10 ">
          <div className="card card-w-title">
            <h1>Add User</h1>
            <div className="p-grid  p-fluid form-group">
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">account_circle</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.firstName ? "p-error" : ""}
                      as={InputText}
                      control={control}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRuleVal100,
                      }}
                      name="firstName"
                      value={obj.firstName}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>First name</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.firstName?.message}
                </span>
              </div>
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">account_circle</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.lastName ? "p-error" : ""}
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.maxLengthRuleVal100,
                      }}
                      as={InputText}
                      control={control}
                      name="lastName"
                      value={obj.lastName}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Last name</label>{" "}
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.lastName?.message}
                </span>
              </div>
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">email</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.login ? "p-error" : ""}
                      rules={{ ...rule.RULE.maxLengthRuleVal50 }}
                      name="login"
                      value={obj.login}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Username</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.login?.message}
                </span>
              </div>
              {priviladgeContext.isSuperAdmin && (
                <div className="p-col-8">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="material-icons">business</i>
                    </span>
                    <span className="md-inputfield">
                      <Controller
                        optionLabel="name"
                        optionValue="id"
                        className={errors.company1 ? "p-error" : ""}
                        rules={{ ...rule.RULE.requiredRule }}
                        as={Dropdown}
                        control={control}
                        options={companies}
                        placeholder={"Company"}
                        name="company1"
                        value={obj.company1}
                        onChange={(e) => handleChange(e[0])}
                      />
                    </span>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.company1?.message}
                  </span>
                </div>
              )}

              {trackNTrace && (
                <div className="p-col-8">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="material-icons">list</i>
                    </span>
                    <span className="md-inputfield">
                      <Controller
                        className={errors.authority ? "p-error" : ""}
                        filter
                        optionLabel="displayName"
                        optionValue="name"
                        rules={{
                          ...rule.RULE.requiredRule,
                          ...rule.RULE.maxLengthRuleVal25,
                        }}
                        as={Dropdown}
                        control={control}
                        options={authority}
                        placeholder={"Authority"}
                        name="authority"
                        value={obj.authority}
                        onChange={(e) => handleChange(e[0])}
                      />
                    </span>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.authority?.message}
                  </span>
                </div>
              )}

              {obj.authority?.name !== "ROLE_PRODUCER" && (
                <div className="p-col-8">
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                      <i className="material-icons">business</i>
                    </span>
                    <span className="md-inputfield">
                      <Controller
                        optionLabel="companyName"
                        optionValue="id"
                        className={errors.customers1 ? "p-error" : ""}
                        rules={{ ...rule.RULE.requiredRule }}
                        as={trackNTrace ? Dropdown : MultiSelect}
                        control={control}
                        options={customers}
                        placeholder={"Customers"}
                        name="customers1"
                        value={obj.customers1}
                        onChange={(e) => handleChange(e[0])}
                      />
                    </span>
                  </div>
                  <span className="errorSpan merginLeft30">
                    {errors.customers1?.message}
                  </span>
                </div>
              )}
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">phone</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.mobileNo ? "p-error" : ""}
                      type="number"
                      name="mobileNo"
                      rules={{
                        ...rule.RULE.requiredRule,
                        ...rule.RULE.minLengthRule10,
                        ...rule.RULE.maxLengthRule15,
                      }}
                      value={obj.mobileNo}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Mobile no.</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.mobileNo?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">email</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.email ? "p-error" : ""}
                      rules={{ ...rule.RULE.maxLengthRuleVal50 }}
                      name="email"
                      value={obj.email}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Email</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.email?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">home</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      className={errors.address ? "p-error" : ""}
                      rules={{ ...rule.RULE.maxLengthRuleVal200 }}
                      name="address"
                      value={obj.address}
                      as={InputText}
                      control={control}
                      onChange={(e) => handleChange(e[0])}
                    />
                    <label>Address</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.email?.message}
                </span>
              </div>

              <div className="p-col-8">
                <div className="p-inputgroup">
                  <span className="p-inputgroup-addon">
                    <i className="material-icons">insert_invitation</i>
                  </span>
                  <span className="md-inputfield">
                    <Controller
                      minDate={minDate}
                      className={errors.accountExpiredDate ? "p-error" : ""}
                      name="accountExpiredDate"
                      value={obj.accountExpiredDate}
                      rules={{ ...rule.RULE.minRuleVal1 }}
                      as={Calendar}
                      control={control}
                      onChange={(e) => handleChange(e[0], "date")}
                      monthNavigator={true}
                      yearNavigator={true}
                      yearRange="2010:2070"
                      dateFormat="yy-MM-dd"
                    />
                    <label>Account expiration date</label>
                  </span>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.accountExpiredDate?.message}
                </span>
              </div>

              <div className="p-col-8">
                <label>Upload profile image</label>
                <div className="p-col-8"></div>
                <div className="p-inputgroup">
                  <Controller
                    className={errors.file ? "p-error" : ""}
                    disabled={disableFile}
                    rules={{}}
                    as={FileUpload}
                    control={control}
                    name="file"
                    onSelect={(e) => onFileSelect(e)}
                    accept="image/*"
                    onRemove={() => onRemoveImg()}
                    maxFileSize={5000000}
                    customUpload={true}
                  />
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.file?.message}
                </span>
              </div>
              <div className="p-col-8">
                <div className="p-inputgroup">
                  <InputSwitch
                    checked={obj.activated}
                    name={"activated"}
                    inputId={obj.firstName}
                    ariaLabelledBy={obj.firstName}
                    value={obj.activated}
                    onChange={(event) => handleChange(event)}
                  />
                  <label
                    htmlFor={obj.firstName}
                    className="p-checkbox-label merginLeft15"
                  >
                    Active
                  </label>
                </div>
                <span className="errorSpan merginLeft30">
                  {errors.activated?.message}
                </span>
              </div>
            </div>
            <div className="p-col-12">
              <Button
                icon="pi pi-check"
                label="Save"
                onClick={handleSubmit(save)}
              />
              <Button
                icon="pi-md-cancel"
                label="Cancel"
                className="p-button-secondary"
                onClick={() => cancel()}
              />
            </div>
            <div className="p-col-8">
              {message !== "" && <Message severity="error" text={message} />}
            </div>
          </div>
        </div>
        <div className="p-col-4"></div>
      </div>
    </>
  );
}
export default withRouter(UserAdd);
