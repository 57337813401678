import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState,useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { trackPromise } from 'react-promise-tracker';
import { withRouter } from 'react-router-dom';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean,showMessage } from '../../utility/AppUtility';
import rule from './../../constant/Rule';
import { AppBreadcrumb } from '../../AppBreadcrumb';


function CompanyAdd(props) {

    var [obj, setObj] = useState({})
    const growl = useRef(null)
    const { handleSubmit, errors, control } = useForm({ mode: "onBlur" });
    const httpService = new HttpService();
    
    

    const handleChange = (e, type) => {
        let val = e.target.value;

        setObj({ ...obj, [e.target.name]: val });
        return val;
    }
   
    const cancel = () => {
        props.history.push('company_list')
    }
    
    const save = data => {
        obj = clean(obj)

        trackPromise(
            httpService.postApi('companies', obj).then((response) => {
                cancel();
            }).catch((error) => {
                if (checkForError(error)) {
                   showMessage('error', 'Error Message', error.response.data.title,growl)
                }
            }));

    }

   


    return  <>
                                <AppBreadcrumb breadcrumdItems={[{ label: 'Company List',url:"#company_list"},{ label: 'Company Add'}]} />

    <div className="p-grid ">
        <Growl ref={growl} style={{ marginTop: '75px' }} />

        <div className="p-col-10 ">
            <div className="card card-w-title">
                <h1>Add Company</h1>
                <div className="p-grid p-fluid form-group">

                    <div className="p-col-8">
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon"><i className="material-icons">account_circle</i></span>
                            <span className="md-inputfield">
                                <Controller className={errors.name ? "p-error" : ''} name="name" value={obj.name} rules={{ ...rule.RULE.requiredRule, ...rule.RULE.maxLengthRuleVal100 }} as={InputText} control={control} onChange={(e) => handleChange(e[0])} />
                                <label >Name</label>
                            </span>
                        </div>
                        <span className="errorSpan merginLeft30">{errors.name?.message}</span>
                    </div>

                </div>
                <div className="p-col-12">
                    <Button icon="pi pi-check" label="Save" onClick={handleSubmit(save)} />
                    {/* <Button icon="pi-md-clear" label="Clear" className="p-button-secondary" onClick={() => clear()} /> */}
                    <Button icon="pi-md-cancel" label="Cancel" className="p-button-secondary" onClick={() => cancel()} />

                </div>
                <div className="p-col-8">

                </div>

            </div>
        </div>
        <div className="p-col-4"></div>
    </div></>

}
export default withRouter(CompanyAdd);
