import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { trackPromise } from "react-promise-tracker";
import { withRouter } from "react-router-dom";
import { HttpService } from "../../service/HttpService";
import { Dropdown } from "primereact/dropdown";
import { checkForError, clean, showMessage } from "../../utility/AppUtility";
import rule from "./../../constant/Rule";
import { TabView, TabPanel } from "primereact/tabview";
import ContectPersonAdd from "./ContectPersonAdd";
import { PriviladgeContext } from "./../../context/priviladge-context";
import { AppBreadcrumb } from "../../AppBreadcrumb";
import { InputSwitch } from "primereact/inputswitch";

var _ = require("lodash");

function PlantAdd(props) {
  var [obj, setObj] = useState({});
  const growl = useRef(null);
  var [products, setProducts] = useState([]);
  var [clients, setClients] = useState([]);
  var [plantProducts, setPlantProducts] = useState([]);
  var [contectPersons, setContectPersons] = useState([]);
  var [activeIndex, setActiveIndex] = useState(0);
  var [allowAllProduct, setAllProducts] = useState(false);

  const { handleSubmit, errors, control, reset, setValue, watch } = useForm({
    mode: "onBlur",
  });
  const httpService = new HttpService();
  console.log(watch());
  useEffect(() => {
    getCustomer();
  }, []);

  const getProduct = (id) => {
    trackPromise(
      httpService
        .getApi("products/" + id + "/customer")
        .then((response) => {
          setProducts(response.data);
          setPlantProducts([]);
          setValue("plantProducts", []);
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };
  const getCustomer = () => {
    trackPromise(
      httpService
        .getApi("customers/company?type=Customer")
        .then((response) => {
          setClients(response.data.content);
        })
        .catch((error) => {
          if (checkForError(error))
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
        })
    );
  };
  const addProduct = () => {
    let pp = [...plantProducts];
    pp.push({ plant: null, noOfLabelPerRoll: null });

    setPlantProducts(pp);
  };

  const handleChange = (e, type) => {
    let val = e.target.value;

    if (e.target.name === "customer" && val !== null) {
      getProduct(val.id);
    }
    setObj({ ...obj, [e.target.name]: val });
    return val;
  };
  const handleProductPlantChange = (e, index, name) => {
    const tempPlantProducts = [...plantProducts];
    const obj = { ...tempPlantProducts[index] };
    obj[name] = e.target.value;
    tempPlantProducts[index] = obj;
    setPlantProducts(tempPlantProducts);
    return e.target.value;
  };

  const cancel = () => {
    props.history.push("plant_list");
  };

  const save = (data) => {
    obj = clean(obj);
    obj.plantProducts = plantProducts;
    obj.contectPersons = contectPersons;
    obj.allowAllProduct = allowAllProduct;
    trackPromise(
      httpService
        .postApi("plants", obj)
        .then((response) => {
          cancel();
        })
        .catch((error) => {
          if (checkForError(error)) {
            showMessage(
              "error",
              "Error Message",
              error.response.data.title,
              growl
            );
          }
        })
    );
  };
  var saveContectPersons = (data) => {
    setContectPersons(data);
    setActiveIndex(0);
  };
  const deleteProduct1 = (index) => {
    let pp = [...plantProducts];
    pp.splice(index, 1);

    setPlantProducts(pp);
  };

  const onTabChange = (index) => {
    setActiveIndex(index);
    if (index == 0) {
      reset({ ...obj, plantProducts: plantProducts });
    }
  };

  return (
    <>
      <AppBreadcrumb
        breadcrumdItems={[
          { label: "Plant List", url: "#plant_list" },
          { label: "Plant Add" },
        ]}
      />

      <div className="p-grid ">
        <Growl ref={growl} style={{ marginTop: "75px" }} />

        <div className="p-col-10 ">
          <TabView
            activeIndex={activeIndex}
            onTabChange={(e) => onTabChange(e.index)}
          >
            <TabPanel header="Plant">
              <div className="card card-w-title">
                <h1>Add Plant</h1>
                <div className="p-grid p-fluid form-group">
                  <div className="p-col-8">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="material-icons">account_circle</i>
                      </span>
                      <span className="md-inputfield">
                        <Controller
                          className={errors.plantCode ? "p-error" : ""}
                          name="plantCode"
                          value={obj.plantCode}
                          rules={{
                            ...rule.RULE.requiredRule,
                            ...rule.RULE.maxLengthRuleVal100,
                          }}
                          as={InputText}
                          control={control}
                          onChange={(e) => handleChange(e[0])}
                        />
                        <label>Plant Code</label>
                      </span>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.plantCode?.message}
                    </span>
                  </div>
                  <div className="p-col-8">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="material-icons">account_circle</i>
                      </span>
                      <span className="md-inputfield">
                        <Controller
                          className={errors.name ? "p-error" : ""}
                          name="name"
                          value={obj.name}
                          rules={{
                            ...rule.RULE.requiredRule,
                            ...rule.RULE.maxLengthRuleVal100,
                          }}
                          as={InputText}
                          control={control}
                          onChange={(e) => handleChange(e[0])}
                        />
                        <label>Name</label>
                      </span>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.name?.message}
                    </span>
                  </div>

                  <div className="p-col-8">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="material-icons">account_circle</i>
                      </span>
                      <span className="md-inputfield">
                        <Controller
                          className={errors.address ? "p-error" : ""}
                          name="address"
                          value={obj.address}
                          rules={{
                            ...rule.RULE.requiredRule,
                            ...rule.RULE.maxLengthRuleVal250,
                          }}
                          as={InputText}
                          control={control}
                          onChange={(e) => handleChange(e[0])}
                        />
                        <label>Address</label>
                      </span>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.address?.message}
                    </span>
                  </div>

                  <div className="p-col-8">
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">
                        <i className="material-icons">perm_identity</i>
                      </span>
                      <span className="md-inputfield">
                        <Controller
                          optionLabel="companyName"
                          optionValue="id"
                          filter
                          filterBy="companyName"
                          className={errors.client ? "p-error" : ""}
                          rules={{ ...rule.RULE.requiredRule }}
                          as={Dropdown}
                          control={control}
                          options={clients}
                          placeholder={"Client"}
                          name="customer"
                          value={obj.customer}
                          onChange={(e) => handleChange(e[0])}
                        />
                      </span>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.customer?.message}
                    </span>
                  </div>
                  <div className="p-col-8">
                    <div className="p-inputgroup">
                      <InputSwitch
                        checked={allowAllProduct}
                        name={"allowAllProduct"}
                        inputId={allowAllProduct}
                        ariaLabelledBy={allowAllProduct}
                        value={allowAllProduct}
                        onChange={() => setAllProducts(!allowAllProduct)}
                      />
                      <label
                        htmlFor={allowAllProduct}
                        className="p-checkbox-label merginLeft15"
                      >
                        All Product Add
                      </label>
                    </div>
                    <span className="errorSpan merginLeft30">
                      {errors.allowAllProduct?.message}
                    </span>
                  </div>
                  {!allowAllProduct && (
                    <>
                      <div className="p-col-8">
                        <div className="p-col-3">
                          <Button
                            icon="pi pi-plus"
                            label="Add Product"
                            onClick={addProduct.bind(this)}
                          />
                        </div>
                      </div>

                      {plantProducts.map((pp, index) => {
                        return (
                          <div className="p-col-8">
                            <div className="p-col-5 dlsplayInlineBlock">
                              <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                  <i className="material-icons">
                                    perm_identity
                                  </i>
                                </span>
                                <span className="md-inputfield">
                                  <Controller
                                    disabled={pp.id !== undefined}
                                    optionLabel="productCode"
                                    optionValue="id"
                                    filter
                                    filterBy="productCode"
                                    className={
                                      errors?.plantProducts?.[index]?.product
                                        ?.message
                                        ? "p-error"
                                        : ""
                                    }
                                    rules={{ ...rule.RULE.requiredRule }}
                                    as={Dropdown}
                                    control={control}
                                    options={products}
                                    placeholder={"Product"}
                                    name={
                                      "plantProducts[" + index + "].product"
                                    }
                                    value={plantProducts[index]?.product}
                                    onChange={(e) =>
                                      handleProductPlantChange(
                                        e[0],
                                        index,
                                        "product"
                                      )
                                    }
                                  />
                                </span>
                              </div>
                              <span className="errorSpan merginLeft30">
                                {
                                  errors?.plantProducts?.[index]?.product
                                    ?.message
                                }
                              </span>
                            </div>

                            <div className="p-col-5 dlsplayInlineBlock">
                              <div className="p-inputgroup">
                                <span className="p-inputgroup-addon">
                                  <i className="material-icons">
                                    account_circle
                                  </i>
                                </span>
                                <span className="md-inputfield">
                                  <Controller
                                    className={
                                      errors?.plantProducts?.[index]
                                        ?.noOfLabelPerRoll
                                        ? "p-error"
                                        : ""
                                    }
                                    name={
                                      "plantProducts[" +
                                      index +
                                      "].noOfLabelPerRoll"
                                    }
                                    type="number"
                                    value={
                                      plantProducts[index]?.noOfLabelPerRoll
                                    }
                                    rules={{
                                      ...rule.RULE.requiredRule,
                                      ...rule.RULE.maxLengthRule7,
                                    }}
                                    as={InputText}
                                    control={control}
                                    onChange={(e) =>
                                      handleProductPlantChange(
                                        e[0],
                                        index,
                                        "noOfLabelPerRoll"
                                      )
                                    }
                                  />
                                  <label>No.Of Label Per Roll</label>
                                </span>
                              </div>
                              <span className="errorSpan merginLeft30">
                                {
                                  errors?.plantProducts?.[index]
                                    ?.noOfLabelPerRoll?.message
                                }
                              </span>
                            </div>
                            <div className="p-col-2 dlsplayInlineBlock">
                              <Button
                                icon="pi-md-delete"
                                onClick={deleteProduct1.bind(this, index)}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>

                <div className="p-col-12">
                  <Button
                    icon="pi pi-check"
                    label="Save"
                    onClick={handleSubmit(save)}
                  />
                  <Button
                    icon="pi-md-cancel"
                    label="Cancel"
                    className="p-button-secondary"
                    onClick={() => cancel()}
                  />
                </div>
                <div className="p-col-8"></div>
              </div>
            </TabPanel>
            <TabPanel header="Contact Person">
              <ContectPersonAdd
                setContectPersons={saveContectPersons}
                contectPersons={contectPersons}
              ></ContectPersonAdd>
            </TabPanel>
          </TabView>
        </div>

        <div className="p-col-4"></div>
      </div>
    </>
  );
}
export default withRouter(PlantAdd);
