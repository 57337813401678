import classNames from "classnames";
import React, { Component } from "react";
import constant from "./constant/Constant";

export class AppInlineProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      user: JSON.parse(localStorage.getItem("user")),
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick(event) {
    this.setState({ expanded: !this.state.expanded });
    event.preventDefault();
  }
  logout = () => {
    localStorage.removeItem("token");
    localStorage.clear();
    window.location.href = "/#";
  };
  userProfile = () => {
    window.location.href = "/#/user_profile";
  };

  render() {
    return (
      <div>
        <div
          className={classNames("profile", {
            "profile-expanded": this.state.expanded,
          })}
        >
          <button className="p-link" onClick={this.onClick}>
            {this.state.user.profileImage !== null &&
            this.state.user.profileImage !== undefined &&
            this.state.user.profileImage !== "" ? (
              <img
                className="profile-image borderRadius50 imageShadow"
                src={constant.USER_IMAGE_URl + this.state.user.profileImage}
                alt="Profile"
              />
            ) : (
              <img
                className="profile-image borderRadius50 imageShadow"
                src="assets/layout/images/avatar.png"
                alt="Profile"
              />
            )}
            <span className="profile-name">
              {this.state.user.firstName + " " + this.state.user.lastName}
            </span>
            <i className="material-icons">keyboard_arrow_down</i>
          </button>
        </div>

        <ul className="ultima-menu profile-menu">
          <li role="menuitem">
            <button
              className="p-link ripplelink"
              tabIndex={this.state.expanded ? null : "-1"}
              onClick={() => this.userProfile()}
            >
              <i className="material-icons">person</i>
              <span>Profile</span>
            </button>
          </li>
          {/* <li role="menuitem">
                            <button  className="p-link ripplelink" tabIndex={this.state.expanded ? null : '-1'}>
                                <i className="material-icons">security</i>
                                <span>Privacy</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button  className="p-link ripplelink" tabIndex={this.state.expanded ? null : '-1'}>
                                <i className="material-icons">settings_application</i>
                                <span>Settings</span>
                            </button>
                        </li> */}
          <li role="menuitem">
            <button
              className="p-link ripplelink"
              tabIndex={this.state.expanded ? null : "-1"}
              onClick={() => this.logout()}
            >
              <i className="material-icons">power_settings_new</i>
              <span>Logout</span>
            </button>
          </li>
        </ul>
      </div>
    );
  }
}
