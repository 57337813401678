import React ,{useContext}from 'react'
import {PriviladgeContext} from './../context/priviladge-context'
var _ = require('lodash');

const CheckPriviladge=(props)=>{


    const priviladgeContext=useContext(PriviladgeContext)
    var checkPriviladge=false;
    if(priviladgeContext.isAdmin || priviladgeContext.isSuperAdmin){
        checkPriviladge=true
    }else{
    var checkPriviladge=_.find(priviladgeContext.userPriviladge, 
        function(n) {
            return (n.name===props.priviladge?.name && n.priority>= props.priviladge?.priority)
          });
    }

    return(
        checkPriviladge?props.children:null
    )

}
export default CheckPriviladge