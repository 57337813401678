import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import { trackPromise } from "react-promise-tracker";
import { HttpService } from "../service/HttpService";
import { checkForError, showMessage } from "../utility/AppUtility";
import { AppBreadcrumb } from "../AppBreadcrumb";
import moment from "moment";

export class ConsignmentList extends Component {
  constructor() {
    super();
    this.state = {
      dataTableValue: [],
      page: 1,
      totalRecords: 0,
      size: 10,
      expandedRows: null,
      first: 0,
    };

    this.httpService = new HttpService();
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
  }

  componentDidMount() {
    this.getList(this.state.page - 1);
  }
  getList = (page) => {
    trackPromise(
      this.httpService
        .getApi(
          "v1/consignment/web/get_all?page=" +
          page +
          "&size=" +
          this.state.size +
          "&sort=createdDate,desc"
        )
        .then((response) => {
          this.setState({
            dataTableValue: response.data.content,
            totalRecords: response.data.totalElements,
          });
        })
        .catch((error) => {
          if (checkForError(error))
            this.showMessage(
              "error",
              "Error Message",
              error.response.data.title
            );
        })
    );
  };
  showMessage(severity, summary, message) {
    let msg = { severity: severity, summary: summary, detail: message };
    this.growl.show(msg);
  }
  onPageChange = (event) => {
    this.setState({
      page: event.page,
      first: event.first
    });
    this.getList(event.page);
  };

  rowExpansionTemplate(data) {
    return (
      <div className="orders-subtable">
        <h4>List of Product </h4>
        <DataTable value={data.palletLabels.reverse()}>
          <Column field="name" header="Product Name" sortable></Column>

          <Column
            field="description"
            header="Product Description"
            sortable
          ></Column>
          <Column field="count" header="Product Quantity" sortable></Column>
        </DataTable>
      </div>
    );
  }

  returnHeader = () => {
    return (
      <div>
        <div>Consignments</div>

        <div className="p-datatable-globalfilter-container">
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
          />
        </div>
      </div>
    );
  };

  returnValue = (rowData) => {
    return moment(new Date(rowData.sendingDate))
      .format("DD-MM-YY hh:mm a")
      .toString();
  };

  expandAll() {
    let expandedRows = {};
    this.state.dataTableValue.forEach((p) => (expandedRows[`${p.id}`] = true));
    this.setState({ expandedRows });
  }

  collapseAll() {
    this.setState({
      expandedRows: null,
    });
  }
  render() {
    let actionHeader = <Button type="button" icon="pi-md-settings" />;

    return (
      <>
        <AppBreadcrumb breadcrumdItems={[{ label: "Consignment List" }]} />
        <div className="p-grid">
          <div className="p-col-12">
            <Growl
              ref={(el) => (this.growl = el)}
              style={{ marginTop: "75px" }}
            />

            <div className=" datatable-demo">
              <div className="p-col-12">
                <font className="font21Weight500">Consignment</font>
              </div>
              <div className="p-col-12"></div>
              <DataTable
                ref={(el) => (this.dt = el)}
                value={this.state.dataTableValue}
                rowExpansionTemplate={this.rowExpansionTemplate}
                paginator={true}
                dataKey="id"
                expandedRows={this.state.expandedRows}
                rows={10}
                header="List of Consignments"
                onRowToggle={(e) => this.setState({ expandedRows: e.data })}
                responsive={true}
                resizableColumns={true}
                columnResizeMode="fit"
                lazy first={this.state.first}
                totalRecords={this.state.totalRecords}
                onPage={(event) => this.onPageChange(event)}
              >
                <Column expander style={{ width: "3em" }} />
                <Column
                  field="reference"
                  header="Ref. No."
                  headerStyle={{ fontWight: "600" }}
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  field="noOfBox"
                  header="Items"
                  headerStyle={{ fontWight: "600" }}
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  field="overallStatus"
                  header="Status"
                  headerStyle={{ fontWight: "600" }}
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />

                <Column
                  field={this.returnValue}
                  header="Created Date"
                  headerStyle={{ fontWight: "600" }}
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                {/* <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '8em' }} /> */}
              </DataTable>
            </div>
          </div>
        </div>
      </>
    );
  }
}
