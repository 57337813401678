import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { HttpService } from '../../service/HttpService';
import { checkForError, showMessage } from '../../utility/AppUtility';
import constant from './../../constant/Constant';
import { PriviladgeContext } from './../../context/priviladge-context'
import CheckPriviladge from './../../hoc/CheckPriviladge'
import { AppBreadcrumb } from '../../AppBreadcrumb';
import moment from "moment";

export class TemplateHistoryList extends Component {

    constructor() {
        super();
        this.state = {
            dataTableValue: [],
            page: 1,
            totalRecords: 0,
            size: 10,
            first: 0,
            isSearch: false,
            searchKey: "",
            searchColumn: ""
        };

        this.httpService = new HttpService();
    }

    componentDidMount() {

        this.getList(this.state.page - 1);
    }
    getList = (page) => {
        trackPromise(
            this.httpService.getApi('template-histories?page=' + page + "&size=" + this.state.size + "&sort=createdDate,desc").then((response) => {
                this.setState({ dataTableValue: response.data.content, totalRecords: response.data.totalElements });

            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            }));
    }
    returnValue = (rowData) => {
        return moment(new Date(rowData.createdDate))
            .format("DD-MM-YY hh:mm a")
            .toString();
    };

    showMessage(severity, summary, message) {
        let msg = { severity: severity, summary: summary, detail: message };
        this.growl.show(msg);
    }
    onPageChange = (event) => {
        this.setState({
            page: event.page,
            first: event.first
        })
        if (this.state.isSearch) {
            this.customFilter(this.state.searchKey, this.state.searchColumn, event.page)
        } else {
            this.getList(event.page)
        }
    }


    edit = (rowData) => {
        this.props.history.push({
            pathname: '/company_edit',
            state: { id: rowData.id }
        })
    }

    add = () => {
        this.props.history.push('company_add')
    }
    download = (rowData) => {
        var a = document.createElement('a');
        a.href = constant.TEMPLATE_URl + rowData.fileName + ".pdf";
        a.download = rowData.fileName;
        a.target = '_blank';
        a.click();
        a.remove();
    }
    downloadCSV = (rowData) => {
        var a = document.createElement('a');
        a.href = constant.TEMPLATE_URl + rowData.fileNameCSV;
        a.download = rowData.fileName;
        a.target = '_blank';
        a.click();
        a.remove();
    }


    customFilter(searchValue, columnName, pageNo) {
        if (searchValue == "") {
            this.setState({ isSearch: false })
            this.getList(0);
        } else {
            this.setState({
                searchKey: searchValue,
                searchColumn: columnName,
                isSearch: true
            })
            this.httpService.searchApi(`template-histories/search?columnName=${columnName}&searchKey=${searchValue}&page=${pageNo}&size=${this.state.size}`).then((response) => {
                this.setState({ dataTableValue: response.data.content, totalRecords: response.data.totalElements, page: 1 });
            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            })
        }

    }

    renderSearchElement(columnName) {
        return (
            <React.Fragment>
                <InputText onChange={(e) => this.customFilter(e.target.value, columnName, 0)} placeholder="Contains" ></InputText>
            </React.Fragment>
        );
    }

    downloadUrlCSV = (rowData) => {
        var a = document.createElement('a');
        var str = rowData.fileNameCSV
        var fileName = str.substring(0, str.indexOf("."));

        a.href = constant.TEMPLATE_URl + fileName + '(a).csv';
        a.download = fileName;
        a.target = '_blank';
        a.click();
        a.remove();
    }

    returnHeader = () => {
        return (
            <div>
                <div> List of Token</div>
                <div className="p-datatable-globalfilter-container">
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" />
                </div>
            </div>
        );
    }
    actionTemplate = (rowData, column) => {
        return <div style={{ width: "128px" }}>

            {rowData.status !== 'RUNNING' &&
                <>
                    <Button type="button" tooltip="Download" icon="pi-md-picture-as-pdf" tooltipOptions={{ position: 'bottom' }} className="p-button-info" onClick={() => this.download(rowData)} style={{ marginRight: '.5em' }} />
                    <Button type="button" tooltip="Download CSV" icon="fas fa-file-csv" tooltipOptions={{ position: 'bottom' }} className="p-button-info" onClick={() => this.downloadCSV(rowData)} style={{ marginRight: '.5em' }} />

                    <Button type="button" tooltip="Download Urls CSV" icon="fas fa-file-csv" tooltipOptions={{ position: 'bottom' }} className="p-button-info" onClick={() => this.downloadUrlCSV(rowData)} style={{ marginRight: '.5em' }} />
                </>
            }

        </div>;
    }
    render() {
        let actionHeader = <Button type="button" icon="pi-md-settings" />;

        return <>
            <AppBreadcrumb breadcrumdItems={[{ label: 'Token' }]} />


            <div className="p-grid">
                <div className="p-col-12">
                    <Growl ref={(el) => this.growl = el} style={{ marginTop: '75px' }} />

                    <div className="card card-w-title datatable-demo">
                        <div className="p-col-12">
                            <font className="font21Weight500" >Token</font>
                        </div>
                        <div className="p-col-12">
                        </div>
                        <DataTable ref={(el) => this.dt = el} value={this.state.dataTableValue}
                            paginator={true} rows={10} lazy totalRecords={this.state.totalRecords}
                            onPage={(event) => this.onPageChange(event)} first={this.state.first}
                            responsive={true} header="List of templates"
                            resizableColumns={true} columnResizeMode="fit"
                        >
                            <Column
                                filterElement={this.renderSearchElement("template.customer.companyName")}
                                field="template.customer.companyName" header="Company Name" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains" />
                            <Column
                                filterElement={this.renderSearchElement("template.description")}
                                field="template.description" header="Template Code" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains" />
                            <Column
                                filterElement={this.renderSearchElement("quantity")}
                                field="quantity" header="Quantity" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains" />
                            <Column
                                filterElement={this.renderSearchElement("fileName")}
                                field="fileName" header="File name" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains" />
                            <Column
                                filterElement={this.renderSearchElement("createdDate")}
                                field={this.returnValue} header="Created Date" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains" />

                            <Column
                                filterElement={this.renderSearchElement("status")}
                                field="status" header="Status" headerStyle={{ fontWight: "600" }} sortable={true} filter={true} filterPlaceholder="Contains" />
                            <Column header={actionHeader} body={this.actionTemplate} style={{ textAlign: 'center', width: '10em ' }} />


                        </DataTable>

                    </div>
                </div>
            </div></>
    }
}