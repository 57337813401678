
import React, { Component } from 'react';
import { GMap } from 'primereact/gmap';
import { HttpService } from '../../service/HttpService';
import greenIcon from '../Assets/images/greencircle.png'
import redIcon from '../Assets/images/redcircle.png'

const google = window.google;

export class CustomGMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, new Date().getDate()),
            endDate: new Date(),  
        }
        this.optionsMap = {
            center: { lat: 23.259933, lng: 77.412613 },
            zoom: 5
        };
        this.overLays =[];
        this.httpService = new HttpService();
        
    }

    componentDidMount() {
        console.log("validScans: ",this.props.vaildScans);
        console.log("invalidScans: ",this.props.invalidScans);

        // setTimeout(() => {
            this.overLays = [];
            var bound = new google.maps.LatLngBounds();
            for (var i = 0; i < this.props?.vaildScans?.length; i++) {
                bound.extend(new google.maps.LatLng(this.props.vaildScans[i]["latitude"], this.props.vaildScans[i]["longitude"]));
                let image = greenIcon;
                this.overLays.push(new google.maps.Marker({
                    position: { lat: this.props.vaildScans[i]["latitude"], lng: this.props.vaildScans[i]["longitude"] },
                    icon: {
                        url: image,
                        scaledSize: new window.google.maps.Size(20, 20)
                    },
                    
                }));
            }
    
            for (var i = 0; i < this.props?.invalidScans?.length; i++) {
                bound.extend(new google.maps.LatLng(this.props.invalidScans[i]["latitude"], this.props.invalidScans[i]["longitude"]));
                let image = redIcon;
                this.overLays.push(new google.maps.Marker({
                    position: { lat: this.props.invalidScans[i]["latitude"], lng: this.props.invalidScans[i]["longitude"] },
                    icon: {
                        url: image,
                        scaledSize: new window.google.maps.Size(20, 20)
                    },
                    
                }));
            }
            this.optionsMap.center = { lat: bound.getCenter().lat(), lng: bound.getCenter().lng() }
        // }, 3000);
    }

    render() {
        return (
            <div>
                 <GMap options={this.optionsMap} overlays={this.overLays} style={{ width: '100%', minHeight: '750px' }} />  
            </div>
        );
    }
}
 