function clean(obj) {
    for (var propName in obj) { 
      if (obj[propName] === null || obj[propName] === undefined || obj[propName]==='' )  {
        delete obj[propName];
      }
    }
   
    return obj;
  }
  
  function checkForError(error){
    if(error!==undefined && error.response!==undefined  && error.response.data!==undefined 
      &&  error.response.data.message!==undefined  ) return true;
      else{
        return false;
      }
  }
  function showMessage  (severity, summary, message,growl) {
    let msg = { severity: severity, summary: summary, detail: message };
    growl.current.show(msg);
}
export { clean ,checkForError,showMessage }



