import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Growl } from "primereact/growl";
import { InputText } from "primereact/inputtext";
import React, { Component } from "react";
import { trackPromise } from "react-promise-tracker";
import { HttpService } from "../../service/HttpService";
import { checkForError, showMessage } from "../../utility/AppUtility";
import { PriviladgeContext } from "./../../context/priviladge-context";
import CheckPriviladge from "./../../hoc/CheckPriviladge";
import { AppBreadcrumb } from "../../AppBreadcrumb";

export class PlantList extends Component {
  constructor() {
    super();
    this.state = {
      dataTableValue: [],
      page: 1,
      totalRecords: 0,
      size: 100,
    };

    this.httpService = new HttpService();
    this.properties = [
      { key: "plantCode", name: "Code" },
      { key: "name", name: "Name" },
      { key: "customer.companyName", name: "Client" },
      {
        key: "address",
        name: "Address",
      },
    ];
  }

  componentDidMount() {
    this.getList(this.state.page - 1);
  }
  getList = (page) => {
    trackPromise(
      this.httpService
        .getApi(
          "plants/company?page=" +
            page +
            "&size=" +
            this.state.size +
            "&sort=createdDate,desc"
        )
        .then((response) => {
          this.setState({
            dataTableValue: response.data,
            totalRecords: response.data.totalElements,
          });
        })
        .catch((error) => {
          if (checkForError(error))
            this.showMessage(
              "error",
              "Error Message",
              error.response.data.title
            );
        })
    );
  };
  showMessage(severity, summary, message) {
    let msg = { severity: severity, summary: summary, detail: message };
    this.growl.show(msg);
  }
  onPageChange = (event) => {
    this.setState({ page: event.page });
    this.getList(event.page);
  };

  edit = (rowData) => {
    this.props.history.push({
      pathname: "/plant_edit",
      state: { id: rowData.id },
    });
  };
  view = (rowData) => {
    this.props.history.push({
      pathname: "/view",
      state: {
        data: rowData,
        title: "Plant",
        uri: "plant_list",
        properties: this.properties,
        url: "plants/",
      },
    });
  };
  add = () => {
    this.props.history.push("plant_add");
  };
  delete = (rowData) => {
    trackPromise(
      this.httpService
        .deleteApi("plants/" + rowData.id)
        .then((response) => {
          this.getList(0);
        })
        .catch((error) => {
          if (checkForError(error)) {
            this.showMessage(
              "error",
              "Error Message",
              error.response.data.title
            );
          }
        })
    );
  };

  returnHeader = () => {
    return (
      <div>
        <div> List of Plants</div>

        <div className="p-datatable-globalfilter-container">
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Global Search"
          />
        </div>
      </div>
    );
  };
  actionTemplate = (rowData, column) => {
    return (
      <div style={{ width: "120px" }}>
        <CheckPriviladge priviladge={{ name: "Plant", priority: 3 }}>
          {" "}
          <Button
            type="button"
            tooltip="Delete"
            tooltipOptions={{ position: "bottom" }}
            icon="pi-md-delete-forever"
            className="p-button-danger"
            onClick={() => this.delete(rowData)}
            style={{ marginRight: ".5em" }}
          />{" "}
        </CheckPriviladge>
        <CheckPriviladge priviladge={{ name: "Plant", priority: 2 }}>
          {" "}
          <Button
            type="button"
            tooltip="Edit"
            tooltipOptions={{ position: "bottom" }}
            icon="pi-md-pencil"
            className="p-button-warning"
            onClick={() => this.edit(rowData)}
          />
        </CheckPriviladge>
        <CheckPriviladge priviladge={{ name: "Plant", priority: 1 }}>
          {" "}
          <Button
            type="button"
            tooltip="View"
            tooltipOptions={{ position: "bottom" }}
            icon="pi-md-remove-red-eye"
            className="p-button-info"
            onClick={() => this.view(rowData)}
          />
        </CheckPriviladge>
      </div>
    );
  };
  render() {
    let actionHeader = <Button type="button" icon="pi-md-settings" />;

    return (
      <>
        {" "}
        <AppBreadcrumb breadcrumdItems={[{ label: "Plant List" }]} />
        <div className="p-grid">
          <div className="p-col-12">
            <Growl
              ref={(el) => (this.growl = el)}
              style={{ marginTop: "75px" }}
            />

            <div className=" datatable-demo">
              <div className="p-col-12">
                <font className="font21Weight500">Plants</font>

                <CheckPriviladge priviladge={{ name: "Plant", priority: 2 }}>
                  {" "}
                  <Button
                    className="floatRight"
                    icon="pi-md-plus"
                    tooltip="Add Plant"
                    label="Add"
                    tooltipOptions={{ position: "left" }}
                    onClick={() => this.add()}
                  />
                </CheckPriviladge>
              </div>
              <div className="p-col-12"></div>
              <DataTable
                ref={(el) => (this.dt = el)}
                value={this.state.dataTableValue}
                paginator={true}
                rows={10}
                header="List of Plants"
                responsive={true}
                resizableColumns={true}
                columnResizeMode="fit"
              >
                <Column
                  field="plantCode"
                  header="Code"
                  headerStyle={{ fontWight: "600" }}
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  field="name"
                  header="Name"
                  headerStyle={{ fontWight: "600" }}
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  field="customer.companyName"
                  header="Client"
                  headerStyle={{ fontWight: "600" }}
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />
                <Column
                  field="address"
                  header="Address"
                  headerStyle={{ fontWight: "600" }}
                  sortable={true}
                  filter={true}
                  filterPlaceholder="Contains"
                />

                <Column
                  header={actionHeader}
                  body={this.actionTemplate}
                  style={{ textAlign: "center", width: "10em" }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </>
    );
  }
}
