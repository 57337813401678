import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { Component } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { HttpService } from '../../service/HttpService';
import { checkForError, clean } from '../../utility/AppUtility';
import constant from './../../constant/Constant';
import { Dialog } from 'primereact/dialog';
import CheckPriviladge from '../../hoc/CheckPriviladge';
import { Dropdown } from 'primereact/dropdown';
import { AppBreadcrumb } from '../../AppBreadcrumb';

var _ = require('lodash');

export class RollAdd extends Component {

    constructor(props) {
        super(props);

        this.jsxx = []
        this.httpService = new HttpService();
        this.id = "";
        if (props === undefined || props.location === undefined || props.location.state === undefined || props.location.state.id === undefined) {
            props.history.push('print_data_list')
        } else {
            this.id = props.location.state.id;
        }
        this.state = {
            obj: {},
            roll: [],
            rollGroup: [],
            companyInfo: [],
            displayConfirmation: false


        };


    }

    componentDidMount() {
        this.getList();
    }

    getList = () => {
        trackPromise(
            this.httpService.getApi('get/companyInfoFile/' + this.id).then((response) => {
                let tempObj = {
                    ...response.data,
                    'companyInfo': { id: response.data.id },
                    'totalQuantity': response.data.quantity, 'rollQuantity': response.data.noOfLabelPerRoll
                }
                _.unset(tempObj, 'id');

                this.setState({ obj: tempObj })
                this.createRoll(response.data.quantity, response.data.noOfLabelPerRoll)

            }).catch((error) => {
                if (checkForError(error))
                    this.showMessage('error', 'Error Message', error.response.data.title)
            }));
    }

    handleChange = (e) => {
        let val = e.target.value;
        if (e.target.name === "companyInfo") {
            this.setState({ obj: { ...this.state.obj, [e.target.name]: val, 'totalQuantity': val.quantity, 'rollQuantity': val.noOfLabelPerRoll, 'up': val.up } })
            this.createRoll(val.quantity, val.noOfLabelPerRoll)
            // this.updateRoll(val.quantity, val.noOfLabelPerRoll, val.up)
        } else {
            this.setState({ obj: { ...this.state.obj, [e.target.name]: val } })
        }

    }
    rollhandler = (e, index1, name, row, col) => {
        let index = e.target.dataset.counter;
        const tempRoll = [...this.state.roll];
        const obj = { ...tempRoll[index] };
        obj[name] = e.target.value;
        obj["rollGroupNo"] = col;
        tempRoll[index] = obj;
        this.setState({ roll: tempRoll })

        let tempRollGroup = [...this.state.rollGroup];
        let tempRowRollGroup = tempRollGroup[row];

        if (tempRowRollGroup === undefined || tempRowRollGroup.lenth == 0) {
            tempRowRollGroup = [];
        }
        tempRowRollGroup[col] = obj;
        tempRollGroup[row] = tempRowRollGroup

        this.setState({ rollGroup: tempRollGroup })

    }
    createRoll = (totalQuantity, rollQuantity) => {
        var rolls = parseInt(totalQuantity) / parseInt(rollQuantity);
        rolls = Math.floor(rolls)
        if (parseInt(totalQuantity) % parseInt(rollQuantity) !== 0) {
            rolls = rolls + 1;
        }
        let myarray = [];
        for (let index = 0; index < rolls; index++) {
            myarray.push({ startingToken: null, endingToken: null, rollNo: index })
        }
        this.setState({ roll: myarray })
    }







    download = (rowData, ext) => {
        var a = document.createElement('a');
        a.href = constant.TEMPLATE_URl + rowData.fileName + "." + ext;
        a.download = rowData.fileName;
        a.click();
        a.remove();
    }

    save = (status) => {

        let obj = clean(this.state.obj)
        obj["rolls"] = this.state.roll;
        if (status == 'Close') {
            obj["productionStatus"] = "CLOSE"
        } else {
            obj["productionStatus"] = "PENDING"
        }


        trackPromise(
            this.httpService.postApi('roll-details', obj).then((response) => {
                this.cancel();
            }).catch((error) => {
                if (checkForError(error)) {
                    this.showMessage('error', 'Error Message', error.response.data.title)
                }
            }));
    }
    showConfirmDisplay = () => {
        this.setState({ displayConfirmation: true })
    }
    hideConfirmDisplay = () => {
        this.setState({ displayConfirmation: false })
    }
    showMessage(severity, summary, message) {
        let msg = { severity: severity, summary: summary, detail: message };
        this.growl.show(msg);
    }

    cancel = () => {
        this.props.history.push('label_production_list')
    }

    actionTemplate = (rowData, column) => {
        return <div style={{ width: "120px", "marginLeft": "-9px" }}>
            {rowData.status !== 'RUNNING' ? <>
                <Button type="button" label="Excel" tooltip="Excel" tooltipOptions={{ position: 'bottom' }} className="p-button-info" onClick={() => this.download(rowData, 'xlsx')} />
                <Button type="button" label="Csv" tooltip="Csv" tooltipOptions={{ position: 'bottom' }} className="p-button-info" onClick={() => this.download(rowData, 'csv')} /></>
                : null}
        </div>;
    }
    renderFooter() {
        return (
            <div>
                <Button label="No" icon="pi pi-times" onClick={() => this.hideConfirmDisplay()} className="p-button-text" />
                <Button label="Yes" icon="pi pi-check" onClick={() => this.save('Close')} autoFocus />
            </div>
        );
    }

    render() {

        var rolls = parseInt(this.state.obj.quantity) / parseInt(this.state.obj.noOfLabelPerRoll);
        rolls = Math.floor(rolls)
        if (parseInt(this.state.obj.quantity) % parseInt(this.state.obj.noOfLabelPerRoll) !== 0) {
            rolls = rolls + 1;
        }

        // let totalInput = parseInt(rolls) / parseInt(this.state.obj.up);
        // if (parseInt(rolls) % parseInt(this.state.obj.up) !== 0) {
        //     totalInput = totalInput + 1;
        // }
        // totalInput = parseInt(totalInput)
        let totalInput = 1;
        let masterArray = []
        let counter = 0;
        let counter1 = 0;
        let counterIndex = 0;
        let counterIndex1 = 0;
        for (let row = 0; row < totalInput; row++) {
            let subArray = []
            for (let col = 0; (col < this.state.obj.up) && counter < rolls; col++) {
                let oneElement = <div className="p-col-3 dlsplayInlineBlock">
                    <div className="p-inputgroup">
                        <span className="md-inputfield">
                            <InputText name={"startingToken"} data-counter={counterIndex} value={this.state.roll[(counterIndex)]?.startingToken} onChange={(e) => this.rollhandler(e, (counterIndex), 'startingToken', row, col)} />
                            <label >Start Token </label>
                        </span>
                    </div>
                </div>
                subArray.push(oneElement)
                counter = counter + 1;
                counterIndex = counterIndex + 1;
            }
            let mydiv = <div className="p-col-12">{subArray}</div>

            subArray = []
            for (let col = 0; (col < this.state.obj.up) && counter1 < rolls; col++) {
                let oneElement = <div className="p-col-3 dlsplayInlineBlock">
                    <div className="p-inputgroup">
                        <span className="md-inputfield">
                            <InputText name={"endingToken"} data-counter={counterIndex1} value={this.state.roll[(counterIndex1)]?.endingToken} onChange={(e) => this.rollhandler(e, (counterIndex1), 'endingToken', row, col)} />
                            <label >End Token </label>
                        </span>
                    </div>
                </div>
                subArray.push(oneElement)
                counter1 = counter1 + 1;
                counterIndex1 = counterIndex1 + 1;
            }
            let mydiv2 = <div className="p-col-12">{subArray}</div>
            let mydiv3 = <> <div className="p-col-12">{mydiv}{mydiv2}</div>

                <hr /></>
            masterArray.push(mydiv3)

        }
        this.jsxx = masterArray;


        return <>
                <AppBreadcrumb breadcrumdItems={[{ label: 'Label Production List',url:"#label_production_list" },{ label: 'Label Production Add' }]} />

        
        <div className="p-grid">
            <div className="p-col-12">
                <Growl ref={(el) => this.growl = el} style={{ marginTop: '75px' }} />

                <div className="p-col-10 ">
                    <div className="card card-w-title">
                        <h1>Add Label Production</h1>
                        <div className="p-grid p-fluid form-group">
                            {/* <div className="p-col-8">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">business</i></span>
                                    <span className="md-inputfield">
                                        <Dropdown optionLabel="fileName" optionValue="id" options={this.state.companyInfo} placeholder={"FIle Info"} name="companyInfo" value={this.state.obj.companyInfo} onChange={(e) => this.handleChange(e)} />
                                    </span>
                                </div>
                            </div> */}

                            <div className="p-col-8">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">business</i></span>
                                    <span className="md-inputfield">
                                        <InputText disabled={true} name="totalQuantity" value={this.state.obj.totalQuantity} onChange={(e) => this.handleChange(e)} />
                                        <label >Total Quantity</label>
                                    </span>
                                </div>
                                {/* <span className="errorSpan merginLeft30">{errors.totalQuantity?.message}</span> */}
                            </div>
                            <div className="p-col-8">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">account_circle</i></span>
                                    <span className="md-inputfield">
                                        <InputText disabled={true} name="rollQuantity" value={this.state.obj.rollQuantity} onChange={(e) => this.handleChange(e)} />
                                        <label >Roll Quantity</label>
                                    </span>
                                </div>
                                {/* <span className="errorSpan merginLeft30">{errors.rollQuantity?.message}</span> */}
                            </div>


                            <div className="p-col-8">
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon"><i className="material-icons">description</i></span>
                                    <span className="md-inputfield">
                                        <InputText name="description" value={this.state.obj.description} onChange={(e) => this.handleChange(e)} />
                                        <label >Description</label>
                                    </span>
                                </div>
                                {/* <span className="errorSpan merginLeft30">{errors.description?.message}</span> */}
                            </div>
                            {
                                this.jsxx.map((lion, index) => {
                                    return lion
                                })}





                        </div>
                        <div className="p-col-12">
                            <CheckPriviladge priviladge={{ name: "Label Production", priority: 2 }}>
                                <Button icon="pi pi-check" label="Save" onClick={() => this.save('')} />
                            </CheckPriviladge>

                            <CheckPriviladge priviladge={{ name: "Label Production", priority: 3 }}> <Button icon="pi pi-check" label="Close Label Production" onClick={() => this.showConfirmDisplay()} />
                            </CheckPriviladge>


                        </div>
                        <Dialog header="Confirmation" visible={this.state.displayConfirmation} modal style={{ width: '350px' }} footer={this.renderFooter()} onHide={() => this.hideConfirmDisplay()}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                <span>Are you sure you want to close label production?</span>
                            </div>
                        </Dialog>


                    </div>

                </div>
                <div className="p-col-4"></div>
            </div>
        </div></>
    }
}