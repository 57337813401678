import React from 'react'

export const PriviladgeContext=React.createContext({
    userPriviladge:[],
    isAdmin:false,
    isSuperAdmin:false,
    isUser:false,
    company:{},
    updateUserPriviladge:()=>{}
});
