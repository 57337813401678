import React from 'react';
import './WeekdaysRow.css'; // Import the CSS file for styling

const WeekdaysRow = () => {
  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  return (
    <div className="weekdays-row">
      {weekdays.map((day, index) => (
        <div key={index} className="weekday">
          <div className="color-dot" style={{ backgroundColor: getColorForDay(index) }}></div>
          <div className="weekday-name">{day}</div>
        </div>
      ))}
    </div>
  );
};

// Function to assign a color for each weekday
const getColorForDay = (index) => {
  const colors = ['#e23535', '#b963ee', '#e57314', '#97d2d4', '#ffde14', '#546918', '#2c7ef2']
  return colors[index % colors.length];
};

export default WeekdaysRow;
