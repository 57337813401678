import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { Growl } from 'primereact/growl';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import rule from './../../constant/Rule';
import { HttpService } from './../../service/HttpService';
import { clean, checkForError, showMessage } from './../../utility/AppUtility';
import constant from "../../constant/Constant";

var _ = require('lodash');

function Settings(props) {

    var [obj, setObj] = useState({})
    var [minDate] = useState(new Date())
    var [disableFile, setDisableFile] = useState(false)
    const growl = useRef(null)


    var id = ""

    const { handleSubmit, errors, control, reset } = useForm({ mode: "onBlur" });
    const httpService = new HttpService();

    useEffect(() => {
        initSettings();
    }, [id])

    const handleChange = (e, type) => {
        let val = e.target.value;
        if (type === 'date') {
            val = moment(new Date(e.target.value)).format("YYYY-MM-DDTHH:mm:ss").toString();
        }
        setObj({ ...obj, [e.target.name]: val });
        if (type === 'date') {
            return e.target.value;
        } else
            return val;
    }
    const onFileSelect = (e) => {
        setObj({ ...obj, file: e.files[0] });
        setDisableFile(true)
    }
    const onRemoveImg = () => {
        _.unset(obj, 'file');
        setDisableFile(false)
    }

    const cancel = () => {
        props.history.push('dashboard');
    }

    const save = data => {
        obj = clean(obj)
        let formData = new FormData()
        for (const property in obj) {

            if (obj[property] !== undefined)
                formData.append(property, obj[property]);
        }
        httpService.getApiMultipartPut('settings/create', formData).then((response) => {
            localStorage.setItem("settings", JSON.stringify(response.data))
            window.location.reload();
        }).catch((error) => {
            if (checkForError(error))
                showMessage('error', 'Error Message', error.response.data.error, growl)
        })

    }
    const initSettings = () => {
        httpService
            .getApi('settings/getSettings')
            .then((response) => {
                setObj({ file: response.data.companyLogo.value })
                reset({ file: response.data.companyLogo.value })
                localStorage.setItem("settings", JSON.stringify(response.data))
            })
            .catch((error) => {
            })
    }

    const deleteImage = (name) => {
        _.unset(obj, "file");
        _.unset(obj, name);
      };




    return <><div className="p-grid ">
        <div className="p-col-8 ">
            <Growl ref={growl} style={{ marginTop: '75px' }} />

            <div className="card card-w-title">
                <h1>Settings</h1>
                <div className="p-grid  p-fluid form-group">

                    <div className="p-col-8">
                        <label>Upload company logo</label>
                        <div className="p-col-8"></div>
                        <div className="p-inputgroup">
                            <Controller className={errors.file ? "p-error" : ''} disabled={disableFile} rules={{}} as={FileUpload} control={control} name="file"
                                onSelect={e => onFileSelect(e)} accept="image/*" onRemove={() => onRemoveImg()} maxFileSize={5000000} customUpload={true} />
                        </div>
                        <span className="errorSpan merginLeft30">{errors.file?.message}</span>
                    </div>
                    <div className='p-col-4'>
                        {obj.file && obj.file != null && (
                            <div className="card card-w-title">
                                <div>
                                    <div>
                                        <div style={{ textAlign: "center" }}>
                                            <img
                                                className="imageWidth100 img-rounded"
                                                src={constant.COMPANY_IMAGE_URL +"companylogo/"+ obj.file}
                                                alt="Not found"
                                            />
                                        </div>
                                    </div>
                                    <div className="imgButton">
                                        <Button
                                            icon="pi-md-delete"
                                            className="p-button-secondary"
                                            onClick={() => deleteImage("file")}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                </div>
                <div className="p-col-12">
                    <Button icon="pi pi-check" label="Save" onClick={handleSubmit(save)} />
                    <Button icon="pi-md-cancel" label="Cancel" className="p-button-secondary" onClick={() => cancel()} />
                </div>
                <div className="p-col-8">

                </div>

            </div>
        </div>
        <div className="p-col-4"></div>
    </div></>

}
export default withRouter(Settings);
